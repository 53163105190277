import { createSlice } from "@reduxjs/toolkit";
import { UserAccount } from "./types";

interface UserDataState {
  users: UserAccount[];
}

const initialState: UserDataState = {
  users: [] as UserAccount[],
};

export const UsersDataSlice = createSlice({
  name: "usersData",
  initialState,
  reducers: {
    setUsersData: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const { setUsersData } = UsersDataSlice.actions;

export default UsersDataSlice.reducer;
