import React, { useRef, useState } from "react";
import {
  AiOutlineEdit,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSync,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";

import {
  CapabilityCheck,
  SupportedStatus,
} from "../../store/questionnaire/types";
import {
  useReCheckCapabilityMutation,
  useUpdateCapabilityMutation,
} from "../../store/capabilityCheck/capabilityCheckApiService";
import { RootState } from "../../store";
import { setCapabilityChecks } from "../../store/questionnaire/questionnaireSlice";
import { questionnaireApiUtils } from "../../store/questionnaire/questionnaireApiService";
import { toast } from "react-toastify";
import StatusIcon from "./StatusIcon";
import AutoResizeTextarea from "../utils/AutoResizeTextArea";
import { trackUser } from "../../utils";

export const CapabilityCheckItem = ({ item }: { item: CapabilityCheck }) => {
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reasoning, setReasoning] = useState(item.reasoning);
  const [recheckCapabilityApi, recheckCapabilityDataResult] =
    useReCheckCapabilityMutation();
  const [updateCapability, updateCapabilityResult] =
    useUpdateCapabilityMutation();
  const dispatch = useDispatch();
  const capabilityChecks = useSelector<RootState, CapabilityCheck[]>(
    (state) => state.questionnaireData.capabilityChecks
  );
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const startEditing = () => {
    setIsEditing(true);
    setReasoning(item.reasoning);
  };

  const recheckCapability = () => {
    if (item.isModified) {
      setShowConfirmBox(true);
      return;
    }
    executeRecheck();
  };

  const executeRecheck = () => {
    recheckCapabilityApi(item.id)
      .unwrap()
      .then((newItem: CapabilityCheck) => {
        trackUser("Questionnaire Item Recheck Capability", {
          capabilityCheckId: item.id,
          capabilityCheckIdentifier: item.identifier,
        });
        updateAndNotifyChanges(newItem);
        setShowConfirmBox(false);
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
        setShowConfirmBox(false);
      });
  };

  const resetEditModel = () => {
    setIsEditing(false);
    setReasoning(item.reasoning);
  };

  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      sendUpdateCapabilityCheck();
    } else if (e.key === "Escape") {
      resetEditModel();
    }
  };

  const sendUpdateCapabilityCheck = () => {
    if (isEditing) {
      const updatedCapabilityCheck = {
        id: item.id,
        reasoning: reasoning,
      };
      updateCapability(updatedCapabilityCheck)
        .unwrap()
        .then((newItem: CapabilityCheck) => {
          trackUser("Questionnaire Item Update Capability", {
            capabilityCheckId: item.id,
            capabilityCheckIdentifier: item.identifier,
          });
          updateAndNotifyChanges(newItem);
          resetEditModel();
        })
        .catch((err: any) => {
          toast.error(err?.data?.message || "Error!");
        });
    }
  };

  function updateAndNotifyChanges(newItem: CapabilityCheck, reoloadRFI = true) {
    const newList = capabilityChecks!.map((x) => {
      if (x.id === item.id) {
        if (x.supported !== newItem.supported) {
          if (reoloadRFI) {
            dispatch(
              questionnaireApiUtils.invalidateTags([
                "Questionnaire",
                "QuestionnaireHistory",
              ])
            );
          }
        }
        return newItem;
      }
      return x;
    });
    dispatch(setCapabilityChecks(newList));
  }

  const toggleApproveState = (id: string, approved: boolean) => {
    const updatedCapabilityCheck = {
      id: id,
      approved: approved,
    };
    updateCapability(updatedCapabilityCheck)
      .unwrap()
      .then((newItem: CapabilityCheck) => {
        trackUser("Questionnaire Item Approve Capability", {
          capabilityCheckId: item.id,
          capabilityCheckIdentifier: item.identifier,
        });
        updateAndNotifyChanges(newItem, false);
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
      });
  };

  const handleStatusChange = (status: SupportedStatus) => {
    const updatedCapabilityCheck = {
      id: item.id,
      supported: status,
    };
    updateCapability(updatedCapabilityCheck)
      .unwrap()
      .then((newItem: CapabilityCheck) => {
        trackUser("Questionnaire Item Status Change", {
          capabilityCheckId: item.id,
          capabilityCheckIdentifier: item.identifier,
        });
        updateAndNotifyChanges(newItem);
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
      });
  };

  const ReasoningCell = ({ item }: { item: CapabilityCheck }) => (
    <>
      <div onDoubleClick={startEditing} className="cursor-pointer w-full">
        {item.reasoning === "" && item.processingStatus !== "PROCESSED"
          ? item.processingStatus
          : item.reasoning}
      </div>
    </>
  );

  const ConfirmationBox = ({
    onConfirm,
    onCancel,
  }: {
    onConfirm: () => void;
    onCancel: () => void;
  }) => (
    <div className="absolute inset-0 bg-white bg-opacity-90 flex flex-col items-center justify-center">
      <p className="font-bold">
        All your manual changes will be lost with this action. Are you sure?
      </p>
      <div className="mt-4">
        <AiOutlineCheck
          onClick={onConfirm}
          size={20}
          className="inline mr-4 hover:text-green-500 cursor-pointer"
        />
        <AiOutlineClose
          onClick={onCancel}
          size={20}
          className="inline hover:text-red-500 cursor-pointer"
        />
      </div>
    </div>
  );

  return (
    <tr
      className={`group even:bg-gray-50 p-8 space-x-4 relative ${
        recheckCapabilityDataResult.isLoading ? "bg-gray-100" : ""
      }`}
    >
      <td className="py-6 pl-6 pr-6 leading-6 text-gray-600 tracking-wider font-semibold">
        {item.identifier}
      </td>
      <td className="py-6 pl-2 pr-6 leading-6 text-gray-600 tracking-wider font-semibold">
        {item.description}
      </td>
      {isEditing ? (
        <td
          className="py-6 pl-6 leading-6 text-gray-600 tracking-wider group-last:rounded-0 group-last:rounded-br-xl"
          style={{ height: "100%" }}
        >
          <AutoResizeTextarea
            value={reasoning}
            onChange={(e) => setReasoning(e.target.value)}
            isLoading={updateCapabilityResult.isLoading}
            onKeyPress={onKeyPress}
            ref={inputRef}
          />
          <br />
          {item.responseContexts.map((context) => (
            <p key={context.id} className="text-xs text-gray-500 font-bold">
              📗 {context.fileName}
            </p>
          ))}
        </td>
      ) : (
        <td className="relative py-6 pl-6 leading-6 text-gray-600 tracking-wider">
          <ReasoningCell item={item} />

          {showConfirmBox && (
            <ConfirmationBox
              onConfirm={executeRecheck}
              onCancel={() => setShowConfirmBox(false)}
            />
          )}
          <br />
          {item.responseContexts.map((context) => (
            <p key={context.id} className="text-xs text-gray-500 font-bold">
              📗 {context.fileName}
            </p>
          ))}
        </td>
      )}
      <td className="text-center text-xl group-last:rounded-0 group-last:rounded-bl-xl">
        <StatusIcon
          supported={item.supported}
          onStatusChange={handleStatusChange}
        />
      </td>
      <td className="text-center py-6 leading-6 text-gray-600 tracking-wider font-semibold">
        <input
          type="checkbox"
          className="input-checkbox"
          checked={item.approved}
          onChange={(event) =>
            toggleApproveState(item.id, event.target.checked)
          }
        />
      </td>
      <td className="w-auto">
        <div className="w-14 h-full bg-green-50 flex flex-col items-center justify-center gap-2 absolute right-0 top-0 opacity-0 group-hover:opacity-90 group-last:rounded-0 group-last:rounded-br-xl">
          <div className="flex gap-2">
            <button
              title="Edit"
              disabled={recheckCapabilityDataResult.isLoading}
              className="hover:text-gray-800"
              onClick={startEditing}
            >
              <AiOutlineEdit color="#37571c" size={20} />
            </button>
            <button
              title="Re-analize"
              disabled={recheckCapabilityDataResult.isLoading}
              className="hover:text-gray-800"
              onClick={recheckCapability}
            >
              <AiOutlineSync color="#37571c" size={20} />
            </button>
            {isEditing && (
              <div className="w-16 h-full bg-green-50 absolute right-0 top-0 flex items-center p-2">
                <AiOutlineCheck
                  onClick={sendUpdateCapabilityCheck}
                  size={20}
                  className="hover:text-green-500 cursor-pointer"
                />
                <AiOutlineClose
                  onClick={resetEditModel}
                  size={20}
                  className="hover:text-red-500 cursor-pointer"
                />
              </div>
            )}
            {(recheckCapabilityDataResult.isLoading ||
              item.processingStatus === "PROCESSING") && (
              <span className="absolute top-0 right-0 p-1">
                <FaSpinner className="animate-spin" />
              </span>
            )}
          </div>
        </div>
        {recheckCapabilityDataResult.isLoading && (
          <span className="absolute top-0 right-0 p-1">
            <FaSpinner className="animate-spin" />
          </span>
        )}
      </td>
    </tr>
  );
};
