import React, { useEffect, useState, useRef } from "react";
import {
  AiOutlineDelete,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useApproveStageMutation,
  useGetScopeContextQuery,
  useDeleteScopeItemMutation,
  useUpdateScopeItemMutation,
  useCreateScopeItemMutation,
  useUpdateScopeItemsMutation,
  projectApiUtils,
} from "../../store/project/projectApiService";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentScopeItems,
  setCurrentProject,
  setCurrentScopeItems,
} from "../../store/project/projectSlice";
import {
  ScopeItem,
  ItemScopeType,
  ProjectStage,
  Project,
} from "../../store/project/types";
import { RootState } from "../../store";
import { toast } from "react-toastify";
import { TableLoader } from "../utils/ContentLoader";
import { trackUser } from "../../utils";
import StatusFlag from "../utils/StatusFlag";
import { Tooltip } from "react-tooltip";
import AutoResizeTextarea from "../utils/AutoResizeTextArea";
import ConfirmModal from "../utils/ConfirmModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ScopingPage: React.FC = () => {
  const dispatch = useDispatch();
  const { projectId, requirementId } = useParams<{
    projectId: string;
    requirementId: string;
  }>();
  const navigate = useNavigate();
  const location = useLocation();

  const intervalRef = useRef<null | number | NodeJS.Timeout>(null);
  const [deleteScopeItem] = useDeleteScopeItemMutation();
  const [updateScopeItem] = useUpdateScopeItemMutation();
  const [createScopeItem] = useCreateScopeItemMutation();
  const [updateScopeItems] = useUpdateScopeItemsMutation();

  const scopeContext = useSelector<RootState, CurrentScopeItems | null>(
    (state) => {
      return state.projectData.currentScopeItems;
    }
  );

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  const { data: requestScopeItems } = useGetScopeContextQuery(requirementId!, {
    skip: requirementId == null,
  });

  useEffect(() => {
    if (requestScopeItems) {
      dispatch(
        setCurrentScopeItems({
          requirementId: requirementId!,
          isScopeGenerated: requestScopeItems.data.isScopeGenerated,
          scopeItems: requestScopeItems.data.items,
        })
      );
    }
    if (!intervalRef.current && !requestScopeItems?.data?.isScopeGenerated) {
      // Start the interval to invalidate the state every 5 seconds
      intervalRef.current = setInterval(() => {
        dispatch(
          projectApiUtils.invalidateTags([
            { type: "ScopeContext", id: requirementId },
          ])
        );
      }, 5000);
    }
    if (requestScopeItems?.data?.isScopeGenerated && intervalRef.current) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      intervalRef.current = null;
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [requirementId, requestScopeItems, dispatch]);

  const [approveStage] = useApproveStageMutation();

  const [deletingId, setDeletingId] = useState<string>("");
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingDescription, setEditingDescription] = useState("");
  const [editingName, setEditingName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [scopeItemType] = useState<ItemScopeType | null>(
    ItemScopeType.IN_SCOPE
  );
  const inputNameRef = useRef<HTMLTextAreaElement>(null);
  const inputDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const [draggingId, setDraggingId] = useState<string | null>(null);

  useEffect(() => {
    if (editingId && inputNameRef.current) {
      inputNameRef.current.focus();
      inputNameRef.current.select();
    }
  }, [editingId]);

  const handleDelete = (id: string) => {
    setDeletingId(id);
  };

  const handleEditItem = (item: ScopeItem) => {
    setEditingId(item.id);
    setEditingDescription(item.description);
    setEditingName(item.name);
  };

  const handleConfirmDelete = async (id: string) => {
    deleteScopeItem(id)
      .unwrap()
      .then((result) => {
        dispatch(
          setCurrentScopeItems({
            requirementId: requirementId!,
            scopeItems: result.data,
          })
        );
        toast.success("Successfully deleted!");
        setDeletingId("");
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error deleting item!");
      });
  };

  const handleCancelDelete = () => {
    setDeletingId("");
  };

  const addNewScopeItem = (itemScopeType: ItemScopeType) => {
    const newScopeItem: Partial<ScopeItem> = {
      id: "",
      name: "[New Item]",
      description: "[New Item]",
      type: itemScopeType,
      rank: (scopeContext?.scopeItems.length || 0) + 1,
    };
    createScopeItem({
      requirementId: requirementId!,
      data: newScopeItem,
    })
      .unwrap()
      .then((result) => {
        const createdItem = result.data.find(
          (item: ScopeItem) =>
            item.description === "[New Item]" && item.type === itemScopeType
        );
        if (createdItem) {
          dispatch(
            setCurrentScopeItems({
              requirementId: requirementId!,
              scopeItems: result.data,
            })
          );
          toast.success(
            `Successfully created new ${
              itemScopeType === ItemScopeType.IN_SCOPE
                ? "In Scope"
                : "Out of Scope"
            } item!`
          );
          // Set the newly created item in the editing state
          setEditingId(createdItem.id);
          setEditingDescription(createdItem.description);
          setEditingName(createdItem.name);
        } else {
          toast.error("Could not find the newly created item.");
        }
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error creating item!");
      });
  };

  const sendUpdateScopeItemChanges = async () => {
    if (editingId) {
      updateScopeItem({
        id: editingId,
        description: editingDescription,
        name: editingName,
        rank:
          scopeContext?.scopeItems.find((item) => item.id === editingId)
            ?.rank || 0,
      })
        .unwrap()
        .then((result) => {
          dispatch(
            setCurrentScopeItems({
              requirementId: requirementId!,
              scopeItems: result.data,
            })
          );
          toast.success("Successfully updated!");
          setEditingId(null);
          setEditingDescription("");
          setEditingName("");
        })
        .catch((err: any) => {
          toast.error(err?.data?.message || "Error updating item!");
        });
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendUpdateScopeItemChanges();
    } else if (e.key === "Escape") {
      e.preventDefault();
      resetEditModel();
    }
  };

  const resetEditModel = () => {
    setEditingId(null);
    setEditingDescription("");
    setEditingName("");
  };

  const approveScope = async () => {
    try {
      const { data } = await approveStage({
        requirementId: requirementId!,
        stage: ProjectStage.SCOPE,
      }).unwrap();
      dispatch(setCurrentProject(data));
      navigate(
        `/dashboard/${projectId}/work-breakdown-structure/${requirementId}`
      );
      trackUser("Scope Approved", {
        id: data?.id,
      });
    } catch (err: any) {
      toast.error(err?.data?.message || "Error approving scope!");
    }
  };

  const viewOpenQuestions = () => {
    trackUser("View Open Questions", {
      projectId,
      requirementId,
    });
    navigate("open-questions");
  };

  const isChildRoute = location.pathname.includes("open-questions");

  if (isChildRoute) {
    return <Outlet />;
  }

  const loaded = () => {
    return scopeContext && scopeContext.requirementId === requirementId;
  };

  const handleConfirmAddEdit = () => {
    if (editingId) {
      sendUpdateScopeItemChanges();
    } else {
      addNewScopeItem(scopeItemType!);
    }
    setIsModalVisible(false);
  };

  const handleCancelAddEdit = () => {
    setIsModalVisible(false);
  };

  const onDragEnd = (result: any) => {
    setDraggingId(null);
    if (project?.requirement?.isScopeApproved) {
      toast.error("Cannot reorder items after scope has been approved.");
      return;
    }
    const { source, destination } = result;

    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return;
    }

    const itemId = result.draggableId;
    const item = scopeContext?.scopeItems.find((item) => item.id === itemId);

    if (item) {
      // Separate items into in-scope and out-of-scope arrays
      const inScopeItems = scopeContext!.scopeItems.filter(
        (item) => item.type === ItemScopeType.IN_SCOPE
      );
      const outOfScopeItems = scopeContext!.scopeItems.filter(
        (item) => item.type === ItemScopeType.OUT_OF_SCOPE
      );

      // Remove the moved item from its original array
      let movedItem;
      if (source.droppableId === "in-scope") {
        movedItem = inScopeItems.splice(source.index, 1)[0];
      } else {
        movedItem = outOfScopeItems.splice(source.index, 1)[0];
      }

      // Insert the moved item into its new position
      if (destination.droppableId === "in-scope") {
        movedItem = {
          ...movedItem,
          type: ItemScopeType.IN_SCOPE,
        };
        inScopeItems.splice(destination.index, 0, movedItem);
      } else {
        movedItem = {
          ...movedItem,
          type: ItemScopeType.OUT_OF_SCOPE,
        };
        outOfScopeItems.splice(destination.index, 0, movedItem);
      }

      // Recalculate ranks within each array
      const newInScopeItems = inScopeItems.map((item, index) => ({
        ...item,
        rank: index + 1,
      }));

      const newOutOfScopeItems = outOfScopeItems.map((item, index) => ({
        ...item,
        rank: index + 1,
      }));

      // Combine the arrays back together
      const updatedScopeItems = [...newInScopeItems, ...newOutOfScopeItems];

      // Immediately update the local state
      dispatch(
        setCurrentScopeItems({
          requirementId: requirementId!,
          scopeItems: updatedScopeItems,
        })
      );

      // Call the API to persist the changes
      updateScopeItems({
        requirementId: requirementId!,
        scopeItems: updatedScopeItems,
      })
        .unwrap()
        .then((result: any) => {
          toast.success("Successfully updated!");
        })
        .catch((err: any) => {
          toast.error(err?.data?.message || "Error updating items!");
          // Optionally, revert the local state change if the API call fails
          dispatch(
            setCurrentScopeItems({
              requirementId: requirementId!,
              scopeItems: scopeContext!.scopeItems,
            })
          );
        });
    }
  };

  return (
    <div className="px-4 pt-2 pb-8">
      <div className="sticky top-0 pt-4 bg-white/90 z-10 flex items-start rfi-header">
        <div className="flex grow gap-6 mb-4 items-center">
          <h2 className="grow text-[1.5rem] font-semibold">Scope of Project</h2>
          {project?.requirement?.isScopeApproved && (
            <StatusFlag status="approved" />
          )}
        </div>
        {scopeContext?.scopeItems.length ? (
          <div className="flex ml-4 gap-4">
            <button
              className="button-secondary bg-brandYellow border !border-yellow-500 flex items-center gap-2 px-4 shadow-sm rounded-lg text-lg hover:bg-yellow-50 focus:outline-none focus:ring focus:ring-green-200"
              onClick={viewOpenQuestions}
            >
              Assumptions & Questions
            </button>

            {!project?.requirement?.isScopeApproved && (
              <button
                className="button-primary-icon flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
                onClick={approveScope}
              >
                <AiOutlineCheckCircle
                  className="cursor-pointer"
                  size={20}
                  title="Approve"
                />
                Approve
              </button>
            )}
          </div>
        ) : null}
      </div>

      {scopeContext?.scopeItems.length ? (
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={(initial: any) => setDraggingId(initial.draggableId)}
        >
          <Droppable droppableId="scope-items">
            {(provided: any) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className="">
                  {renderTable(
                    ItemScopeType.IN_SCOPE,
                    scopeContext?.scopeItems || [],
                    "in-scope"
                  )}
                  {renderTable(
                    ItemScopeType.OUT_OF_SCOPE,
                    scopeContext?.scopeItems || [],
                    "out-of-scope"
                  )}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="w-full flex flex-col items-center p-12 bg-gray-25 rounded-xl border border-gray-100 shadow">
          <img
            src="/icons/estimation.png"
            className="w-8 opacity-50 animate-spin"
            alt="Generating scope items in progress"
          />
          <span className="text-sm text-gray-600 text-center">
            Generating Scope in progress. Please wait...
          </span>
        </div>
      )}
      <ConfirmModal
        show={isModalVisible}
        question="Be aware that by changing the scope you need to re-approve it again and this action will start work breakdown structure from the beginning. Are you sure you want to continue?"
        onConfirm={handleConfirmAddEdit}
        onCancel={handleCancelAddEdit}
      />
    </div>
  );

  function renderTable(
    itemScopeType: ItemScopeType,
    items: ScopeItem[],
    droppableId: string
  ) {
    return (
      <Droppable droppableId={droppableId}>
        {(provided: any) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="mt-2"
          >
            <div className="bg-gray-50 rounded rounded-xl p-6 px-8 mb-4">
              <div className="flex items-start">
                <h2 className="mb-4 grow">
                  {itemScopeType === ItemScopeType.IN_SCOPE
                    ? "In Scope"
                    : "Out of Scope"}
                </h2>
                {!project?.requirement?.isScopeApproved && items.length > 0 && (
                  <div>
                    <button
                      data-tooltip-id="addNew"
                      data-tooltip-content={
                        itemScopeType === ItemScopeType.IN_SCOPE
                          ? "Add new in-scope item"
                          : "Add new out-of-scope item"
                      }
                      onClick={() => addNewScopeItem(itemScopeType)}
                    >
                      <img src="/icons/add.png" className="w-6" alt="" />
                    </button>
                    <Tooltip id="addNew" />
                  </div>
                )}
              </div>

              <table className="table-fixed w-full mt-2">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="w-1/4 py-2 px-2 rounded-0 rounded-tl-lg rounded-bl-lg text-left">
                      Item
                    </th>
                    <th className="w-2/3 py-2 px-2 rounded-0 text-left">
                      Description
                    </th>
                    <th className="w-[150px] py-2 px-2 rounded-0 rounded-tr-lg rounded-br-lg text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {!loaded() ? (
                    <tr>
                      <td>
                        <TableLoader />
                      </td>
                    </tr>
                  ) : (
                    items
                      .filter((item) => item.type === itemScopeType)
                      .sort((a, b) => a.rank - b.rank) // Ensure the items are sorted by rank
                      .map((item: ScopeItem, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`hover:bg-gray-200 relative group ${
                                snapshot.isDragging
                                  ? "bg-gray-200 rounded-xl"
                                  : ""
                              }`}
                            >
                              <td
                                className={`py-2 pr-2 group-hover:rounded-0 group-hover:rounded-tl-lg group-hover:rounded-bl-lg ${
                                  snapshot.isDragging ? "w-1/4" : ""
                                }`}
                              >
                                {editingId === item.id ? (
                                  <div>
                                    <AutoResizeTextarea
                                      value={editingName}
                                      onChange={(e) =>
                                        setEditingName(e.target.value)
                                      }
                                      isLoading={false}
                                      ref={inputNameRef}
                                      onKeyPress={(e) => {
                                        onKeyPress(e);
                                      }}
                                    ></AutoResizeTextarea>
                                    <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                                      <AiOutlineCheck
                                        onClick={sendUpdateScopeItemChanges}
                                        size={20}
                                        className="hover:text-green-500 cursor-pointer"
                                      />
                                      <AiOutlineClose
                                        onClick={resetEditModel}
                                        size={20}
                                        className="hover:text-red-500 cursor-pointer"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className="flex items-center gap-2">
                                      <div>
                                        <img
                                          data-tooltip-id="dragItem"
                                          data-tooltip-content="Drag item to reorder or move it to another section"
                                          src="/icons/drag-item.png"
                                          className="w-5"
                                          alt=""
                                        />
                                        <Tooltip id={`dragItem`} />
                                      </div>
                                      <span>{item.name}</span>
                                    </div>
                                  </>
                                )}
                              </td>
                              <td
                                className={`py-2 pl-2 pr-2 ${
                                  snapshot.isDragging ? "w-2/3 pr-2 pl-0" : ""
                                }`}
                              >
                                {editingId === item.id ? (
                                  <div>
                                    <AutoResizeTextarea
                                      value={editingDescription}
                                      onChange={(e) =>
                                        setEditingDescription(e.target.value)
                                      }
                                      isLoading={false}
                                      ref={inputDescriptionRef}
                                      onKeyPress={(e) => {
                                        onKeyPress(e);
                                      }}
                                    ></AutoResizeTextarea>
                                    <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                                      <AiOutlineCheck
                                        onClick={sendUpdateScopeItemChanges}
                                        size={20}
                                        className="hover:text-green-500 cursor-pointer"
                                      />
                                      <AiOutlineClose
                                        onClick={resetEditModel}
                                        size={20}
                                        className="hover:text-red-500 cursor-pointer"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  item.description
                                )}
                              </td>
                              <td className="py-2 px-2 rounded-0 rounded-tr-lg rounded-br-lg text-right">
                                <div className="flex w-full justify-end space-x-4">
                                  {deletingId === item.id ? (
                                    <div className="flex items-center space-x-2">
                                      <span className="text-sm">
                                        Are you sure?
                                      </span>
                                      <div>
                                        <AiOutlineCheck
                                          data-tooltip-id={`confirmDelete-${item.id}`}
                                          data-tooltip-content="Yes"
                                          onClick={() =>
                                            handleConfirmDelete(item.id)
                                          }
                                          size={20}
                                          className="hover:text-green-500 cursor-pointer"
                                        />
                                        <Tooltip
                                          id={`confirmDelete-${item.id}`}
                                        />
                                      </div>
                                      <div>
                                        <AiOutlineClose
                                          data-tooltip-id={`confirmDelete-${item.id}`}
                                          data-tooltip-content="No"
                                          onClick={handleCancelDelete}
                                          size={20}
                                          className="hover:text-red-500 cursor-pointer"
                                        />
                                        <Tooltip
                                          id={`confirmDelete-${item.id}`}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    !project?.requirement?.isScopeApproved &&
                                    draggingId !== item.id && (
                                      <>
                                        <div>
                                          <AiOutlineEdit
                                            data-tooltip-id={`edit-${item.id}`}
                                            data-tooltip-content="Edit"
                                            onClick={() => handleEditItem(item)}
                                            className="hover:text-gray-500 cursor-pointer"
                                            size={20}
                                            title="Edit Item"
                                          />
                                          <Tooltip id={`edit-${item.id}`} />
                                        </div>
                                        <div>
                                          <AiOutlineDelete
                                            data-tooltip-id={`delete-${item.id}`}
                                            data-tooltip-content="Delete"
                                            onClick={() =>
                                              handleDelete(item.id)
                                            }
                                            className="text-red-500 cursor-pointer hover:text-red-700"
                                            size={20}
                                            title="Delete Item"
                                          />
                                          <Tooltip id={`delete-${item.id}`} />
                                        </div>
                                      </>
                                    )
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))
                  )}
                </tbody>
              </table>

              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    );
  }
};

export default ScopingPage;
