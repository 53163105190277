import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from "../store/project/projectApiService";
import { toast } from "react-toastify";
import { Project } from "../store/project/types";
import { setCurrentProject, setProjects } from "../store/project/projectSlice";
import { useDispatch } from "react-redux";
import { trackUser } from "../utils";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});

type NewOpportunityProps = {
  isOpen: boolean;
  toggleDrawer: () => void;
  project?: Project | null;
  projects?: Project[];
};

const NewOpportunity = ({
  isOpen,
  toggleDrawer,
  project,
  projects
}: NewOpportunityProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createProject] = useCreateProjectMutation();
  const [updateProject] = useUpdateProjectMutation();

  const formik = useFormik({
    initialValues: {
      title: project ? project.title : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (project) {
          const data = await updateProject({
            id: project.id,
            ...values,
          }).unwrap();
          dispatch(setCurrentProject(data));
          dispatch(setProjects([...projects!, data]));
          trackUser("Opportunity Updated", { project: data?.title });
          toggleDrawer();
          toast.success("Project updated successfully!");
        } else {
          const data = await createProject(values).unwrap();
          dispatch(setCurrentProject(data));
          navigate(`/dashboard/${data.data.id}`);
          trackUser("Opportunity Created", { project: data.data?.title, id: data.data?.id });
          toast.success("Project created successfully!");
        }
      } catch (err: any) {
        toast.error(err?.data?.message || "Error creating/updating project!");
      }
    },
  });

  useEffect(() => {
    if (project) {
      formik.setValues({
        title: project.title,
      });
    }
    // formik as dependency creates infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.title]);

  return (
    <div
      className={`fixed top-0 right-0 w-80 h-full bg-white shadow-md z-50 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="m-4 mt-12">
        <h2 className="text-lg font-semibold m-2">Create New Project</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="m-2 mt-12">
            <label className="block">
              Name*
              <input
                type="text"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                placeholder="Enter your opportunity name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.title && formik.errors.title ? (
              <div className="text-red-500">{formik.errors.title}</div>
            ) : null}
          </div>
          <div className="m-4  mt-8 flex items-center justify-center grow">
            <button type="submit" className="button-primary">
              {project ? "Update" : "Create"}
            </button>
            <button
              type="button"
              onClick={toggleDrawer}
              className="button-secondary w-[1/3] ml-4"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewOpportunity;
