import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AboutCompanyForm } from "./AboutCompanyForm";

const OnboardingWizard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const company = useSelector((state: RootState) => state.companyData.company);

  useEffect(() => {
    if (company?.isOnboarded) {
      navigate("/dashboard/company");
    }
  }, [company, navigate]);

  return (
    <div className="onboarding-wizard">
      <Routes location={location}>
        <Route path="/about-company" element={<AboutCompanyForm />} />
        <Route path="*" element={<Navigate to="/onboarding/about-company" />} />
      </Routes>
    </div>
  );
};

export default OnboardingWizard;
