import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Quote, QuoteItem, QuoteItemGroup } from "../../store/quote/types";
import { formatNumber, parseNumber } from "../utils/helperFunctions";
import { setCurrentQuote } from "../../store/project/projectSlice";
import { useNavigate, useParams } from "react-router-dom";
import { calculateDerivedFields, recalculateTotals } from "./quoteCalculations";
import { useUpdateQuoteMutation } from "../../store/project/projectApiService";
import { Project } from "../../store/project/types";
import { RootState } from "../../store";

function QuoteItemTable({ quote }: { quote: Quote }) {
  const dispatch = useDispatch();
  const { requirementId } = useParams();
  const navigate = useNavigate();
  const [updateQuote] = useUpdateQuoteMutation();

  const [shadowValues, setShadowValues] = useState<Record<string, string>>({});
  const [isModified, setIsModified] = useState(false);

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  useEffect(() => {
    const initialShadowValues: Record<string, string> = {};

    quote.quoteItemGroups.forEach((itemGroup) => {
      itemGroup.quoteItems.forEach((item) => {
        initialShadowValues[`${item.id}-discountPercentage`] = formatNumber(
          item.discountPercentage
        );
        initialShadowValues[`${item.id}-discountAmount`] = formatNumber(
          item.discountAmount
        );
        initialShadowValues[`${item.id}-netMarginPercentage`] = formatNumber(
          item.netMarginPercentage
        );
        initialShadowValues[`${item.id}-netMarginAmount`] = formatNumber(
          item.netMarginAmount
        );
        initialShadowValues[`${item.id}-netPrice`] = formatNumber(
          item.netPrice
        );
      });
    });

    setShadowValues(initialShadowValues);
  }, [quote]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    quoteItem: QuoteItem,
    field: keyof QuoteItem
  ) => {
    if (project?.requirement?.isQuoteApproved) {
      return;
    }
    const { value } = e.target;
    let numberValue = parseNumber(value);

    if (numberValue !== quoteItem[field]) {
      setIsModified(true);
    }
    setShadowValues((prev) => ({
      ...prev,
      [`${quoteItem.id}-${field}`]: value,
    }));
  };

  const handleInputBlur = useCallback(
    (
      e: React.FocusEvent<HTMLInputElement>,
      quoteItem: QuoteItem,
      field: keyof QuoteItem
    ) => {
      if (!isModified || project?.requirement?.isQuoteApproved) {
        return;
      }

      setIsModified(false);
      const { value } = e.target;
      let numberValue = parseNumber(value);

      const updatedQuote: Quote = JSON.parse(JSON.stringify(quote));

      updatedQuote.quoteItemGroups.forEach((itemGroup) => {
        itemGroup.quoteItems = itemGroup.quoteItems.map((item) => {
          if (item.id === quoteItem.id) {
            const updatedItem = {
              ...item,
              [field]: numberValue,
              ...calculateDerivedFields(item, field, numberValue),
            };
            return updatedItem;
          }
          return item;
        });
      });

      recalculateTotals(updatedQuote);

      dispatch(
        setCurrentQuote({
          requirementId: requirementId!,
          quote: updatedQuote,
        })
      );
      updateQuote(updatedQuote);
    },
    [quote, dispatch, requirementId, updateQuote, isModified]
  );

  function changeEstimation(phaseId: string): void {
    navigate(`/dashboard/${project?.id}/work-breakdown-structure/${quote.requirementId}/tasks-and-subtasks/${phaseId}`);
  }

  return (
    <>
      {quote.quoteItemGroups.map((itemGroup: QuoteItemGroup, index: number) => (
        <div key={index} className="mb-8 shadow border border-gray-200 rounded rounded-xl">
          <div className="flex-grow p-7">
            <h2 className="text-[1.45rem] grow font-semibold m-0">
            {itemGroup.name}
            </h2>
          </div>

          <table className="min-w-full bg-white shadow rounded-lg">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-2 text-left">Role</th>
                <th className="px-4 py-2 text-left">Cost per Hour</th>
                <th className="px-4 py-2 text-left">Total Hours</th>
                <th className="px-4 py-2 text-left">Total Cost</th>
                <th className="px-4 py-2 text-left">Hourly Rate</th>
                <th className="px-4 py-2 text-left">List Price</th>
                <th className="px-4 py-2 text-left">Gross Margin</th>
                <th className="px-4 py-2 text-left">Discount (%)</th>
                <th className="px-4 py-2 text-left">Discount</th>
                <th className="px-4 py-2 text-left">Net Margin (%)</th>
                <th className="px-4 py-2 text-left">Net Margin</th>
                <th className="px-4 py-2 text-left">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {itemGroup.quoteItems.map((item: QuoteItem) => (
                <tr
                  key={item.id}
                  className="group cursor-pointer border-t border-gray-200 first:border-t-0 relative text-gray-700 hover:bg-gray-50"
                >
                  <td className="px-4 py-2">{item.resourceType.name}</td>
                  <td className="px-4 py-2">
                    {formatNumber(item.costPerHour)}
                  </td>
                  <td className="px-4 py-2">{formatNumber(item.totalHours)}</td>
                  <td className="px-4 py-2">{formatNumber(item.totalCost)}</td>
                  <td className="px-4 py-2">{formatNumber(item.hourlyRate)}</td>
                  <td className="px-4 py-2">{formatNumber(item.listPrice)}</td>
                  <td className="px-4 py-2">
                    {formatNumber(item.grossMarginPercentage)}%
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="text"
                      value={
                        shadowValues[`${item.id}-discountPercentage`] ?? ""
                      }
                      className="w-full border-b focus:outline-brand p-1"
                      onChange={(e) =>
                        handleInputChange(e, item, "discountPercentage")
                      }
                      onBlur={(e) =>
                        handleInputBlur(e, item, "discountPercentage")
                      }
                    />
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="text"
                      value={shadowValues[`${item.id}-discountAmount`] ?? ""}
                      className="w-full border-b inline-block focus:outline-brand p-1"
                      onChange={(e) =>
                        handleInputChange(e, item, "discountAmount")
                      }
                      onBlur={(e) => handleInputBlur(e, item, "discountAmount")}
                    />
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="text"
                      value={
                        shadowValues[`${item.id}-netMarginPercentage`] ?? ""
                      }
                      className="w-full border-b focus:outline-brand p-1"
                      onChange={(e) =>
                        handleInputChange(e, item, "netMarginPercentage")
                      }
                      onBlur={(e) =>
                        handleInputBlur(e, item, "netMarginPercentage")
                      }
                    />
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="text"
                      value={shadowValues[`${item.id}-netMarginAmount`] ?? ""}
                      className="w-full border-b inline-block focus:outline-brand p-1"
                      onChange={(e) =>
                        handleInputChange(e, item, "netMarginAmount")
                      }
                      onBlur={(e) =>
                        handleInputBlur(e, item, "netMarginAmount")
                      }
                    />
                  </td>
                  <td className="px-4 py-2 relative">
                    <input
                      type="text"
                      value={shadowValues[`${item.id}-netPrice`] ?? ""}
                      className="w-full border-b focus:outline-brand p-1"
                      onChange={(e) => handleInputChange(e, item, "netPrice")}
                      onBlur={(e) => handleInputBlur(e, item, "netPrice")}
                    />
                  </td>
                </tr>
              ))}
              <tr className="bg-brandYellow group">
                <td colSpan={2} className="text-right px-4 py-5 font-bold rounded-0 rounded-bl-xl">
                  Subtotal:
                </td>
                <td className="px-4 py-5 font-bold">{itemGroup.totalHours}</td>
                <td className="px-4 py-5 font-bold">
                  {formatNumber(itemGroup.totalCost)}
                </td>
                <td className="px-4 py-5 font-bold">
                  {formatNumber(itemGroup.averageHourlyRate)}
                </td>
                <td className="px-4 py-5 font-bold">
                  {formatNumber(itemGroup.listPrice)}
                </td>
                <td className="px-4 py-5 font-bold">
                  {formatNumber(itemGroup.grossMarginPercentage)}%
                </td>
                <td className="px-4 py-5 font-bold">
                  {formatNumber(itemGroup.discountPercentage)}%
                </td>
                <td className="px-4 py-5 font-bold">
                  {formatNumber(itemGroup.discountAmount)}
                </td>
                <td className="px-4 py-5 font-bold">
                  {formatNumber(itemGroup.netMarginPercentage)}%
                </td>
                <td className="px-4 py-5 font-bold">
                  {formatNumber(itemGroup.netMarginAmount)}
                </td>
                <td className="px-4 py-5 font-bold rounded-0 rounded-br-xl">
                  {formatNumber(itemGroup.netPrice)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </>
  );
}

export default QuoteItemTable;
