export const MobileNote = () => {

  return (
    <div className="w-full h-screen bg-brandYellow flex flex-col gap-12 items-center justify-center">
      <img
        src="/images/main-logo.png"
        className="w-36"
        alt="EstiWiz - Your best buddy in giving proposals!"
      />
      <div className="text-center">
        Hi Mobile User! <br />
        This app is not yet optimized for mobile. <br />
        Please use a desktop browser for better experience.
      </div>
    </div>
  );
};