import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCheckCircle, AiOutlineLeft } from "react-icons/ai";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store";
import {
  Project,
  ProjectStage,
  RequiredResourcesSummary,
  ResourceRequirement,
} from "../../store/project/types";
import {
  useApproveStageMutation,
  useGetRequestResourceRequirementsQuery,
} from "../../store/project/projectApiService";
import {
  setCurrentProject,
  setCurrentResourceSummary,
} from "../../store/project/projectSlice";
import { TableLoader } from "../utils/ContentLoader";
import { toast } from "react-toastify";
import { trackUser } from "../../utils";
import { toCurrencyFormat } from "../utils/helperFunctions";
import ResourceRequirementDrawer from "./ResourceRequirementDrawer";
import StatusFlag from "../utils/StatusFlag";
import Tabs from "../utils/Tabs";
import ResourceOverviewScope from "./ResourceOverviewScope";

const ResourceOverview = () => {
  const dispatch = useDispatch();
  const { requirementId } = useParams();
  const [approveStage] = useApproveStageMutation();
  const resourceSummary = useSelector<
    RootState,
    RequiredResourcesSummary | null
  >((state) => state.projectData.currentResourceSummary);

  const { data: requestResourceSummaryData } =
    useGetRequestResourceRequirementsQuery(requirementId!);

  useEffect(() => {
    if (requestResourceSummaryData) {
      dispatch(setCurrentResourceSummary(requestResourceSummaryData.data));
    }
  }, [requestResourceSummaryData, dispatch]);

  const project = useSelector<RootState, Project | null>(
    (state) => state.projectData.currentProject
  );

  const [selectedRequirement, setSelectedRequirement] =
    useState<ResourceRequirement | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const isChildRoute = location.pathname.includes("resource-type-planning");

  if (isChildRoute) {
    return <Outlet />;
  }

  const approveResources = async () => {
    try {
      await approveStage({
        requirementId: requirementId!,
        stage: ProjectStage.WORK_BREAKDOWN,
      }).unwrap();
      const { data } = await approveStage({
        requirementId: requirementId!,
        stage: ProjectStage.RESOURCES,
      }).unwrap();
      trackUser("Approve Resources and WBS", { requirementId });
      dispatch(setCurrentProject(data));
      navigate(`/dashboard/${project?.id}/quote/${project?.requirement?.id}`);
    } catch (err: any) {
      toast.error(err?.data?.message || "Error approving scope!");
    }
  };

  const handleRowClick = (requirement: ResourceRequirement) => {
    setSelectedRequirement(requirement);
    setIsDrawerOpen(true);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const tabs = [
    {
      label: "Resource Summary",
      content: (
        <div className="container shadow border border-gray-200 rounded rounded-xl">
          <h2 className="text-[1.2rem] font-semibold py-8 px-8 mb-0">
            Projected Profiles Needed
          </h2>
          <div>
            <table className="min-w-full text-[15px]">
              <thead>
                <tr>
                  <th className="w-1/2 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                    Role
                  </th>
                  <th className="w-1/6 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                    Cost per Hour
                  </th>
                  <th className="w-1/6 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                    Hourly Rate
                  </th>
                  <th className="w-1/6 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                    Effort
                  </th>
                </tr>
              </thead>
              <tbody>
                {!resourceSummary ? (
                  <tr>
                    <td>
                      <TableLoader />
                    </td>
                  </tr>
                ) : (
                  resourceSummary.summary.map(
                    (
                      { resourceType, effort, averageCostPerHour, hourlyRate },
                      index
                    ) => (
                      <tr
                        key={index}
                        className={`even:bg-gray-50 text-gray-700 hover:bg-brandSubtle hover:text-brand ${
                          project?.requirement?.isResourcesApproved
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        onClick={() => {
                          if (!project?.requirement?.isResourcesApproved) {
                            handleRowClick(resourceSummary.summary[index]);
                          }
                        }}
                      >
                        <td className="py-4 pl-8 align-top font-normal h-full">
                          {resourceType.name}
                        </td>
                        <td className="py-4 pl-8 align-top font-semibold h-full">
                          {toCurrencyFormat(averageCostPerHour)}
                        </td>
                        <td className="py-4 pl-8 align-top font-semibold h-full">
                          {toCurrencyFormat(hourlyRate)}
                        </td>
                        <td className="py-4 pl-8 align-top font-semibold h-full">
                          {effort} Hours
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={3}
                    className="py-7 pl-8 align-top font-bold text-gray-600 h-full bg-brandYellow rounded-0 rounded-bl-xl"
                  >
                    Total
                  </td>
                  <td className="py-7 pl-8 align-top font-bold text-gray-600 h-full bg-brandYellow rounded-0 rounded-br-xl">
                    {resourceSummary?.summary.reduce(
                      (acc, { effort }) => acc + +effort,
                      0
                    )}{" "}
                    Hours
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      ),
    },
    {
      label: "Scope Summary",
      content: (
        <ResourceOverviewScope
          scopes={resourceSummary?.scopes || []}
          toggleDrawer={toggleDrawer}
          setSelectedRequirement={setSelectedRequirement}
        />
      ),
    },
  ];

  return (
    <div className="px-4 pt-2 pb-8">
      <div className="sticky top-0 pt-4 bg-white/90 z-10 flex items-start rfi-header w-full">
        <div className="flex grow gap-6 mb-4 items-center">
          <h2 className="grow text-[1.5rem] font-semibold">
            Resources Overview
          </h2>
          {project?.requirement?.isResourcesApproved && (
            <StatusFlag status="approved" />
          )}
        </div>
        <div className="flex ml-4 gap-4">
          {!project?.requirement?.isResourcesApproved && (
            <div className="flex items-center gap-4">
              <button
                className="button-secondary flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
                onClick={() =>
                  navigate(
                    `/dashboard/${project?.id}/work-breakdown-structure/${project?.requirement?.id}`
                  )
                }
              >
                <AiOutlineLeft
                  className="hover:text-gray-500 cursor-pointer"
                  size={20}
                  title="Approve Resources"
                />
                Back to WBS
              </button>
              <button
                disabled={!project?.requirement?.isResourcesGenerated}
                className="button-primary-icon flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
                onClick={approveResources}
              >
                <AiOutlineCheckCircle
                  className="hover:text-gray-500 cursor-pointer"
                  size={20}
                  title="Approve Resources"
                />
                Approve
              </button>
            </div>
          )}
        </div>
      </div>
      {project?.requirement?.isResourcesApproved && (
        <div className="bg-brandYellow p-4 rounded-xl mb-4">
          <span className="text-[#475467]">
            Resources Overview is approved. You can no longer edit assignments.
          </span>
        </div>
      )}
      <Tabs tabs={tabs} />
      <ResourceRequirementDrawer
        isOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        resourceRequirement={selectedRequirement}
      />
    </div>
  );
};

export default ResourceOverview;
