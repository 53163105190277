import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../store/users/userApi";
import { RootState } from "../../store";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
} from "react-icons/ai";
import InviteUserDrawer from "./InviteUserDrawer";
import { UserAccount } from "../../store/users/types";
import { setUsersData } from "../../store/users/userDataSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

const ManageUsersComponent: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const [deleteUser] = useDeleteUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [deletingId, setDeletingId] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingFirstName, setEditingFirstName] = useState("");
  const [editingLastName, setEditingLastName] = useState("");

  const users = useSelector<RootState, UserAccount[] | null>((state) => {
    return state.usersData.users;
  });

  const { data, isSuccess } = useGetUsersQuery();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUsersData(data.data));
    }
  }, [isSuccess, data, dispatch]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleDeleteClick = (userId: string) => {
    if (userId === user?.sub) {
      toast.error("You cannot delete yourself");
    } else {
      setDeletingId(userId);
    }
  };

  const handleConfirmDelete = async (id: string) => {
    try {
      await deleteUser(id).unwrap();
      toast.success("User deleted successfully!");
      setDeletingId("");
    } catch (err: any) {
      toast.error(err?.data?.message || "Error deleting user!");
    }
  };

  const handleCancelDelete = () => {
    setDeletingId("");
  };

  const startEditing = (user: UserAccount) => {
    setEditingId(user.id);
    setEditingFirstName(user.firstName);
    setEditingLastName(user.lastName);
  };

  const cancelEditing = () => {
    setEditingId(null);
    setEditingFirstName("");
    setEditingLastName("");
  };

  const handleSaveEdit = async (id: string) => {
    try {
      await updateUser({
        id,
        firstName: editingFirstName,
        lastName: editingLastName,
      }).unwrap();
      toast.success("User updated successfully!");
      setEditingId(null);
    } catch (err: any) {
      toast.error(err?.data?.message || "Error updating user!");
    }
  };

  return (
    <div className="p-4 mt-2">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Manage Users</h2>
        <button
          className="button-primary-icon flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
          onClick={toggleDrawer}
        >
          <AiOutlinePlus size={20} title="Invite User" />
          Invite User
        </button>
      </div>
      <InviteUserDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <ul>
        {users?.map((user) => (
          <li key={user.id} className="mb-2 p-2 border">
            <div className="flex justify-between items-center">
              {editingId === user.id ? (
                <>
                  <input
                    type="text"
                    value={editingFirstName}
                    onChange={(e) => setEditingFirstName(e.target.value)}
                    className="w-1/4 text-sm font-semibold border px-2 py-1"
                  />
                  <input
                    type="text"
                    value={editingLastName}
                    onChange={(e) => setEditingLastName(e.target.value)}
                    className="w-1/4 text-sm font-semibold border px-2 py-1"
                  />
                  <div className="w-1/4 text-sm font-semibold">
                    {user.email}
                  </div>
                  <div className="w-1/4 flex justify-end space-x-2 pr-4">
                    <AiOutlineCheck
                      className="hover:text-green-500 cursor-pointer"
                      onClick={() => handleSaveEdit(user.id)}
                      size={20}
                    />
                    <AiOutlineClose
                      className="hover:text-red-500 cursor-pointer"
                      onClick={cancelEditing}
                      size={20}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="w-1/4 text-sm font-semibold">
                    {user.firstName}
                  </div>
                  <div className="w-1/4 text-sm font-semibold">
                    {user.lastName}
                  </div>
                  <div className="w-1/4 text-sm font-semibold">
                    {user.email}
                  </div>
                  <div className="w-1/4 flex justify-end space-x-2 pr-4">
                    <AiOutlineEdit
                      className="hover:text-blue-500 cursor-pointer"
                      onClick={() => startEditing(user)}
                      size={20}
                    />
                    {deletingId === user.id ? (
                      <div className="flex items-center space-x-2">
                        <span>Are you sure?</span>
                        <AiOutlineCheck
                          className="hover:text-green-500 cursor-pointer"
                          onClick={() => handleConfirmDelete(user.id)}
                          size={20}
                        />
                        <AiOutlineClose
                          className="hover:text-red-500 cursor-pointer"
                          onClick={handleCancelDelete}
                          size={20}
                        />
                      </div>
                    ) : (
                      <AiOutlineDelete
                        className="hover:text-red-500 cursor-pointer"
                        size={20}
                        onClick={() => handleDeleteClick(user.id)}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageUsersComponent;
