export interface Company {
  id: string;
  name: string;
  streetAddress: string;
  suite: string;
  city: string;
  postalCode: string;
  country: string;
  state: string;
  contactEmail: string;
  contactPhone: string;
  isOnboarded: boolean;
  documents?: Document[];
  pricingPlan?: string;
}

export interface Document {
  id: string;
  name: string;
  capabilityType: CapabilityType;
  processingStatus: DocumentProcessingStatus;
  content?: string;
  dateAdded?: Date;
}

export enum CapabilityType {
  CompanyCapability = "COMPANY_CAPABILITY",
  ProductCapability = "PRODUCT_CAPABILITY",
}

export enum DocumentProcessingStatus {
  Pending = "PENDING",
  Processing = "PROCESSING",
  Processed = "PROCESSED",
  Failed = "FAILED",
}
