import { createSlice } from "@reduxjs/toolkit";
import { Company, Document } from "./types";

interface CompanyDataState {
  company: Company | null;
  documents: Document[];
}

const initialState: CompanyDataState = {
  company: null,
  documents: [],
};

export const CompanyDataSlice = createSlice({
  name: "CompanyData",
  initialState,
  reducers: {
    setCompanyData: (state, action) => {
      state.company = action.payload;
    },
    addDocument: (state, action) => {
      if (state.documents === undefined) {
        state.documents = [];
      }
      state.documents.push(action.payload);
    },
    setCompanyDocuments: (state, action) => {
      state.documents = action.payload;
    },
    removeDocument: (state, action) => {
      state.documents = state.documents.filter(
        (doc) => doc.id !== action.payload.id
      );
    },
  },
});

export const {
  setCompanyData,
  setCompanyDocuments,
  addDocument,
  removeDocument,
} = CompanyDataSlice.actions;

export default CompanyDataSlice.reducer;
