import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store";
import {
  PhaseResourceTypeDistribution,
  RequirementTaskResource,
  ResourceRequirement,
} from "../../store/project/types";
import {
  useGetPhaseResourceTypeResourcesQuery,
  useDeleteResourceMutation,
  useUpdateRequestResourceRequirementsMutation,
} from "../../store/project/projectApiService";
import { setPhaseResourceTypeDistribution } from "../../store/project/projectSlice";
import { TableLoader } from "../utils/ContentLoader";
import { toast } from "react-toastify";

const ResourceTypeEffortPlanning = () => {
  const dispatch = useDispatch();
  const [deletingId, setDeletingId] = useState<string>("");
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingEffort, setEditingEffort] = useState<number | null>(null);
  const { resourceTypeId, phaseId } = useParams();
  const phaseResourceTypeDistribution = useSelector<
    RootState,
    PhaseResourceTypeDistribution | null
  >((state) => {
    return state.projectData.phaseResourceTypeDistribution;
  });

  const { data: phaseResourceTypeResources } =
    useGetPhaseResourceTypeResourcesQuery(
      {
        phaseId: phaseId!,
        resourceTypeId: resourceTypeId!,
      },
      {
        skip: !resourceTypeId || !phaseId,
      }
    );

  useEffect(() => {
    if (phaseResourceTypeResources) {
      dispatch(
        setPhaseResourceTypeDistribution(phaseResourceTypeResources.data)
      );
    }
  }, [phaseResourceTypeResources, dispatch]);

  const [deleteProjectRequirementResource] = useDeleteResourceMutation();
  const [updateResource] = useUpdateRequestResourceRequirementsMutation();
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const deleteResourceClick = (id: string) => {
    setDeletingId(id);
  };

  const handleConfirmDelete = () => {
    deleteProjectRequirementResource(deletingId)
      .unwrap()
      .then(({ data }) => {
        dispatch(setPhaseResourceTypeDistribution(data));
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
      });
    setDeletingId("");
  };

  const handleCancelDelete = () => {
    setDeletingId("");
  };

  const stratEditing = (item: RequirementTaskResource) => {
    setEditingId(item.id);
    setEditingEffort(item.effort);
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendUpdatedEffort();
    } else if (e.key === "Escape") {
      resetEditModel();
    }
  };

  const sendUpdatedEffort = () => {
    if (editingId) {
      updateResource({
        id: editingId,
        effort: editingEffort!,
      })
        .unwrap()
        .catch((err: any) => {
          toast.error(err?.data?.message || "Error!");
        });
    }
    resetEditModel();
  };

  const resetEditModel = () => {
    setEditingId(null);
    setEditingEffort(null);
  };

  return (
    <div className="p-4 pb-8">
      <div className="container shadow border border-gray-200 rounded rounded-xl">
        <div className="flex items-start rfi-header px-8 py-8">
          <div className="flex-grow mb-4">
            <h1 className="grow">
              {phaseResourceTypeDistribution?.phase.name} -{" "}
              {phaseResourceTypeDistribution?.resourceType.name}
            </h1>
          </div>
        </div>
        {!phaseResourceTypeDistribution ||
        phaseResourceTypeDistribution.phase.id !== phaseId ||
        phaseResourceTypeDistribution.resourceType.id !== resourceTypeId ? (
          <TableLoader />
        ) : (
          <table className="min-w-full text-[15px] mb-20 mb-1">
            <tbody>
              {phaseResourceTypeDistribution.tasks.map((task, phaseIndex) => (
                <tr
                  key={phaseIndex}
                  onMouseEnter={() => setHoveredRow(phaseIndex)}
                  onMouseLeave={() => setHoveredRow(null)}
                  className={`${
                    hoveredRow === phaseIndex ? "bg-blue-100" : "bg-white"
                  }`}
                >
                  <td className="w-1/4 py-4 pl-8 align-top font-bold text-gray-600 border-b border-r border-gray-300 h-full">
                    {task.name}
                  </td>
                  <td className="w-3/4 pl-8 py-4 border-b border-gray-300 h-full">
                    <table className="w-full">
                      <tbody>
                        {task.subTasks.map((subTask) => (
                          <tr key={subTask.id}>
                            <td className="w-2/3 py-4 pl-8 align-top font-bold text-gray-600 border-b border-r border-gray-300 h-full">
                              <h3>{subTask.name}</h3>
                              <p className="font-normal text-gray-500 text-sm">
                                {subTask.description}
                              </p>
                            </td>
                            <td className="w-1/3 pl-12 py-4 border-b border-gray-300 h-full">
                              <table className="w-full">
                                <tbody>
                                  {subTask.resources.map((resource) => (
                                    <tr key={resource.id}>
                                      <td className="w-1/2 py-4 h-full">
                                        {editingId === resource.id ? (
                                          <div className="grow relative">
                                            <input
                                              type="number"
                                              value={editingEffort?.toString()}
                                              onChange={(e) =>
                                                setEditingEffort(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                              autoFocus
                                              className="block w-full py-2 text-gray-900 bg-gray-100 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-opacity-50"
                                              onKeyUp={onEnterPress}
                                            />
                                            <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                                              <AiOutlineCheck
                                                onClick={sendUpdatedEffort}
                                                size={20}
                                                className="hover:text-green-500 cursor-pointer"
                                              />
                                              <AiOutlineClose
                                                onClick={resetEditModel}
                                                size={20}
                                                className="hover:text-red-500 cursor-pointer"
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <span>{resource.effort} Hours</span>
                                        )}
                                      </td>
                                      <td className="w-1/2 py-4 h-full relative">
                                        <div className="flex space-x-2 mt-2 pr-4 justify-end">
                                          {deletingId === resource.id ? (
                                            <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
                                              <span className="text-sm mr-2">
                                                Are you sure?
                                              </span>
                                              <AiOutlineCheck
                                                className="hover:text-green-500 cursor-pointer"
                                                onClick={() =>
                                                  handleConfirmDelete()
                                                }
                                                size={20}
                                              />
                                              <AiOutlineClose
                                                className="hover:text-red-500 cursor-pointer"
                                                onClick={() =>
                                                  handleCancelDelete()
                                                }
                                                size={20}
                                              />
                                            </div>
                                          ) : (
                                            <>
                                              <AiOutlineEdit
                                                className="hover:text-gray-500 cursor-pointer"
                                                size={20}
                                                onClick={() =>
                                                  stratEditing(resource)
                                                }
                                                title="Edit Role"
                                              />

                                              <AiOutlineDelete
                                                className="hover:text-gray-500 cursor-pointer"
                                                size={20}
                                                onClick={() =>
                                                  deleteResourceClick(
                                                    resource.id
                                                  )
                                                }
                                                title="Delete Resource"
                                              />
                                            </>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ResourceTypeEffortPlanning;
