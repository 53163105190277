export interface Project {
  id: string;
  title: string;
  requirement?: Requirement | null;
}

export interface Requirement {
  id: string;
  version: string;
  fileName: string;
  isScopeApproved: boolean;
  isWorkBreakdownApproved: boolean;
  isResourcesApproved: boolean;
  isQuoteApproved: boolean;
  isScopeGenerated: boolean;
  isWorkBreakdownPhasesGenerated: boolean;
  isResourcesGenerated: boolean;
  isQuoteGenerated: boolean;
  scopeItems: ScopeItem[];
  questions: RequirementQuestion[];
  assumptions: RequirementAssumption[];
  prerequisites: RequirementPrerequisite[];
  phases: RequirementPhase[];
}

export enum ItemScopeType {
  IN_SCOPE = "IN_SCOPE",
  OUT_OF_SCOPE = "OUT_OF_SCOPE",
}
export interface ScopeItem {
  id: string;
  name: string;
  description: string;
  type: ItemScopeType;
  isConfirmed: boolean;
  rank: number;
}

export interface RequirementQuestion {
  id: string;
  question: string;
  answer: string;
}

export interface RequirementAssumption {
  id: string;
  description: string;
  isConfirmed: boolean;
}

export interface RequirementPrerequisite {
  id: string;
  description: string;
  isConfirmed: boolean;
}

export interface RequirementPhase {
  id: string;
  name: string;
  description: string;
  tasks: RequirementTask[];
  isResourcesGenerated: boolean;
  isTasksGenerated: boolean;
}

export interface RequirementTask {
  id: string;
  name: string;
  description: string;
  isResourcesGenerated: boolean;
  subTasks: RequirementTask[];
  isConfirmed: boolean;
  parentId: string;
  resources: RequirementTaskResource[];
}

export interface ResourceType {
  id: string;
  name: string;
}

export interface RequirementTaskResource {
  id: string;
  resourceType: ResourceType;
  effort: number;
}

export interface RequiredResourcesSummary {
  phases: PhaseResourceRequirement[];
  summary: ResourceRequirement[];
  scopes: ScopeResourceRequirement[];
}

export interface ScopeResourceRequirement {
  id: string;
  name: string;
  description: string;
  resources: ResourceRequirement[];
}

export interface PhaseResourceRequirement {
  id: string;
  name: string;
  resources: ResourceRequirement[];
}

export interface ResourceRequirement {
  id: string;
  resourceType: ResourceType;
  averageCostPerHour: number;
  hourlyRate: number;
  effort: number;
}

export enum ProjectStage {
  SCOPE = "SCOPE",
  WORK_BREAKDOWN = "WORK_BREAKDOWN",
  RESOURCES = "RESOURCES",
  QUOTE = "QUOTE",
  PROPOSAL = "PROPOSAL",
}

export interface PhaseResourceTypeDistribution {
  resourceType: ResourceType;
  phase: RequirementPhase;
  tasks: RequirementTask[];
}
