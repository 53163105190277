import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import {
  Project,
  RequirementPhase,
  RequirementTask,
  ResourceType,
} from "../../store/project/types";
import {
  useGetProjectPhaseTasksAndSubTasksQuery,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
} from "../../store/project/projectApiService";
import { setCurrentPhase } from "../../store/project/projectSlice";
import { TableLoader } from "../utils/ContentLoader";
import { trackUser } from "../../utils";
import TaskEditDrawer from "./TaskEditDrawer";
import { toast } from "react-toastify";
import { useGetResourceTypesQuery } from "../../store/resourceType/resourceTypeApiService";
import { setResourceTypes } from "../../store/resourceType/resourceTypeSlice";

const TasksAndSubtasks = () => {
  const dispatch = useDispatch();
  const { phaseId, requirementId } = useParams();
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showMask, setShowMask] = useState(false);
  const [editingTask, setEditingTask] = useState<RequirementTask | null>(null);
  const [resourcesTotal, setResourcesTotal] = useState<
    { resourceType: string; effort: number }[]
  >([]);

  const requirementPhase = useSelector<RootState, RequirementPhase | null>(
    (state) => {
      return state.projectData.currentPhase;
    }
  );

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  const { data: requestPhaseData } = useGetProjectPhaseTasksAndSubTasksQuery(
    phaseId!,
    {
      skip: phaseId == null,
    }
  );

  const resourceTypes = useSelector<RootState, ResourceType[]>(
    (state) => state.resourceTypeData.resourceTypes
  );

  const { data: resourceTypesData } = useGetResourceTypesQuery();

  useEffect(() => {
    if (requestPhaseData) {
      dispatch(setCurrentPhase(requestPhaseData.data));
      const total = aggregateResources(requestPhaseData.data);
      setResourcesTotal(total);
    }
  }, [requestPhaseData, dispatch]);

  useEffect(() => {
    if (resourceTypesData) {
      dispatch(setResourceTypes(resourceTypesData.data));
    }
  }, [resourceTypesData, dispatch]);

  const [deleteTask] = useDeleteTaskMutation();
  const [updateTask] = useUpdateTaskMutation();

  const editTask = (task: RequirementTask) => {
    if (project?.requirement?.isWorkBreakdownApproved) {
      return;
    }
    trackUser("Edit Task", { taskId: task.id });
    setEditingTask(task);
    setDrawerSequence(true);
  };

  const setDrawerSequence = (open: boolean) => {
    if (!open) {
      setTimeout(() => {
        setShowMask(open);
      });
      setTimeout(() => {
        setIsEditing(open);
      }, 300);
    } else {
      setTimeout(() => {
        setIsEditing(open);
      });
      setTimeout(() => {
        setShowMask(open);
      }, 300);
    }
  };

  const handleConfirmDelete = async (item: RequirementTask) => {
    try {
      if (item.parentId) {
        const parentTask: RequirementTask | undefined =
          requirementPhase!.tasks.find((t) => t.id === item.parentId);
        if (parentTask) {
          const updatedSubTasks = parentTask.subTasks.filter(
            (t) => t.id !== item.id
          );
          const updatedTasks = requirementPhase!.tasks.map((t) =>
            t.id === parentTask.id
              ? { ...parentTask, subTasks: updatedSubTasks }
              : t
          );
          dispatch(
            setCurrentPhase({
              ...requirementPhase!,
              tasks: updatedTasks,
            })
          );
          const total = aggregateResources({
            ...requirementPhase!,
            tasks: updatedTasks,
          });
          setResourcesTotal(total);
        }
      } else {
        const updatedTasks = requirementPhase!.tasks.filter(
          (task) => task.id !== item.id
        );
        dispatch(
          setCurrentPhase({
            ...requirementPhase!,
            tasks: updatedTasks,
          })
        );
        const total = aggregateResources({
          ...requirementPhase!,
          tasks: updatedTasks,
        });
        setResourcesTotal(total);
      }
      await deleteTask({
        requirementId: requirementId!,
        id: item.id,
      }).unwrap();
      toast.success("Successfully deleted!");
    } catch (err: any) {
      toast.error(err?.data?.message || "Error deleting item!");
    }
  };

  const handleSaveEdit = async (task: RequirementTask) => {
    try {
      if (task.parentId) {
        const parentTask: RequirementTask | undefined =
          requirementPhase!.tasks.find((t) => t.id === task.parentId);
        if (parentTask) {
          const updatedSubTasks = parentTask.subTasks.map((t) =>
            t.id === task.id ? task : t
          );
          const updatedTasks = requirementPhase!.tasks.map((t) =>
            t.id === parentTask.id
              ? { ...parentTask, subTasks: updatedSubTasks }
              : t
          );
          dispatch(
            setCurrentPhase({
              ...requirementPhase!,
              tasks: updatedTasks,
            })
          );
          const total = aggregateResources({
            ...requirementPhase!,
            tasks: updatedTasks,
          });
          setResourcesTotal(total);
        }
      } else {
        const updatedTasks = requirementPhase!.tasks.map((t) =>
          t.id === task.id ? task : t
        );
        dispatch(
          setCurrentPhase({
            ...requirementPhase!,
            tasks: updatedTasks,
          })
        );
        const total = aggregateResources({
          ...requirementPhase!,
          tasks: updatedTasks,
        });
        setResourcesTotal(total);
      }
      await updateTask(task).unwrap();
      toast.success("Successfully updated!");
      setDrawerSequence(false);
      setEditingTask(null);
    } catch (err: any) {
      toast.error(err?.data?.message || "Error updating item!");
    }
  };

  const loaded = () => {
    return requirementPhase && requirementPhase.id === phaseId;
  };

  function handleBackClick(): void {
    window.history.back();
  }

  const aggregateResources = (
    currentPhase: RequirementPhase
  ): { resourceType: string; effort: number }[] => {
    const resourceMap: Map<string, number> = new Map();
    let total = 0;

    // Iterate through tasks, subtasks, and resources to aggregate efforts

    currentPhase?.tasks.forEach((task) => {
      task?.subTasks.forEach((subtask) => {
        subtask.resources.forEach((resource) => {
          const { resourceType, effort } = resource;
          const resourceName = resourceType.name;
          const parsedEffort = parseInt(effort.toString()); // Parse effort as integer

          if (!isNaN(parsedEffort)) {
            if (resourceMap.has(resourceName)) {
              // If resource type exists, add effort
              const currentValue = resourceMap.get(resourceName) || 0;
              resourceMap.set(resourceName, currentValue + parsedEffort);
            } else {
              // If resource type doesn't exist, initialize with effort
              resourceMap.set(resourceName, parsedEffort);
            }
          } else {
            console.warn(
              `Invalid effort value for resource '${resourceName}': '${effort}'. Skipping.`
            );
          }
          total = total + parsedEffort;
        });
      });
    });

    // Convert Map to array of objects
    const aggregatedResources: { resourceType: string; effort: number }[] = [];
    resourceMap.forEach((effort, resourceType) => {
      aggregatedResources.push({ resourceType, effort });
    });

    aggregatedResources.push({ resourceType: "TOTAL", effort: total });

    return aggregatedResources;
  };

  return (
    <div className="p-4 pb-8 mt-4">
      {project?.requirement?.isWorkBreakdownApproved && (
        <div className="bg-brandYellow p-4 rounded-xl mb-4">
          <span className="text-[#475467]">
            Work Breakdown Structure is approved. You can no longer edit tasks.
          </span>
        </div>
      )}
      <div className="container shadow border border-gray-200 rounded rounded-xl">
        <div className="wbs-phase-header flex flex-col items-start pr-6 mb-4">
          <button
            className="flex items-center gap-2 mt-3 ml-3 text-brand"
            onClick={handleBackClick}
          >
            <img src="/icons/back-btn.png" className="w-3" alt="" />
            Back
          </button>
          <div className="w-full pl-8 pt-4 mb-3">
            <div className="flex items-center">
              <h2 className="text-[1.35rem] grow font-semibold m-0">
                {requirementPhase?.name}
              </h2>
              <span className="text-sm text-gray-500 max-w-xl">
                {requirementPhase?.description}
              </span>
            </div>
          </div>
        </div>
        <div className="">
          <table className="min-w-full text-[15px]">
            <thead>
              <tr>
                <th className="w-1/4 py-3 px-8 border-b border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                  Task/Subtask
                </th>
                <th className="w-1/2 py-3 px-5 border-b border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                  Description
                </th>
                <th className="w-1/5 py-3 px-2 border-b border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                  Resources (h)
                </th>
              </tr>
            </thead>
            <tbody>
              {!loaded() ? (
                <tr>
                  <td colSpan={4}>
                    <TableLoader />
                  </td>
                </tr>
              ) : !requirementPhase!.isTasksGenerated ? (
                <tr>
                  <td colSpan={4}>
                    <div className="py-3 px-8 font-bold text-xl">
                      Generating...
                    </div>
                  </td>
                </tr>
              ) : (
                requirementPhase!.tasks.map((task, taskIndex) => (
                  <tr key={task.id}>
                    <td className="m-0 p-0" colSpan={4}>
                      <table className="w-full">
                        <tbody>
                          <tr
                            className={`group/task ${
                              project?.requirement?.isWorkBreakdownApproved
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                            onClick={() => editTask(task)}
                            onMouseEnter={() => setHoveredRow(taskIndex)}
                            onMouseLeave={() => setHoveredRow(null)}
                          >
                            <td className="py-3 pl-8">
                              <div className="uppercase font-semibold">
                                {task.name}
                              </div>
                            </td>
                          </tr>
                          {!task.isResourcesGenerated ? (
                            <tr key={`subtask-${task.id}`}>
                              <td
                                colSpan={3}
                                className="py-3 px-8 font-bold text-normal"
                              >
                                Generating...
                              </td>
                            </tr>
                          ) : (
                            task.subTasks.map((subtask) => (
                              <tr
                                onClick={() => editTask(subtask)}
                                key={subtask.id}
                                onMouseEnter={() => setHoveredRow(taskIndex)}
                                onMouseLeave={() => setHoveredRow(null)}
                                className={`group/subtask even:bg-gray-50 hover:bg-brandSubtle hover:text-brand ${
                                  project?.requirement?.isWorkBreakdownApproved
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                }`}
                              >
                                <td className="w-1/4 py-4 pl-8 h-full">
                                  {subtask.name}
                                </td>
                                <td className="w-1/2 py-4 pl-6 h-full">
                                  {subtask.description}
                                </td>
                                <td className="w-1/5 py-4 pl-2 pr-4 h-full">
                                  <div className="min-w-full">
                                    <table className="w-full">
                                      <tbody>
                                        {subtask.resources.map(
                                          (resource, index) => (
                                            <div
                                              key={index}
                                              className="flex gap-6 w-full pr-2"
                                            >
                                              <span className="justify-self-start w-3/4">
                                                {resource.resourceType.name}
                                              </span>
                                              <span className="justify-self-end w-1/4 text-right">
                                                {resource.effort}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="w-full grid grid-cols-2 justify-items-end content-between pl-14 pr-6 py-8 bg-brandYellow rounded-0 rounded-br-xl rounded-bl-xl border-t">
          <span className="font-bold text-[#475467] justify-self-start">
            RESOURCES SUBTOTAL
          </span>
          <div className="w-56 text-[0.9rem] flex flex-col gap-2">
            {resourcesTotal.map((resource, index) => (
              <div
                key={index}
                className="flex gap-6 w-full font-semibold text-[#475467]"
              >
                <span className="justify-self-start w-3/4">
                  {resource.resourceType}
                </span>
                <span className="justify-self-end w-1/4 text-right">
                  {resource.effort}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <TaskEditDrawer
        isOpen={isEditing}
        maskOpen={showMask}
        toggleDrawer={() => setDrawerSequence(false)}
        task={editingTask}
        onSave={handleSaveEdit}
        onDelete={handleConfirmDelete}
        resourceTypes={resourceTypes}
      />
    </div>
  );
};

export default TasksAndSubtasks;
