import { useAuth0 } from "@auth0/auth0-react";

export const MyProfile = () => {
  const { user, logout } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className="min-h-screen flex flex-col justify-start items-center">
      <div className="bg-white mt-24 p-8 rounded-lg shadow-md w-96">
        <h1 className="text-2xl font-semibold mb-4">My Profile</h1>
        <div className="mb-4 flex space-x-2">
          <label className="block text-gray-600 mb-2">First Name:</label>
          <span className="text-gray-800">{user?.given_name}</span>
        </div>
        <div className="mb-4 flex space-x-2">
          <label className="block text-gray-600 mb-2">Last Name:</label>
          <span className="text-gray-800">{user?.family_name}</span>
        </div>
        <div className="mb-4 flex space-x-2">
          <label className="block text-gray-600 mb-2">Email:</label>
          <span className="text-gray-800">{user?.email}</span>
        </div>
        <button onClick={handleLogout} className="button-primary w-full">
          Logout
        </button>
      </div>
    </div>
  );
};
