import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CapabilityCheck } from "../questionnaire/types";
import { getAccessToken } from "../../utils";

const api = createApi({
  reducerPath: "capabilityCheckApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/capability-checker/",
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  tagTypes: ["CapabilitiesCheck"],
  endpoints: (builder) => ({
    getCapabilityChecks: builder.query<CapabilityCheck[], string>({
      query: (id: string) => `${id}/checks`,
      providesTags: [{ type: "CapabilitiesCheck" }],
    }),
    reCheckCapabilities: builder.mutation<CapabilityCheck[], string>({
      query: (id: string) => {
        return {
          url: `${id}/checks`,
          method: "PUT",
          body: {
            id,
          },
        };
      },
      invalidatesTags: [{ type: "CapabilitiesCheck" }],
    }),
    reCheckCapability: builder.mutation<CapabilityCheck, string>({
      query: (id: string) => {
        return {
          url: `re-check/${id}`,
          method: "PATCH",
          body: {
            id,
          },
        };
      },
      invalidatesTags: [{ type: "CapabilitiesCheck" }],
    }),
    updateCapability: builder.mutation<
      CapabilityCheck,
      Partial<CapabilityCheck>
    >({
      query: (capabilityCheck: Partial<CapabilityCheck>) => {
        return {
          url: `${capabilityCheck.id!}`,
          method: "PATCH",
          body: {
            reasoning: capabilityCheck.reasoning,
            approved: capabilityCheck.approved,
            supported: capabilityCheck.supported,
          },
        };
      },
      invalidatesTags: [{ type: "CapabilitiesCheck" }],
    }),
  }),
});

export const {
  useGetCapabilityChecksQuery,
  useReCheckCapabilitiesMutation,
  useUpdateCapabilityMutation,
  useReCheckCapabilityMutation,
  util: capabilityCheckApiUtil,
} = api;

export const capabilityCheckApiMiddleware = api.middleware;

export default api.reducer;

export const downloadDocumentApi = async (id: string, fileName: string) => {
  const response = await fetch(`/api/capability-checker/download/${id}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = transformFilename(fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const downloadTemplateApi = async () => {
  const response = await fetch(`/api/capability-checker/download-template`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "template.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

function transformFilename(filename: string): string {
  const parts = filename.split(".");

  if (parts.length < 2) {
    return filename + "-response";
  }

  parts[0] += "-response";

  return parts.join(".");
}
