import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ResourceRequirement } from "../../store/project/types";
import { useUpdateRequestResourceRequirementsByResourceTypeMutation } from "../../store/project/projectApiService";

const validationSchema = Yup.object({
  averageCostPerHour: Yup.number().required(
    "Average Cost per Hour is required"
  ),
  hourlyRate: Yup.number().required("Hourly Rate is required"),
});

type ResourceRequirementDrawerProps = {
  isOpen: boolean;
  toggleDrawer: () => void;
  resourceRequirement?: ResourceRequirement | null;
};

const ResourceRequirementDrawer: React.FC<ResourceRequirementDrawerProps> = ({
  isOpen,
  toggleDrawer,
  resourceRequirement,
}) => {
  const [updateResourceRequirement] =
    useUpdateRequestResourceRequirementsByResourceTypeMutation();

  const formik = useFormik({
    initialValues: {
      averageCostPerHour: resourceRequirement
        ? resourceRequirement.averageCostPerHour
        : 0,
      hourlyRate: resourceRequirement ? resourceRequirement.hourlyRate : 0,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const updatedResourceRequirement = {
          ...resourceRequirement,
          ...values,
        };
        await updateResourceRequirement({
          resourceTypeId: resourceRequirement?.resourceType.id!,
          data: updatedResourceRequirement,
        }).unwrap();
        toggleDrawer();
        toast.success("Resource Requirement updated successfully!");
      } catch (err: any) {
        toast.error(
          err?.data?.message || "Error updating resource requirement!"
        );
      }
    },
  });

  useEffect(() => {
    if (resourceRequirement) {
      formik.setValues({
        averageCostPerHour: resourceRequirement.averageCostPerHour,
        hourlyRate: resourceRequirement.hourlyRate,
      });
    }
    // formik as dependency creates infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceRequirement]);

  return (
    <div
      className={`fixed top-0 right-0 w-80 h-full bg-white shadow-md z-50 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="m-4 mt-12">
        <h2 className="text-lg font-semibold m-2">
          {resourceRequirement
            ? "Edit Resource Requirement"
            : "Create New Resource Requirement"}
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="m-2">
            <label className="block">
              Average Cost per Hour*
              <input
                type="number"
                name="averageCostPerHour"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.averageCostPerHour}
                placeholder="Enter average cost per hour"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.averageCostPerHour &&
            formik.errors.averageCostPerHour ? (
              <div className="text-red-500">
                {formik.errors.averageCostPerHour}
              </div>
            ) : null}
          </div>
          <div className="m-2">
            <label className="block">
              Hourly Rate*
              <input
                type="number"
                name="hourlyRate"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.hourlyRate}
                placeholder="Enter hourly rate"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.hourlyRate && formik.errors.hourlyRate ? (
              <div className="text-red-500">{formik.errors.hourlyRate}</div>
            ) : null}
          </div>
          <div className="m-4 mt-8 flex items-center justify-center grow">
            <button type="submit" className="button-primary">
              Save
            </button>
            <button
              type="button"
              onClick={toggleDrawer}
              className="button-secondary w-[1/3] ml-4"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResourceRequirementDrawer;
