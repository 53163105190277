import React from "react";
import { toCurrencyFormat } from "../utils/helperFunctions";
import {
  ResourceRequirement,
  ScopeResourceRequirement,
} from "../../store/project/types";

interface ResourceOverviewScopeProps {
  scopes: ScopeResourceRequirement[];
  toggleDrawer: () => void;
  setSelectedRequirement: (requirement: ResourceRequirement) => void;
}

const ResourceOverviewScope: React.FC<ResourceOverviewScopeProps> = ({
  scopes,
  toggleDrawer,
  setSelectedRequirement,
}) => {
  // Move the 'No Scope' item to the end and rename it
  const sortedScopes = [...scopes]
    .sort((a, b) =>
      a.name === "No Scope" ? 1 : b.name === "No Scope" ? -1 : 0
    )
    .map((scope) => ({
      ...scope,
      name: scope.name === "No Scope" ? "Other activities (Planning, Operational and Organisational)" : scope.name,
      description: scope.name === "No Scope" ? "" : scope.description,
    }));

  const grandTotalEffort = sortedScopes.reduce(
    (total, scope) => total + scope.resources.reduce(
      (acc: number, { effort }: { effort: number }) => acc + effort, 0),
    0
  );

  return (
    <div>
      {sortedScopes.map((scope) => {
        const totalEffort = scope.resources.reduce(
          (acc: number, { effort }: { effort: number }) => acc + effort,
          0
        );
        return (
          <div
            key={scope.id}
            className="container shadow border border-gray-200 rounded rounded-xl mb-8"
          >
            <div className="w-full flex items-center pr-8 bg-brandSubtle rounded-0 rounded-tl-xl rounded-tr-xl">
              <h2 className="w-4/5 grow text-[1.3rem] font-semibold py-8 px-8 mb-0">
                {scope.name}
                <div className="font-normal normal-case text-[0.95rem]">
                  {scope.description}
                </div>
              </h2>
              <div className="font-bold w-1/5 text-right">Subtotal: {totalEffort}h</div>
            </div>

            <div>
              <table className="min-w-full text-[15px]">
                <thead>
                  <tr>
                    <th className="w-1/4 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                      Role
                    </th>
                    <th className="w-1/4 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                      Cost per Hour
                    </th>
                    <th className="w-1/4 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                      Hourly Rate
                    </th>
                    <th className="w-1/4 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                      Effort
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {scope.resources.map((resource) => (
                    <tr
                      key={resource.id}
                      className="even:bg-gray-50 text-gray-700 hover:bg-brandSubtle hover:text-brand cursor-pointer group"
                      onClick={() => {
                        setSelectedRequirement(resource);
                        toggleDrawer();
                      }}
                    >
                      <td className="py-4 pl-8 align-top font-normal h-full group-last:rounded-0 group-last:rounded-bl-xl">
                        {resource.resourceType.name}
                      </td>
                      <td className="py-4 pl-8 align-top font-semibold h-full">
                        {toCurrencyFormat(resource.averageCostPerHour)}
                      </td>
                      <td className="py-4 pl-8 align-top font-semibold h-full">
                        {toCurrencyFormat(resource.hourlyRate)}
                      </td>
                      <td className="py-4 pl-8 align-top font-semibold h-full group-last:rounded-0 group-last:rounded-br-xl">
                        {resource.effort} Hours
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
      <div className="container shadow border border-gray-200 rounded rounded-xl mb-8">
        <div className="flex items-center justify-between pr-8 bg-brandYellow rounded-xl">
          <h2 className="text-[1.3rem] font-semibold py-8 px-8 mb-0">
            Grand Total
          </h2>
          <div className="font-bold">{grandTotalEffort} Hours</div>
        </div>
      </div>
    </div>
  );
};

export default ResourceOverviewScope;
