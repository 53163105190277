import React, { useState, useCallback, useEffect, FC, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DndProvider, useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { RootState } from "../../store";
import { ProposalDocumentSection } from "../../store/proposal/types";
import {
  ProposalDocumentStatus,
  setProposalSettings,
  CurrentProposalSettings,
} from "../../store/project/projectSlice";
import {
  downloadProposal,
  projectApiUtils,
  useGetProposalSettingsQuery,
  useSetProposalDocumentActiveMutation,
  useUpdateProposalSettingsMutation,
} from "../../store/project/projectApiService";
import ProposalHeader from "./ProposalHeader";
import { AiOutlineFileWord } from "react-icons/ai";
import { trackUser } from "../../utils";
import { Tooltip } from "react-tooltip";

const ItemTypes = {
  COMPONENT: "component",
  DROPPED_COMPONENT: "droppedComponent",
};

interface DraggableComponentProps {
  component: ProposalDocumentSection;
}

const DraggableComponent: FC<DraggableComponentProps> = ({ component }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COMPONENT,
    item: component,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={`cursor-move flex items-center gap-2 py-4 px-2 border-b border-gray-100  ${
        isDragging ? "opacity-50" : "bg-white"
      }`}
    >
      <img src="/icons/add-section.png" className="w-6" alt="" />
      <span className="grow">{component.name}</span>
      <img src="/icons/drag-item.png" className="w-5" alt="" />
    </div>
  );
};

interface DroppedComponentProps {
  component: ProposalDocumentSection;
  index: number;
  moveComponent: (dragIndex: number, hoverIndex: number) => void;
  removeComponent: (index: number) => void;
  isDocumentGenerated: boolean;
}

interface DragItem {
  index: number;
  id: string;
  name: string;
  key: string;
  selected: boolean;
  type: string;
}

const DroppedComponent: FC<DroppedComponentProps> = ({
  component,
  index,
  moveComponent,
  removeComponent,
  isDocumentGenerated,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem>({
    accept: ItemTypes.DROPPED_COMPONENT,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveComponent(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.DROPPED_COMPONENT,
    item: { id: component.id, name: component.name, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={`p-4 border rounded-lg bg-white ${
        isDragging ? "opacity-50" : "opacity-100"
      } ${isDocumentGenerated ? "cursor-not-allowed" : "cursor-move"}`}
    >
      <div className="flex justify-between items-center">
        <img
          src="/icons/drag-item.png"
          className="w-6 hover:cursor-pointer"
          alt="drag me"
        />
        <span className="text-[#344054] font-semibold">{component.name}</span>
        <div
          className={`text-sm text-center ${
            isDocumentGenerated ? "disabled" : ""
          }`}
        >
          <div
            className={`p-1 my-0 inline-flex items-center rounded-md group relative ${
              isDocumentGenerated ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <img
              src="/icons/close.png"
              data-tooltip-id="removeSection"
              data-tooltip-content={`Remove ${component.name}`}
              className="w-5"
              alt=""
              onClick={() => {
                if (!isDocumentGenerated) removeComponent(index);
              }}
            />
            <Tooltip id="removeSection" />
          </div>
        </div>
      </div>
    </div>
  );
};

interface DropZoneProps {
  components: ProposalDocumentSection[];
  setComponents: React.Dispatch<
    React.SetStateAction<ProposalDocumentSection[]>
  >;
  handleDrop: (component: ProposalDocumentSection) => void;
  handleRemove: (index: number) => void;
  isDocumentGenerated: boolean;
}

const DropZone: FC<DropZoneProps> = ({
  components,
  setComponents,
  handleDrop,
  handleRemove,
  isDocumentGenerated,
}) => {
  const moveComponent = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const draggedComponent = components[dragIndex];
      setComponents(
        update(components, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, draggedComponent],
          ],
        })
      );
    },
    [components, setComponents]
  );

  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.COMPONENT, ItemTypes.DROPPED_COMPONENT],
    drop: (item: DragItem, monitor) => {
      if (monitor.getItemType() === ItemTypes.COMPONENT) {
        handleDrop(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`p-4 flex flex-col gap-4 border border-dashed rounded-lg ${
        isOver ? "border-brand" : "border-gray-300"
      }`}
    >
      {components.length === 0 && (
        <div className="w-full flex flex-col items-center">
          <img
            src="/icons/add-section.png"
            className="w-8 opacity-50"
            alt="Drop section here"
          />
          <span className="text-sm text-gray-600 text-center">
            Drag and drop sections from the right hand side here...
          </span>
        </div>
      )}

      {components.map((component, index) => (
        <DroppedComponent
          key={component.id}
          index={index}
          component={component}
          moveComponent={moveComponent}
          removeComponent={handleRemove}
          isDocumentGenerated={isDocumentGenerated}
        />
      ))}
    </div>
  );
};

const ProposalPage: FC = () => {
  const dispatch = useDispatch();
  const intervalRef = useRef<null | number | NodeJS.Timeout>(null);
  const { requirementId } = useParams();

  const proposalSettings = useSelector<
    RootState,
    CurrentProposalSettings | null
  >((state) => state.projectData.proposalSettings);

  const { data: requestProposalSettings } = useGetProposalSettingsQuery(
    requirementId!,
    {
      skip: requirementId == null,
    }
  );

  const isDocumentGenerated =
    proposalSettings?.activeDocument &&
    proposalSettings.activeDocument.status === ProposalDocumentStatus.READY;

  const [updateProposalSettings] = useUpdateProposalSettingsMutation();
  const [setProposalDocumentActive] = useSetProposalDocumentActiveMutation();

  useEffect(() => {
    if (requestProposalSettings) {
      dispatch(
        setProposalSettings({
          requirementId: requirementId!,
          allDocuments: requestProposalSettings.data.allDocuments,
          activeDocument: requestProposalSettings.data.activeDocument,
        })
      );
      if (
        !intervalRef.current &&
        requestProposalSettings.data.activeDocument?.status ===
          ProposalDocumentStatus.GENERATING
      ) {
        // Start the interval to invalidate the state every 5 seconds
        intervalRef.current = setInterval(() => {
          dispatch(
            projectApiUtils.invalidateTags([
              { type: "ProposalSettings", id: requirementId },
            ])
          );
        }, 5000);

        if (
          requestProposalSettings.data.activeDocument?.status !==
            ProposalDocumentStatus.GENERATING &&
          intervalRef.current
        ) {
          clearInterval(intervalRef.current as NodeJS.Timeout);
          intervalRef.current = null;
        }

        return () => {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
          }
        };
      }
    }
  }, [requirementId, requestProposalSettings, dispatch]);

  const [components, setComponents] = useState<ProposalDocumentSection[]>([]);
  const [availableComponents, setAvailableComponents] = useState<
    ProposalDocumentSection[]
  >(proposalSettings?.activeDocument!.sections || []);

  useEffect(() => {
    if (proposalSettings) {
      const selectedComponents =
        proposalSettings.activeDocument!.sections.filter(
          (section) => section.selected
        );
      const availableComponents =
        proposalSettings.activeDocument!.sections.filter(
          (section) => !section.selected
        );
      setComponents(selectedComponents);
      setAvailableComponents(availableComponents);
    }
  }, [proposalSettings]);

  const handleDrop = (component: ProposalDocumentSection) => {
    component = { ...component, selected: true };
    setComponents((prev) => [...prev, component]);
    setAvailableComponents((prev) =>
      prev.filter((comp) => comp.id !== component.id)
    );
    updateProposalSettings({
      id: proposalSettings!.activeDocument!.id,
      requirementId: requirementId!,
      sections: [...proposalSettings!.activeDocument!.sections, component],
    })
      .unwrap()
      .then((data) => {
        dispatch(
          setProposalSettings({
            requirementId: requirementId!,
            allDocuments: data.data.allDocuments,
            activeDocument: data.data.activeDocument,
          })
        );
      });
  };

  const handleRemove = (index: number) => {
    const removedComponent = components[index];
    setComponents((prev) => prev.filter((_, i) => i !== index));
    setAvailableComponents((prev) => [...prev, removedComponent]);
    const removedComponentToUpdate = { ...removedComponent, selected: false };
    const componentsToUpdate = proposalSettings!.activeDocument!.sections.map(
      (section) =>
        section.id === removedComponentToUpdate.id
          ? removedComponentToUpdate
          : section
    );
    updateProposalSettings({
      id: proposalSettings!.activeDocument!.id,
      requirementId: requirementId!,
      sections: componentsToUpdate,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          setProposalSettings({
            requirementId: requirementId!,
            allDocuments: data.data.allDocuments,
            activeDocument: data.data.activeDocument,
          })
        );
      });
  };

  const activateDocumentClick = (id: string) => () => {
    setProposalDocumentActive({
      requirementId: requirementId!,
      id: id,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          setProposalSettings({
            requirementId: requirementId!,
            allDocuments: data.data.allDocuments,
            activeDocument: data.data.activeDocument,
          })
        );
      });
  };
  const addAllSections = () => {
    const updatedComponents = availableComponents.map((component) => ({
      ...component,
      selected: true,
    }));
    setComponents((prev) => [...prev, ...updatedComponents]);
    setAvailableComponents([]);
    const updatedSections = proposalSettings!.activeDocument!.sections.map(
      (section) =>
        updatedComponents.find((comp) => comp.id === section.id) || section
    );
    updateProposalSettings({
      id: proposalSettings!.activeDocument!.id,
      requirementId: requirementId!,
      sections: updatedSections,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          setProposalSettings({
            requirementId: requirementId!,
            allDocuments: data.data.allDocuments,
            activeDocument: data.data.activeDocument,
          })
        );
      });
  };

  const removeAllSections = () => {
    const updatedComponents = components.map((component) => ({
      ...component,
      selected: false,
    }));
    setComponents([]);
    setAvailableComponents((prev) => [...prev, ...updatedComponents]);
    const updatedSections = proposalSettings!.activeDocument!.sections.map(
      (section) =>
        updatedComponents.find((comp) => comp.id === section.id) || section
    );
    updateProposalSettings({
      id: proposalSettings!.activeDocument!.id,
      requirementId: requirementId!,
      sections: updatedSections,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          setProposalSettings({
            requirementId: requirementId!,
            allDocuments: data.data.allDocuments,
            activeDocument: data.data.activeDocument,
          })
        );
      });
  };

  function downloadDocument(format: string): void {
    downloadProposal(
      proposalSettings?.activeDocument?.id!,
      proposalSettings?.activeDocument?.name!,
      format
    );
    trackUser(`Download Proposal Document ${format}`);
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="p-4 pb-8 mt-2">
        <ProposalHeader
          proposalSettings={proposalSettings?.activeDocument || null}
          selectedComponents={components}
        />
        <div className="container mt-4">
          {proposalSettings?.activeDocument?.status ===
          ProposalDocumentStatus.GENERATING ? (
            <div className="w-full flex flex-col items-center p-12 bg-gray-25 rounded-xl border border-gray-100 shadow">
              <img
                src="/icons/estimation.png"
                className="w-8 opacity-50"
                alt="Generating document in progress"
              />
              <span className="text-sm text-gray-600 text-center">
                Generating the proposal in progress. Please wait...
              </span>
            </div>
          ) : (
            <div className="flex gap-8">
              <div className="flex-grow p-6 bg-gray-25 shadow border border-gray-200 rounded rounded-xl">
                <div className="flex items-center mb-8">
                  {proposalSettings?.activeDocument &&
                  proposalSettings?.activeDocument.name ? (
                    <div className="flex items-center gap-4 grow">
                      {/* <div className="px-1 py-2 rounded-lg bg-gray-100 shadow-inner">
                        <img
                          src="/icons/ico-docx.png"
                          className="w-6 h-6"
                          alt=""
                        />
                      </div> */}
                      <h2 className="p-0 m-0 text-[1.4rem]">
                        {proposalSettings?.activeDocument?.name}
                      </h2>
                    </div>
                  ) : (
                    <h2 className="grow p-0 m-0 text-[1.4rem]">
                      Proposal Document Structure - Working version
                    </h2>
                  )}

                  {!isDocumentGenerated && components.length !== 0 ? (
                    <span
                      onClick={removeAllSections}
                      className="text-brand text-[0.9rem] hover:cursor-pointer"
                    >
                      Remove all
                    </span>
                  ) : (
                    isDocumentGenerated && (
                      <div className="flex items-center gap-4 p-4 rounded-xl shadow">
                        <div className="flex items-center gap-3">
                          {/* <AiOutlineDownload className="w-5 h-5" /> */}
                          Download
                        </div>
                        <button
                          onClick={() => downloadDocument("pdf")}
                          className="button-primary-icon !px-3 py-2 shadow-sm border border-gray-300 px-3 rounded-lg text-lg hover:bg-brandHover focus:outline-none focus:ring focus:ring-green-200 flex items-center gap-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed"
                        >
                          <img
                            src="/icons/ico-pdf.png"
                            className="w-6"
                            alt=""
                          />
                        </button>
                        <button
                          onClick={() => downloadDocument("docx")}
                          className="button-primary-icon !px-3 py-2 shadow-sm border border-gray-300 px-3 rounded-lg text-lg hover:bg-brandHover focus:outline-none focus:ring focus:ring-green-200 flex items-center gap-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed"
                        >
                          <img
                            src="/icons/ico-docx.png"
                            className="w-6"
                            alt=""
                          />
                        </button>
                      </div>
                    )
                  )}
                </div>
                <DropZone
                  components={components}
                  setComponents={setComponents}
                  handleDrop={handleDrop}
                  handleRemove={handleRemove}
                  isDocumentGenerated={isDocumentGenerated || false}
                />
              </div>
              <div className="w-1/3">
                {proposalSettings?.allDocuments &&
                  proposalSettings?.allDocuments.length > 0 && (
                    <div className="p-6 shadow border border-gray-200 rounded rounded-xl mb-8">
                      <div className="flex items-center mb-6">
                        <h2 className="grow p-0 m-0 text-[1.2rem]">
                          Documents
                        </h2>
                      </div>
                      {proposalSettings?.allDocuments?.map((doc) => (
                        <button
                          key={doc.id}
                          className={`flex p-2 ${
                            doc.id === proposalSettings?.activeDocument?.id
                              ? "text-brand font-bold bg-gray-100"
                              : ""
                          } hover:bg-gray-100 w-full rounded-lg mb-2 items-center`}
                          onClick={activateDocumentClick(doc.id)}
                        >
                          <AiOutlineFileWord className="w-5 h-5" />
                          <div className="ml-2 text-[0.93rem] truncate">
                            {doc.name ? `${doc.name}.docx` : "Working version"}
                          </div>
                        </button>
                      ))}{" "}
                    </div>
                  )}
                {!isDocumentGenerated && (
                  <div className="p-6 shadow border border-gray-200 rounded rounded-xl">
                    <div className="flex items-center">
                      <h2 className="grow p-0 m-0 text-[1.2rem]">
                        Add Sections
                      </h2>
                      {availableComponents.length !== 0 && (
                        <span
                          onClick={addAllSections}
                          className="text-brand text-[0.9rem] hover:cursor-pointer"
                        >
                          Add all
                        </span>
                      )}
                    </div>
                    {availableComponents.length === 0 && (
                      <div className="text-gray-500 h-full flex justify-center">
                        All sections added
                      </div>
                    )}
                    {availableComponents.map((component) => (
                      <DraggableComponent
                        key={component.id}
                        component={component}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </DndProvider>
  );
};

export default ProposalPage;
