import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Quote, QuoteTotals, QuoteItem } from "../../store/quote/types";
import {
  formatNumber,
  parseNumber,
  toCurrencyFormat,
} from "../utils/helperFunctions";
import { setCurrentQuote } from "../../store/project/projectSlice";
import { useParams } from "react-router-dom";
import { recalculateTotals, calculateDerivedFields } from "./quoteCalculations";
import { Project } from "../../store/project/types";
import { RootState } from "../../store";
import { useUpdateQuoteMutation } from "../../store/project/projectApiService";

interface QuoteTotalsTableProps {
  totals: QuoteTotals[];
  quote: Quote;
}

const QuoteTotalsTable: React.FC<QuoteTotalsTableProps> = ({
  totals,
  quote,
}) => {
  const dispatch = useDispatch();
  const { requirementId } = useParams();
  const [shadowValues, setShadowValues] = useState<Record<string, string>>({});
  const [isModified, setIsModified] = useState(false);

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });
  const [updateQuote] = useUpdateQuoteMutation();

  useEffect(() => {
    const initialShadowValues: Record<string, string> = {};

    quote.quoteTotals.forEach((quoteTotal) => {
      initialShadowValues[`${quoteTotal.id}-discountPercentage`] = formatNumber(
        quoteTotal.discountPercentage
      );
      initialShadowValues[`${quoteTotal.id}-discountAmount`] = formatNumber(
        quoteTotal.discountAmount
      );
      initialShadowValues[`${quoteTotal.id}-netMarginPercentage`] =
        formatNumber(quoteTotal.netMarginPercentage);
      initialShadowValues[`${quoteTotal.id}-netMarginAmount`] = formatNumber(
        quoteTotal.netMarginAmount
      );
      initialShadowValues[`${quoteTotal.id}-netPrice`] = formatNumber(
        quoteTotal.netPrice
      );
      initialShadowValues[`${quoteTotal.id}-vatPercentage`] = formatNumber(
        quoteTotal.vatPercentage
      );
      initialShadowValues[`${quoteTotal.id}-totalAmount`] = formatNumber(
        quoteTotal.totalAmount
      );
    });
    setShadowValues(initialShadowValues);
  }, [quote]);

  const applyDiscountPercentage = (
    quote: Quote,
    discountPercentage: number
  ) => {
    const updatedQuote = JSON.parse(JSON.stringify(quote));

    updatedQuote.quoteItemGroups.forEach((itemGroup: any) => {
      itemGroup.quoteItems.forEach((item: QuoteItem) => {
        const updatedItem = calculateDerivedFields(
          item,
          "discountPercentage",
          discountPercentage
        );
        Object.assign(item, updatedItem);
      });
    });

    recalculateTotals(updatedQuote);

    return updatedQuote;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    quoteTotal: QuoteTotals,
    field: keyof QuoteTotals
  ) => {
    if (project?.requirement?.isQuoteApproved) {
      return;
    }

    const { value } = e.target;

    let numberValue = parseNumber(value);

    if (numberValue !== quoteTotal[field]) {
      setIsModified(true);
    }
    setShadowValues((prev) => ({
      ...prev,
      [`${quoteTotal.id}-${field}`]: value,
    }));
  };

  const handleInputBlur = useCallback(
    (
      e: React.FocusEvent<HTMLInputElement>,
      total: QuoteTotals,
      field: keyof QuoteTotals
    ) => {
      if (!isModified || project?.requirement?.isQuoteApproved) {
        return;
      }
      setIsModified(false);

      const { value } = e.target;
      let numberValue = parseNumber(value);
      const totalCost = parseNumber(total.totalCost);
      const totalListPrice = parseNumber(total.listPrice);

      let updatedQuote = JSON.parse(JSON.stringify(quote));

      if (field === "discountPercentage") {
        updatedQuote = applyDiscountPercentage(updatedQuote, numberValue);
      } else {
        let discountPercentage = parseNumber(total.discountPercentage);
        if (field === "discountAmount") {
          discountPercentage = (numberValue / totalListPrice) * 100;
        } else if (field === "netMarginPercentage") {
          const netMarginAmount =
            (numberValue * totalCost) / (100 - numberValue);
          const netPrice = totalCost + netMarginAmount;
          const discountAmount = totalListPrice - netPrice;
          discountPercentage = (discountAmount / totalListPrice) * 100;
        } else if (field === "netMarginAmount") {
          const discountAmount = totalListPrice - numberValue - totalCost;
          discountPercentage = (discountAmount / totalListPrice) * 100;
        } else if (field === "netPrice") {
          const discountAmount = totalListPrice - numberValue;
          discountPercentage = (discountAmount / totalListPrice) * 100;
        } else if (field === "totalAmount") {
          const netPrice = numberValue / (1 + total.vatPercentage / 100);
          const discountAmount = totalListPrice - netPrice;
          discountPercentage = (discountAmount / totalListPrice) * 100;
        }

        updatedQuote = applyDiscountPercentage(
          updatedQuote,
          discountPercentage
        );
      }
      if (field === "vatPercentage") {
        updatedQuote.quoteTotals.forEach((qt: QuoteTotals) => {
          if (qt.id === total.id) {
            qt.vatPercentage = numberValue;
            qt.vatAmount = (qt.netPrice * qt.vatPercentage) / 100;
            qt.totalAmount = qt.netPrice + qt.vatAmount;
          }
        });
      }

      dispatch(
        setCurrentQuote({
          requirementId: requirementId!,
          quote: updatedQuote,
        })
      );
      updateQuote(updatedQuote);
    },
    [quote, dispatch, requirementId, isModified, updateQuote]
  );

  const renderTableRows = (total: QuoteTotals) => {
    const rows = [
      {
        label: "Total Employee Hours",
        value: formatNumber(total.totalHours),
        field: "totalHours",
        isEditable: false,
      },
      {
        label: "Total Cost",
        value: toCurrencyFormat(total.totalCost),
        field: "totalCost",
        isEditable: false,
      },
      {
        label: "List Price",
        value: toCurrencyFormat(total.listPrice),
        field: "listPrice",
        isEditable: false,
      },
      {
        label: "Discount (%)",
        value: shadowValues[`${total.id}-discountPercentage`] ?? "",
        field: "discountPercentage",
        isEditable: true,
      },
      {
        label: "Discount ($)",
        value: shadowValues[`${total.id}-discountAmount`] ?? "",
        field: "discountAmount",
        isEditable: true,
      },
      {
        label: "Net Margin (%)",
        value: shadowValues[`${total.id}-netMarginPercentage`] ?? "",
        field: "netMarginPercentage",
        isEditable: true,
      },
      {
        label: "Net Margin ($)",
        value: shadowValues[`${total.id}-netMarginAmount`] ?? "",
        field: "netMarginAmount",
        isEditable: true,
      },
      {
        label: "Net Price ($)",
        value: shadowValues[`${total.id}-netPrice`] ?? "",
        field: "netPrice",
        isEditable: true,
      },
      {
        label: "VAT (%)",
        value: shadowValues[`${total.id}-vatPercentage`] ?? "",
        field: "vatPercentage",
        isEditable: true,
      },
      {
        label: "VAT Amount ($)",
        value: formatNumber(total.vatAmount),
        field: "vatAmount",
        isEditable: false,
      },
      {
        label: "Total Amount ($)",
        value: shadowValues[`${total.id}-totalAmount`] ?? "",
        field: "totalAmount",
        isEditable: true,
      },
    ];

    return rows.map((row, index) => (
      <tr
        key={index}
        className="group odd:bg-gray-100 border-b border-gray-200"
      >
        <td className="px-6 py-3 text-right group-last:bg-brandYellow group-last:text-xl group-last:font-semibold">{row.label}</td>
        <td
          className={`px-6 py-3 ${
            row.field === "totalAmount" ? "text-l font-bold" : ""
          }  group-last:bg-brandYellow text-right group-last:text-xl group-last:font-semibold`} 
        >
          {row.isEditable ? (
            <input
              type="text"
              value={row.value}
              className="w-full p-1 border-b focus:outline-brand text-right"
              onChange={(e) =>
                handleInputChange(e, total, row.field as keyof QuoteTotals)
              }
              onBlur={(e) =>
                handleInputBlur(e, total, row.field as keyof QuoteTotals)
              }
            />
          ) : (
            <div>{row.value}</div>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <>
      {totals.map((total) => (
        <div
          key={total.id}
          className="text-lg mb-6 flex align-right justify-end"
        >
          <table className="w-2/5 bg-white shadow rounded-lg">
            <tbody>{renderTableRows(total)}</tbody>
          </table>
        </div>
      ))}
    </>
  );
};

export default QuoteTotalsTable;
