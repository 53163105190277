import React from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

interface ConfirmCancelButtonsProps {
  onConfirm: () => void;
  onCancel: () => void;
  confirmTooltipId: string;
  cancelTooltipId: string;
}

const ConfirmCancelButtons: React.FC<ConfirmCancelButtonsProps> = ({
  onConfirm,
  onCancel,
  confirmTooltipId,
  cancelTooltipId,
}) => {
  return (
    <div className="flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
      <AiOutlineCheck
        data-tooltip-id={confirmTooltipId}
        data-tooltip-content="Save"
        onClick={onConfirm}
        size={20}
        className="hover:text-green-500 cursor-pointer"
      />
      <Tooltip id={confirmTooltipId} />
      <AiOutlineClose
        data-tooltip-id={cancelTooltipId}
        data-tooltip-content="Cancel"
        onClick={onCancel}
        size={20}
        className="hover:text-red-500 cursor-pointer"
      />
      <Tooltip id={cancelTooltipId} />
    </div>
  );
};

export default ConfirmCancelButtons;
