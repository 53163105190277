import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface AppConfig {
  audience: string;
  domain: string;
  client_id: string;
  environment: string;
  mixpanel_token: string;
}

const api = createApi({
  reducerPath: "configApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
  }),
  endpoints: (builder) => ({
    getConfig: builder.query<AppConfig, void>({
      query: () => "/app-config",
      transformResponse: (response: AppConfig) => {
        return {
          ...response,
        };
      },
    }),
  }),
});

export const { useGetConfigQuery } = api;

export const configApiMiddleware = api.middleware;

export default api.reducer;
