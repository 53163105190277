import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UserAccount } from "./types";
import { getAccessToken } from "../../utils";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/user-account",
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  tagTypes: ["UserAccount"],
  endpoints: (builder) => ({
    inviteUser: builder.mutation<
      void,
      { email: string; firstName: string; lastName: string }
    >({
      query: (user) => ({
        url: "invite",
        method: "POST",
        body: user,
      }),
      invalidatesTags: [{ type: "UserAccount" }],
    }),
    updateUser: builder.mutation<void, Partial<UserAccount>>({
      query: (user) => ({
        url: user.id!,
        method: "PATCH",
        body: user,
      }),
      invalidatesTags: [{ type: "UserAccount" }],
    }),
    getUsers: builder.query<{ data: UserAccount[] }, void>({
      query: () => "",
      providesTags: ["UserAccount"],
    }),
    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "UserAccount" }],
    }),
  }),
});

export const {
  useInviteUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;

export const userApiMiddleware = userApi.middleware;
export default userApi.reducer;
