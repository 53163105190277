// store.ts
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import companyApiReducer, {
  companyApiMiddleware,
} from "./company/companyApiService";
import resourceTypeReducer, {
  resourceTypeApiMiddleware,
} from "./resourceType/resourceTypeApiService";
import resourceTypeSlice from "./resourceType/resourceTypeSlice";
import questionnaireApiReducer, {
  questionnaireApiMiddleware,
} from "./questionnaire/questionnaireApiService";
import questionnaireSlice from "./questionnaire/questionnaireSlice";
import capabilityCheckApiReducer, {
  capabilityCheckApiMiddleware,
} from "./capabilityCheck/capabilityCheckApiService";
import cofigApiReducer, { configApiMiddleware } from "./configApiService";
import userIdentitySlice from "./userIdentity/userIdentitySlice";
import companyDataSlice from "./company/companyDataSlice";
import authSlice from "./userIdentity/authSlice";
import projectApiReducer, {
  projectApiMiddleware,
} from "./project/projectApiService";
import projectSlice from "./project/projectSlice";
import usersSlice from "./users/userDataSlice";

import authApiReducer, { authApiMiddleware } from "./auth/authApi";
import userApiReducer, { userApiMiddleware } from "./users/userApi";

const rootReducer = combineReducers({
  companyApi: companyApiReducer,
  companyData: companyDataSlice,
  resourceTypeApi: resourceTypeReducer,
  resourceTypeData: resourceTypeSlice,
  questionnaireApi: questionnaireApiReducer,
  questionnaireData: questionnaireSlice,
  capabilityCheckApi: capabilityCheckApiReducer,
  configApi: cofigApiReducer,
  userIdentityData: userIdentitySlice,
  authData: authSlice,
  projectApi: projectApiReducer,
  projectData: projectSlice,
  authApi: authApiReducer,
  userApi: userApiReducer,
  usersData: usersSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(companyApiMiddleware)
      .concat(resourceTypeApiMiddleware)
      .concat(questionnaireApiMiddleware)
      .concat(capabilityCheckApiMiddleware)
      .concat(configApiMiddleware)
      .concat(projectApiMiddleware)
      .concat(authApiMiddleware)
      .concat(userApiMiddleware),
});

setupListeners(store.dispatch);
