import React from "react";

interface StatusFlagProps {
  status: "in_progress" | "approved" | "not_started" | "in_approval" | "available";
}

const StatusFlag: React.FC<StatusFlagProps> = ({ status }) => {
  switch (status) {
    case "in_progress":
      return (
        <div className="bg-blue-50 flex gap-2 h-fit items-center font-semibold text-blue-700 py-0.5 pl-3 pr-4 rounded-full border border-blue-400">
          <img src="/icons/ico-recycle.png" alt="" />
          In Progress
        </div>
      );

    case "approved":
      return (
        <div className="bg-green-50 flex gap-2 h-fit items-center font-semibold text-green-700 py-0.5 pl-3 pr-4 rounded rounded-full border border-green-400">
          <img src="/icons/ico-check-green.png" alt="" />
          Approved
        </div>
      );
    case "in_approval":
      return (
        <div className="bg-[#FEF6EE] flex gap-2  h-fit items-center font-semibold text-[#B93815] py-0.5 pl-3 pr-4 rounded rounded-full border border-[#F9DBAF]">
          <img src="/icons/in-approval.png" className="w-4" alt="" />
          In Approval
        </div>
      );
    case "not_started":
      return <span style={{ color: "gray" }}>Not started</span>;
    case "available":
      return <span className={`text-brand`}>Available</span>;
    default:
      return null;
  }
};

export default StatusFlag;
