import { createSlice } from "@reduxjs/toolkit";
import {
  PhaseResourceTypeDistribution,
  Project,
  RequiredResourcesSummary,
  Requirement,
  RequirementAssumption,
  RequirementPhase,
  RequirementPrerequisite,
  RequirementQuestion,
  ScopeItem,
} from "./types";
import { Questionnaire } from "../questionnaire/types";
import { Quote } from "../quote/types";
import { ProposalDocumentSection } from "../proposal/types";

export interface CurrentScopeItems {
  requirementId: string;
  isScopeGenerated: boolean;
  scopeItems: ScopeItem[];
}

export interface CurrentQuestionsAndAssumptions {
  requirementId: string;
  assumptions: RequirementAssumption[];
  prerequisites: RequirementPrerequisite[];
  questions: RequirementQuestion[];
  isQuestionsGenerated: boolean;
  isAssumptionsGenerated: boolean;
  isPrerequisiteGenerated: boolean;
}

export interface CurrentHihgLevelTasks {
  requirementId: string;
  phases: RequirementPhase[];
}

export interface CurrentQuote {
  requirementId: string;
  quote: Quote;
}

export enum ProposalDocumentStatus {
  UNDER_CONSTRUCTION = "UNDER_CONSTRUCTION",
  GENERATING = "GENERATING",
  READY = "READY",
  ERROR = "ERROR",
}

export interface ProposalDocument {
  id: string;
  name: string;
  requirementId: string;
  isActive: boolean;
  status: ProposalDocumentStatus;
  sections: ProposalDocumentSection[];
}

export interface CurrentProposalSettings {
  requirementId: string | null;
  allDocuments: ProposalDocument[] | null;
  activeDocument: ProposalDocument | null;
}

const projectSlice = createSlice({
  name: "projectData",
  initialState: {
    projects: [] as Project[],
    currentProject: null as Project | null,
    currentRequirement: null as Requirement | null,
    currentScopeItems: null as CurrentScopeItems | null,
    currentQuestionsAndAssumptions:
      null as CurrentQuestionsAndAssumptions | null,
    currentHighLevelTasks: null as CurrentHihgLevelTasks | null,
    currentPhase: null as RequirementPhase | null,
    currentResourceSummary: null as RequiredResourcesSummary | null,
    phaseResourceTypeDistribution: null as PhaseResourceTypeDistribution | null,
    currentQuote: null as CurrentQuote | null,
    questionnaires: [] as Questionnaire[],
    proposalSettings: null as CurrentProposalSettings | null,
  },
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
    setCurrentRequirement: (state, action) => {
      state.currentRequirement = action.payload;
    },
    setQuestionnaires: (state, action) => {
      state.questionnaires = action.payload;
    },
    setCurrentScopeItems: (state, action) => {
      state.currentScopeItems = action.payload;
    },
    setCurrentQuestionsAndAssumptions: (state, action) => {
      state.currentQuestionsAndAssumptions = action.payload;
    },
    setCurrentHighLevelTasks: (state, action) => {
      state.currentHighLevelTasks = action.payload;
    },
    setCurrentPhase: (state, action) => {
      state.currentPhase = action.payload;
    },
    setCurrentResourceSummary: (state, action) => {
      state.currentResourceSummary = action.payload;
    },
    setPhaseResourceTypeDistribution: (state, action) => {
      state.phaseResourceTypeDistribution = action.payload;
    },
    setCurrentQuote: (state, action) => {
      state.currentQuote = action.payload;
    },
    setProposalSettings: (state, action) => {
      state.proposalSettings = action.payload;
    },
  },
});

export const {
  setProjects,
  setCurrentProject,
  setCurrentRequirement,
  setCurrentScopeItems,
  setCurrentQuestionsAndAssumptions,
  setCurrentHighLevelTasks,
  setCurrentPhase,
  setQuestionnaires,
  setCurrentResourceSummary,
  setPhaseResourceTypeDistribution,
  setCurrentQuote,
  setProposalSettings,
} = projectSlice.actions;
export default projectSlice.reducer;
