import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineRight,
} from "react-icons/ai";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store";
import { Project, RequirementPhase } from "../../store/project/types";
import {
  useDeletePhaseMutation,
  useGetHighLevelTasksQuery,
  useUpdatePhaseMutation,
  useCreatePhaseMutation,
  useUpdatePhaseRanksMutation,
  projectApiUtils,
  useGetProjectByIdQuery,
} from "../../store/project/projectApiService";
import {
  CurrentHihgLevelTasks,
  setCurrentHighLevelTasks,
  setCurrentProject,
} from "../../store/project/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TableLoader } from "../utils/ContentLoader";
import { trackUser } from "../../utils";
import AutoResizeTextarea from "../utils/AutoResizeTextArea";
import StatusFlag from "../utils/StatusFlag";
import PhaseDetails from "./PhaseDetails";
import { Tooltip } from "react-tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const WorkBreakdownStructure = () => {
  const dispatch = useDispatch();
  const intervalRef = useRef<null | number | NodeJS.Timeout>(null);
  const { projectId, requirementId } = useParams();
  const [deletingId, setDeletingId] = useState<string>("");
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingName, setEditingName] = useState("");
  const [editingDescription, setEditingDescription] = useState("");
  const [deletePhase] = useDeletePhaseMutation();
  const [createPhase] = useCreatePhaseMutation();
  const [updatePhaseRanks] = useUpdatePhaseRanksMutation();
  const [updatePhase, updatePhaseResult] = useUpdatePhaseMutation();
  const [openPhaseId, setOpenPhaseId] = useState<string | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const phaseNameRef = useRef<HTMLInputElement>(null);
  let currentHighLevelTasks: CurrentHihgLevelTasks | null = null;
  currentHighLevelTasks = useSelector<RootState, CurrentHihgLevelTasks | null>(
    (state) => {
      return state.projectData.currentHighLevelTasks;
    }
  );
  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  const { data: requestProjectData } = useGetProjectByIdQuery(projectId!, {
    skip: projectId == null,
  });

  useEffect(() => {
    if (requestProjectData) {
      dispatch(setCurrentProject(requestProjectData.data));
    }

    const { isWorkBreakdownPhasesGenerated, isResourcesGenerated } =
      project?.requirement || {};

    if (
      !intervalRef.current &&
      (!isWorkBreakdownPhasesGenerated || !isResourcesGenerated)
    ) {
      intervalRef.current = setInterval(() => {
        if (!isWorkBreakdownPhasesGenerated) {
          dispatch(
            projectApiUtils.invalidateTags([
              { type: "HighLevelTasks", id: requirementId },
            ])
          );
        }
        dispatch(
          projectApiUtils.invalidateTags([{ type: "Project", id: project?.id }])
        );
      }, 5000);
    }

    if (
      intervalRef.current &&
      isWorkBreakdownPhasesGenerated &&
      isResourcesGenerated
    ) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      intervalRef.current = null;
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current as NodeJS.Timeout);
        intervalRef.current = null;
      }
    };
  }, [
    requestProjectData,
    dispatch,
    project?.id,
    requirementId,
    project?.requirement,
  ]);

  const { data: requestHighLevelTasks } = useGetHighLevelTasksQuery(
    requirementId!,
    {
      skip: requirementId == null,
    }
  );

  useEffect(() => {
    if (editingId && phaseNameRef.current) {
      phaseNameRef.current.focus();
      phaseNameRef.current.select();
    }
  }, [editingId]);

  useEffect(() => {
    if (requestHighLevelTasks) {
      dispatch(
        setCurrentHighLevelTasks({
          requirementId: requirementId!,
          phases: requestHighLevelTasks.data.phases,
        })
      );
    }
  }, [requirementId, requestHighLevelTasks, dispatch]);

  const navigate = useNavigate();
  const location = useLocation();

  const isChildRoute = location.pathname.includes("tasks-and-subtasks");

  if (isChildRoute) {
    return <Outlet />;
  }

  const loaded = () => {
    return (
      currentHighLevelTasks &&
      currentHighLevelTasks.requirementId === requirementId
    );
  };

  const approveWorkBreakDown = async () => {
    dispatch(
      projectApiUtils.invalidateTags([
        { type: "ResourceSummary", id: project?.requirement?.id },
      ])
    );
    navigate(
      `/dashboard/${project?.id}/resource-planning/${project?.requirement?.id}`
    );
  };

  const resetEditModel = () => {
    setEditingId(null);
    setEditingName("");
    setEditingDescription("");
  };

  const sendUpdates = () => {
    if (editingId) {
      const editingItem = {
        id: editingId,
        name: editingName,
        description: editingDescription,
      };
      updatePhase(editingItem)
        .unwrap()
        .then((result) => {
          dispatch(
            setCurrentHighLevelTasks({
              requirementId: requirementId!,
              phases: result.data.phases,
            })
          );

          resetEditModel();
        });
    }
  };
  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendUpdates();
    } else if (e.key === "Escape") {
      resetEditModel();
    }
  };

  const onEnterPressTextArea = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === "Escape") {
      resetEditModel();
    }
  };

  const startEditing = (phase: RequirementPhase) => {
    trackUser("Edit Phase");
    setEditingId(phase.id);
    setEditingName(phase.name);
    setEditingDescription(phase.description);
  };
  const deletePhaseClick = (id: string) => {
    setDeletingId(id);
    trackUser("Delete Phase");
  };

  const handleConfirmDelete = (id: string) => {
    deletePhase(id)
      .unwrap()
      .then(() => {
        toast.success("Successfully deleted!");
        setDeletingId("");
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
      });
  };

  const handleCancelDelete = () => {
    setDeletingId("");
  };

  const showStandardRaw = (phase: RequirementPhase, index: number) => {
    return (
      <Draggable
        key={phase.id}
        draggableId={phase.id}
        index={index}
        isDragDisabled={project?.requirement?.isWorkBreakdownApproved}
      >
        {(provided: any) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className="container shadow border border-gray-200 rounded rounded-xl mb-4"
          >
            <div className="flex items-center w-full">
              <div
                className={`wbs-phase-header flex flex-col items-start pr-6 hover:cursor-pointer hover:bg-brandSubtle rounded-xl w-full ${
                  openPhaseId === phase.id ? "bg-brandSubtle" : ""
                }`}
                onClick={() => togglePhase(phase)}
              >
                <div className="w-full pl-8 pt-6 mb-3 flex items-center justify-between">
                  <div>
                    <h2 className="text-[1.35rem] grow font-semibold m-0">
                      {phase.name}
                    </h2>
                    <span className="text-md text-gray-800 max-w-xl">
                      {phase.description}
                    </span>
                  </div>
                  {!project?.requirement?.isWorkBreakdownApproved && (
                    <div className="flex items-center justify-center gap-3">
                      <div>
                        <AiOutlineEdit
                          data-tooltip-id={`edit-${phase.id}`}
                          data-tooltip-content="Edit Phase"
                          className="hover:text-gray-500 cursor-pointer"
                          size={20}
                          onClick={(e) => {
                            e.stopPropagation();
                            startEditing(phase);
                          }}
                          title="Edit Project Phase"
                        />
                        <Tooltip id={`edit-${phase.id}`} />
                      </div>

                      <div>
                        <AiOutlineDelete
                          data-tooltip-id={`delete-${phase.id}`}
                          data-tooltip-content="Delete Phase"
                          className="hover:text-gray-500 cursor-pointer"
                          size={20}
                          onClick={(e) => {
                            e.stopPropagation();
                            deletePhaseClick(phase.id);
                          }}
                          title="Delete Project Phase"
                        />
                        <Tooltip id={`delete-${phase.id}`} />
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-center items-center w-full py-2">
                  <div className="inline-flex items-center rounded-md cursor-pointer group relative">
                    <div className="absolute bottom-[-50px] left-1/2 -translate-x-1/2 hidden group-hover:block w-auto bg-gray-600 rounded px-4 py-1 text-xs text-white text-center">
                      Toggle Tasks
                    </div>
                    <abbr className="text-xl font-bold">
                      <img
                        src="/icons/expand.png"
                        className="w-4 h-4"
                        alt="expand"
                      />
                    </abbr>
                  </div>
                </div>
              </div>
              {deletingId === phase.id && (
                <div className="flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2 mr-6">
                  <span className="mr-2">Are you sure?</span>
                  <AiOutlineCheck
                    className="hover:text-green-500 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleConfirmDelete(phase.id);
                    }}
                    size={20}
                  />
                  <AiOutlineClose
                    className="hover:text-red-500 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCancelDelete();
                    }}
                    size={20}
                  />
                </div>
              )}
            </div>

            <PhaseDetails
              requirementId={requirementId!}
              phase={phase}
              isOpen={openPhaseId === phase.id}
              project={project}
            />
          </div>
        )}
      </Draggable>
    );
  };

  const showEditableRaw = (phase: RequirementPhase, index: number) => {
    return (
      <Draggable
        key={phase.id}
        draggableId={phase.id}
        index={index}
        isDragDisabled={project?.requirement?.isWorkBreakdownApproved}
      >
        {(provided: any) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className="container shadow border border-gray-200 rounded rounded-xl mb-4"
          >
            <div className="wbs-phase-header flex flex-col items-start pb-4 bg-brandSubtle rounded-xl">
              <div className="w-full pl-8 pt-4 mb-3 flex items-center justify-between">
                <div className="w-full pr-8">
                  <input
                    type="text"
                    value={editingName}
                    onChange={(e) => setEditingName(e.target.value)}
                    autoFocus
                    readOnly={updatePhaseResult.isLoading}
                    ref={phaseNameRef}
                    className="block w-full py-2 px-2 text-gray-900 bg-white-10 border border-gray-400 rounded hover:bg-white-10 focus:outline-none focus:ring-2 focus:ring-brand mb-2"
                    onKeyUp={onEnterPress}
                  />
                  <AutoResizeTextarea
                    value={editingDescription}
                    onChange={(e) => setEditingDescription(e.target.value)}
                    isLoading={updatePhaseResult.isLoading}
                    onKeyPress={onEnterPressTextArea}
                    ref={inputRef}
                  />
                </div>
                <div className="flex items-center justify-center gap-2 pr-4">
                  <AiOutlineCheck
                    onClick={sendUpdates}
                    size={20}
                    className="hover:text-green-500 cursor-pointer"
                  />
                  <AiOutlineClose
                    onClick={resetEditModel}
                    size={20}
                    className="hover:text-red-500 cursor-pointer"
                  />
                </div>
              </div>
              <PhaseDetails
                requirementId={requirementId!}
                phase={phase}
                isOpen={openPhaseId === phase.id}
                project={project}
              />
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const showPhaseRaw = (phase: RequirementPhase, index: number) => {
    if (editingId !== phase.id) {
      return showStandardRaw(phase, index);
    } else {
      return showEditableRaw(phase, index);
    }
  };

  const togglePhase = (phase: RequirementPhase) => {
    setOpenPhaseId((prevOpenPhaseId) =>
      prevOpenPhaseId === phase.id ? null : phase.id
    );
  };

  function addNewPhase(): void {
    trackUser("Add New Phase");
    const newPhase = {
      name: "[New Phase]",
      description: "[Description]",
    };
    createPhase({ requirementId: requirementId!, data: newPhase })
      .unwrap()
      .then((result) => {
        const createdItem = result.data.phases.find(
          (item: RequirementPhase) => item.name === "[New Phase]"
        );
        if (createdItem) {
          dispatch(
            setCurrentHighLevelTasks({
              requirementId: requirementId!,
              phases: result.data.phases,
            })
          );
          toast.success(`Successfully created new project phase!`);
          setEditingId(createdItem.id);
          setEditingName(createdItem.name);
          setEditingDescription(createdItem.description);
        } else {
          toast.error(
            "Could not find the newly created phase. Please try again later."
          );
        }
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error creating phase!");
      });
  }

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const reorderedPhases = Array.from(currentHighLevelTasks!.phases);
    const [removed] = reorderedPhases.splice(result.source.index, 1);
    reorderedPhases.splice(result.destination.index, 0, removed);

    // Update ranks based on new order
    const updatedPhases = reorderedPhases.map((phase, index) => ({
      ...phase,
      rank: index + 1,
    }));

    dispatch(
      setCurrentHighLevelTasks({
        requirementId: requirementId!,
        phases: updatedPhases,
      })
    );

    // Call the backend API to update the ranks
    updatePhaseRanks({
      requirementId: currentHighLevelTasks!.requirementId,
      phases: updatedPhases,
    })
      .unwrap()
      .then(() => {
        toast.success("Phases order saved");
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error updating phase order!");
      });
  };

  return (
    <div className="px-4 pt-2 pb-8">
      <div className="sticky top-0 pt-4 bg-white/90 z-10 flex items-start rfi-header">
        <div className="flex grow gap-6 mb-4 items-center">
          <h2 className="grow text-[1.5rem] font-semibold">
            Work Breakdown Structure
          </h2>
          {project?.requirement?.isWorkBreakdownApproved && (
            <StatusFlag status="approved" />
          )}
        </div>
        {currentHighLevelTasks?.phases?.length !== 0 &&
          !project?.requirement?.isWorkBreakdownApproved && (
            <div className="ml-4">
              <button
                className="button-secondary !bg-white"
                onClick={addNewPhase}
              >
                <img src="/icons/add.png" className="w-6" alt="" />
                Add new phase
              </button>
            </div>
          )}
        {currentHighLevelTasks?.phases?.length !== 0 && (
          <div className="flex ml-4 gap-4">
            {!project?.requirement?.isWorkBreakdownApproved && (
              <button
                disabled={!project?.requirement?.isResourcesGenerated}
                className="button-primary-icon flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
                onClick={approveWorkBreakDown}
              >
                Next
                <AiOutlineRight
                  className="cursor-pointer"
                  size={20}
                  title="View Open Questions"
                />
              </button>
            )}
          </div>
        )}
      </div>
      {project?.requirement?.isWorkBreakdownApproved && (
        <div className="bg-brandYellow p-4 rounded-xl mb-4">
          <span className="text-[#475467]">
            Work Breakdown Structure is approved. You can no longer edit tasks.
          </span>
        </div>
      )}

      {!loaded() ? (
        <TableLoader />
      ) : !project?.requirement?.isWorkBreakdownPhasesGenerated ? (
        <div className="w-full flex flex-col items-center p-12 bg-gray-25 rounded-xl border border-gray-100 shadow">
          <img
            src="/icons/estimation.png"
            className="w-8 opacity-50 animate-spin"
            alt="Generating Work Breakdown Structure in progress"
          />
          <span className="text-sm text-gray-600 text-center">
            Generating Work Breakdown Structure in progress. Please wait...
          </span>
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="phases">
            {(provided: any) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {currentHighLevelTasks!.phases.map((phase, index) => (
                  <div key={phase.id}>{showPhaseRaw(phase, index)}</div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default WorkBreakdownStructure;
