import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

const ComingSoon: React.FC = () => {
  return (
    <div className="h-[50vh] p-4 flex items-center justify-center">
      <div
        className="w-[500px] h-[650px] flex flex-col gap-4 items-center justify-center pt-36"
        style={{
          backgroundImage: `url('images/start-bg.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <h2 className="text-gray-900 mb-0">Coming Soon!</h2>
        <p className="text-center text-gray-500 w-2/3">
          This feature is coming soon. Stay tuned for updates!
        </p>
        <button
          className="button-primary-icon flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
          disabled
        >
          <AiOutlinePlus
            className="cursor-pointer"
            size={20}
            title="Subscribe"
          />
          Subscribe me for updates
        </button>
      </div>
    </div>
  );
};

export default ComingSoon;
