import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCompanyQuery } from "../../store/company/companyApiService";
import { Navigate, Route, Routes } from "react-router-dom";
import OnboardingWizard from "./OnboardingWizard";
import { setCompanyData } from "../../store/company/companyDataSlice";
import { Company } from "../../store/company/types";
import { RootState } from "../../store";
import { useAuth0 } from "@auth0/auth0-react";
import io from "socket.io-client";
import { capabilityCheckApiUtil } from "../../store/capabilityCheck/capabilityCheckApiService";
import { companyApiUtil } from "../../store/company/companyApiService";
import { questionnaireApiUtils } from "../../store/questionnaire/questionnaireApiService";
import { toast } from "react-toastify";
import { projectApiUtils } from "../../store/project/projectApiService";
import { TableLoader } from "../utils/ContentLoader";

function withOnboardingRouting(Component: any) {
  const WrappedComponent = (props: any) => {
    const { data } = useGetCompanyQuery();
    const dispatch = useDispatch();
    const { user } = useAuth0();

    useEffect(() => {
      if (!user?.sub) return;
      const socket = io(`${window.location.origin}?userId=${user?.sub}`, {
        reconnectionAttempts: 5,
        reconnectionDelay: 2000,
      });

      socket.on("questionnaire_changed", ({ data: { project_id } }) => {
        setTimeout(() => {
          dispatch(
            questionnaireApiUtils.invalidateTags([
              "Questionnaire",
              "QuestionnaireHistory",
            ])
          );
          dispatch(
            projectApiUtils.invalidateTags([
              { type: "Project", id: project_id },
            ])
          );
        });
      });

      socket.on(
        "processing_project_request_scope_completed",
        ({ data: { requirement_id } }) => {
          setTimeout(() => {
            dispatch(
              projectApiUtils.invalidateTags([
                { type: "ScopeContext", id: requirement_id },
                { type: "QuestionsAndAssumptions", id: requirement_id },
                { type: "ScopeContext", id: requirement_id },
              ])
            );
          });
        }
      );
      socket.on(
        "generate_project_scope_items_completed",
        ({ data: { requirement_id } }) => {
          setTimeout(() => {
            dispatch(
              projectApiUtils.invalidateTags([
                { type: "ScopeContext", id: requirement_id },
              ])
            );
          });
        }
      );
      socket.on(
        "processing_project_work_breakdown_phases_completed",
        ({ data: { project_id, requirement_id } }) => {
          setTimeout(() => {
            dispatch(
              projectApiUtils.invalidateTags([
                { type: "HighLevelTasks", id: requirement_id },
                { type: "Project", id: project_id },
              ])
            );
          });
        }
      );
      socket.on(
        "processing_project_work_breakdown_sub_tasks_completed",
        ({ data: { phase_id } }) => {
          setTimeout(() => {
            dispatch(
              projectApiUtils.invalidateTags([
                { type: "TaskAndSubTasks", id: phase_id },
              ])
            );
          });
        }
      );
      socket.on("capability_check_changed", () => {
        setTimeout(() => {
          dispatch(
            capabilityCheckApiUtil.invalidateTags(["CapabilitiesCheck"])
          );
        });
      });

      socket.on("capability_checks_created", () => {
        setTimeout(() => {
          dispatch(
            capabilityCheckApiUtil.invalidateTags(["CapabilitiesCheck"])
          );
        });
      });

      socket.on("company_document_status_changed", () => {
        setTimeout(() => {
          dispatch(companyApiUtil.invalidateTags(["CompanyDocument"]));
        });
      });

      socket.on(
        "processing_project_quote_started",
        ({ data: { requirement_id } }) => {
          setTimeout(() => {
            dispatch(
              projectApiUtils.invalidateTags([
                {
                  type: "Quote",
                  id: requirement_id,
                },
              ])
            );
          });
        }
      );

      socket.on(
        "processing_project_quote_completed",
        ({ data: { project_id, requirement_id } }) => {
          setTimeout(() => {
            dispatch(
              projectApiUtils.invalidateTags([
                {
                  type: "Quote",
                  id: requirement_id,
                },
                { type: "Project", id: project_id },
              ])
            );
          });
        }
      );

      socket.on(
        "processing_project_proposal_generation_completed",
        ({ data: { requirement_id } }) => {
          setTimeout(() => {
            dispatch(
              projectApiUtils.invalidateTags([
                {
                  type: "ProposalSettings",
                  id: requirement_id,
                },
              ])
            );
          });
        }
      );

      socket.on(
        "processing_project_proposal_generation_failed",
        ({ data: { requirement_id, proposal_id } }) => {
          setTimeout(() => {
            toast.error("Proposal generation failed", {
              toastId: proposal_id,
            });
            dispatch(
              projectApiUtils.invalidateTags([
                {
                  type: "ProposalSettings",
                  id: requirement_id,
                },
              ])
            );
          });
        }
      );

      socket.on("questionnaire_processing_failed", ({ data }) => {
        setTimeout(() => {
          toast.error(data.error_message, {
            toastId: data.questionnaire_id,
          });
          dispatch(
            questionnaireApiUtils.invalidateTags([
              "Questionnaire",
              "QuestionnaireHistory",
            ])
          );
        });
      });

      return () => {
        socket.disconnect();
      };
    }, [user?.sub, dispatch]);

    const company = useSelector<RootState, Company | null>(
      (state) => state.companyData.company
    );
    useEffect(() => {
      if (data) {
        dispatch(setCompanyData(data));
      }
    }, [data, dispatch]);

    if (!company) {
      return <TableLoader />;
    }
    if (!company?.isOnboarded) {
      return (
        <Routes>
          <Route path="/onboarding/*" element={<OnboardingWizard />} />
          <Route
            path="*"
            element={<Navigate to="/onboarding/about-company" />}
          />
        </Routes>
      );
    } else {
      return <Component {...props} />;
    }
  };

  return WrappedComponent;
}

export default withOnboardingRouting;
