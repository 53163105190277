import React, { forwardRef, useEffect, useRef } from "react";

interface AutoResizeTextareaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isLoading: boolean;
  onKeyPress: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const AutoResizeTextarea = forwardRef<HTMLTextAreaElement, AutoResizeTextareaProps>(({
  value,
  onChange,
  isLoading,
  onKeyPress,
}, ref) => {

  useEffect(() => {
    if (ref && 'current' in ref && ref.current) {
      ref.current.style.height = "inherit";
      ref.current.style.height = `${ref.current.scrollHeight}px`; 
    }
  }, [value, ref]);

  return (
    <textarea
      ref={ref}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyPress}
      disabled={isLoading}
      autoFocus
      rows={1}
      className="w-full py-2 px-2 text-gray-900 bg-gray-100 rounded hover:bg-gray-100 focus:border focus:border-brandHover focus:outline-brand"
      style={{ overflowY: "hidden" }}
    />
  );
});

export default AutoResizeTextarea;
