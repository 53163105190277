import React, { useEffect, useRef, useState } from "react";
import {
  useFormik,
  FormikProvider,
  FieldArray,
  Form,
  Field,
  ErrorMessage,
  FieldProps,
} from "formik";
import * as Yup from "yup";
import {
  RequirementTask,
  RequirementTaskResource,
  ResourceType,
} from "../../store/project/types";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
} from "react-icons/ai";

interface TaskEditDrawerProps {
  isOpen: boolean;
  maskOpen: boolean;
  toggleDrawer: () => void;
  task: RequirementTask | null;
  onSave: (task: RequirementTask) => void;
  onDelete: (task: RequirementTask) => void;
  resourceTypes: ResourceType[];
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  resources: Yup.array().of(
    Yup.object().shape({
      resourceType: Yup.object().shape({
        id: Yup.string().required("Resource type is required"),
      }),
      effort: Yup.number()
        .required("Effort is required")
        .positive("Effort must be positive"),
    })
  ),
});

const TaskEditDrawer: React.FC<TaskEditDrawerProps> = ({
  isOpen,
  maskOpen,
  toggleDrawer,
  task,
  onSave,
  onDelete,
  resourceTypes,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);

  const formik = useFormik({
    initialValues: {
      name: task ? task.name : "",
      description: task ? task.description : "",
      resources: task
        ? task.resources.map((resource) => ({
            ...resource,
            resourceType: {
              id: resource.resourceType.id,
              name:
                resourceTypes.find(
                  (type) => type.id === resource.resourceType.id
                )?.name || "",
            },
          }))
        : [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (task) {
        values.resources = values.resources.map(
          (resource: RequirementTaskResource) => {
            return {
              ...resource,
              resourceType: {
                id: resource.resourceType.id,
                name:
                  resourceTypes.find(
                    (type) => type.id === resource.resourceType.id
                  )?.name || "",
              },
            };
          }
        );
        onSave({
          ...task,
          name: values.name,
          description: values.description,
          resources: values.resources,
        });
        toggleDrawer();
      }
    },
  });

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
      nameRef.current.select();
    }
    if (task) {
      formik.setValues({
        name: task.name,
        description: task.description,
        resources: task.resources.map((resource) => ({
          ...resource,
          resourceType: {
            id: resource.resourceType.id,
            name:
              resourceTypes.find((type) => type.id === resource.resourceType.id)
                ?.name || "",
          },
        })),
      });
    }
    // Adding task to the dependency array
  }, [task, resourceTypes]);

  const handleDeleteConfirm = () => {
    if (task) {
      setShowDeleteConfirmation(false);
      onDelete(task);
      toggleDrawer();
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  return (
    <div
      className={`fixed inset-0 overflow-hidden z-50 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div
        className={`absolute inset-0 bg-gray-100 bg-opacity-75 transition-opacity duration-300 ${
          maskOpen ? "opacity-100" : "opacity-0"
        }`}
        onClick={toggleDrawer}
      ></div>
      <div
        className={`fixed top-0 right-0 w-1/3 h-full bg-white shadow-lg z-50 transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div>
          <FormikProvider value={formik}>
            <Form>
              <div className="p-6 flex flex-col gap-8">
                <div>
                  <h1 className="font-semibold mb-6">Edit Task</h1>
                  <label className="block">
                    Name *
                    <Field name="name">
                      {({ field }: FieldProps) => (
                        <input
                          {...field}
                          type="text"
                          ref={nameRef}
                          placeholder="Enter task name"
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-brand"
                          required
                          autoFocus
                          onKeyDown={(
                            e: React.KeyboardEvent<HTMLInputElement>
                          ) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              formik.submitForm();
                            } else if (e.key === "Escape") {
                              toggleDrawer();
                            }
                          }}
                        />
                      )}
                    </Field>
                  </label>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="mt-4">
                  <label className="block">
                    Description *
                    <Field name="description">
                      {({ field }: FieldProps) => (
                        <textarea
                          {...field}
                          placeholder="Enter task description"
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-brand"
                          required
                          rows={4}
                          onKeyDown={(
                            e: React.KeyboardEvent<HTMLTextAreaElement>
                          ) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              formik.submitForm();
                            } else if (e.key === "Escape") {
                              toggleDrawer();
                            }
                          }}
                        />
                      )}
                    </Field>
                  </label>
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="mt-4">
                  <label className="block">
                    {task?.parentId && "Resources"}
                    <FieldArray
                      name="resources"
                      render={({ remove, push }) => (
                        <div>
                          {formik.values.resources.map((resource, index) => (
                            <div key={index} className="flex items-center mt-2">
                              <div className="flex-grow">
                                <Field
                                  as="select"
                                  name={`resources.${index}.resourceType.id`}
                                  className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-brand"
                                >
                                  <option
                                    value=""
                                    label="Select resource type"
                                  />
                                  {resourceTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name={`resources.${index}.resourceType.id`}
                                  component="div"
                                  className="text-red-500"
                                />
                              </div>
                              <div className="flex-grow mx-2">
                                <Field
                                  type="number"
                                  name={`resources.${index}.effort`}
                                  placeholder="Effort"
                                  className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-brand"
                                  required
                                />
                                <ErrorMessage
                                  name={`resources.${index}.effort`}
                                  component="div"
                                  className="text-red-500"
                                />
                              </div>
                              <div>
                                <AiOutlineDelete
                                  className="text-red-500 cursor-pointer hover:text-red-700"
                                  onClick={() => remove(index)}
                                  size={20}
                                  title="Delete Resource"
                                />
                              </div>
                            </div>
                          ))}
                          {task?.parentId && (
                            <div className="flex items-center justify-center">
                              <button
                                type="button"
                                className="button-secondary mt-4"
                                onClick={() =>
                                  push({
                                    resourceType: { id: "", name: "" },
                                    effort: 0,
                                  })
                                }
                              >
                                <span className="text-xl">+</span>Add new role
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </label>
                </div>
              </div>

              <div className="fixed bottom-0 h-24 w-full bg-gray-25 border border-b-0 border-l-0 border-r-0 flex items-center px-6">
                {showDeleteConfirmation ? (
                  <div className="flex flex-col w-full">
                    <span className="text-normal mr-2 text-center">
                      Are you sure?
                    </span>
                    <div className="flex items-center gap-3 justify-center">
                      <span
                        className="hover:text-green-500 cursor-pointer font-semibold"
                        onClick={handleDeleteConfirm}
                      >
                        Yes
                      </span>
                      <span
                        className="hover:text-red-500 cursor-pointer font-semibold"
                        onClick={handleCancelDelete}
                      >
                        No
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center w-full">
                    <div className="grow">
                      <button
                        type="button"
                        className="button-secondary-red flex items-center"
                        onClick={handleDelete}
                      >
                        Delete
                        <img
                          src="/icons/garbage-white.png"
                          className="w-4"
                          alt=""
                        />
                      </button>
                    </div>
                    <div className="flex items-center ">
                      <button
                        type="submit"
                        className="button-primary-icon !px-6"
                      >
                        Save
                        <img
                          src="/icons/save-white.png"
                          className="w-4"
                          alt=""
                        />
                      </button>
                      <button
                        type="button"
                        onClick={toggleDrawer}
                        className="button-secondary w-[1/3] ml-4"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

export default TaskEditDrawer;
