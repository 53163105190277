import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  CapabilityCheck,
  Questionnaire,
} from "../../store/questionnaire/types";
import { useParams } from "react-router-dom";
import {
  useGetCapabilityChecksQuery,
  downloadDocumentApi,
} from "../../store/capabilityCheck/capabilityCheckApiService";
import {
  setCapabilityChecks,
  setQuestionnaire,
} from "../../store/questionnaire/questionnaireSlice";
import { CapabilityCheckItem } from "./CapabilityCheckItem";
import {
  useGetQuestionnaireQuery,
  useUpdateQuestionnaireTitleMutation,
} from "../../store/questionnaire/questionnaireApiService";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { TableLoader } from "../utils/ContentLoader";
import { trackUser } from "../../utils";

export function CapabilityCheckList() {
  const dispatch = useDispatch();
  const { capabilityCheckId } = useParams();
  const [updateQuestionnaireTitle] = useUpdateQuestionnaireTitleMutation();
  const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false);
  const [editingTitle, setEditingTitle] = useState("");
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const { data, isSuccess, isLoading, error } = useGetCapabilityChecksQuery(
    capabilityCheckId!
  );
  const currentQuestionnaire = useSelector<RootState, Questionnaire | null>((state) => {
    return state.questionnaireData.currentQuestionnaire;
  });
  const { data: questionnaireInitialData, isLoading: isRFPLoading } =
    useGetQuestionnaireQuery(capabilityCheckId!, {
      skip: capabilityCheckId == null,
    });

  const handleDownloadClick = async (fileName: string) => {
    setIsDownloadLoading(true);
    downloadDocumentApi(capabilityCheckId!, fileName).finally(() => {
      setIsDownloadLoading(false);
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setCapabilityChecks(data));
    }
  }, [data, isSuccess, dispatch]);

  useEffect(() => {
    if (questionnaireInitialData) {
      dispatch(setQuestionnaire(questionnaireInitialData));
    }
  }, [questionnaireInitialData, dispatch]);

  const capabilityChecks = useSelector<RootState, CapabilityCheck[]>(
    (state: RootState) => state.questionnaireData.capabilityChecks
  );
  if (isLoading || isRFPLoading) return <TableLoader />;
  if (error) {
    return <div>Error loading configuration.</div>;
  }

  const resetEditModel = () => {
    setIsTitleEditing(false);
    setEditingTitle("");
  };

  const sendUpdateQuestionnaire = () => {
    if (isTitleEditing && currentQuestionnaire) {
      const updatedQuestionnaire = {
        ...currentQuestionnaire,
        title: editingTitle,
      };
      updateQuestionnaireTitle(updatedQuestionnaire)
        .unwrap()
        .then(() => {
          dispatch(
            setQuestionnaire({
              ...currentQuestionnaire,
              title: editingTitle,
            })
          );
          resetEditModel();
        })
        .catch((err: any) => {
          toast.error(err?.data?.message || "Error!");
        });
    }
  };

  const stratEditing = () => {
    setIsTitleEditing(true);
    setEditingTitle(currentQuestionnaire?.title || "");
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendUpdateQuestionnaire();
    } else if (e.key === "Escape") {
      resetEditModel();
    }
  };

  const handleDownloadResponses = async () => {
    trackUser("Questionnaire Download Responses", {
      title: currentQuestionnaire?.title,
    });
    handleDownloadClick(currentQuestionnaire?.title || "");
  };

  const getMatchFactorColorIndicator = () => {
    if (Math.round(currentQuestionnaire?.score || 0) < 33) {
      return "bg-red-fail rounded rounded-full h-2";
    } else if (Math.round(currentQuestionnaire?.score || 0) < 65) {
      return "bg-yellow-500 rounded rounded-full h-2";
    } else {
      return "bg-green-success rounded rounded-full h-2";
    }
  };

  return (
    <div className="p-4 pb-8 mt-4">
      <div className="container shadow border border-gray-200 rounded rounded-xl">
        <div className="flex items-start rfi-header px-8 py-8">
          <div className="flex-grow mb-4">
            {isTitleEditing ? (
              <div className="grow relative">
                <input
                  type="text"
                  value={editingTitle}
                  onChange={(e) => setEditingTitle(e.target.value)}
                  autoFocus
                  className="block w-full py-2 text-gray-900 bg-gray-100 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-opacity-50"
                  onKeyUp={onEnterPress}
                />
                <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                  <AiOutlineCheck
                    onClick={sendUpdateQuestionnaire}
                    size={20}
                    className="hover:text-green-500 cursor-pointer"
                  />
                  <AiOutlineClose
                    onClick={resetEditModel}
                    size={20}
                    className="hover:text-red-500 cursor-pointer"
                  />
                </div>
              </div>
            ) : (
              <h1 className="grow cursor-pointer" onDoubleClick={stratEditing}>
                {currentQuestionnaire?.title}
              </h1>
            )}
            <div className="flex items-start gap-3">
              <img
                src="/icons/match-factor-ok.png"
                className="w-5 h-5"
                alt=""
              />
              <div className="w-1/2">
                <div className="text-gray-700 font-semibold mb-3">
                  Match factor: {Math.round(currentQuestionnaire?.score || 0)}%
                </div>
                <div className="h-2 bg-gray-200 rounded rounded-full">
                  <div
                    className={getMatchFactorColorIndicator()}
                    style={{
                      width: `${Math.round(currentQuestionnaire?.score || 0) || 0}%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex ml-4">
            <button
              onClick={handleDownloadResponses}
              disabled={capabilityChecks.length === 0 || isDownloadLoading}
              className="px-4 py-2 shadow-sm border border-gray-300 px-3 rounded-lg text-lg hover:bg-gray-100 focus:outline-none focus:ring focus:ring-green-200 flex items-center gap-2 disabled:text-gray-400 disabled:hover:bg-white"
            >
              <img
                src="/icons/download.png"
                className="w-5 h-5"
                alt="download your response"
              />
              Download
            </button>
          </div>
        </div>

        <table className="min-w-full text-[15px]">
          <thead>
            <tr>
              <th className="w-4 py-3 pl-6 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                Id
              </th>
              <th className="w-1/2 py-3 pl-2 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                Requirement
              </th>
              <th className="w-1/2 py-3 px-6 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                Answer
              </th>
              <th className="w-2 py-3 px-6 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                Compliant
              </th>
              <th className="w-2 py-3 px-6 border-b border-t border-gray-200 text-gray-600 font-bold bg-gray-50">
                Reviewed
              </th>
              <th className="w-2 py-3 px-6 border-b border-t border-gray-200 text-gray-600 tracking-wider font-bold bg-gray-50">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {capabilityChecks.map((requirement) => (
              <CapabilityCheckItem key={requirement.id} item={requirement} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
