import React from "react";
import { useLocation, Link, matchPath } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Project, RequirementPhase } from "../store/project/types";
import { Questionnaire } from "../store/questionnaire/types";
import { trackUser } from "../utils";

const Breadcrumbs = () => {
  const location = useLocation();
  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  const currentPhase = useSelector<RootState, RequirementPhase | null>(
    (state) => {
      return state.projectData.currentPhase;
    }
  );

  const currentQuestinnarie = useSelector<RootState, Questionnaire | null>(
    (state) => {
      return state.questionnaireData.currentQuestionnaire;
    }
  );

  type BreadcrumbEntry = {
    path: string;
    breadcrumb: string;
    active: boolean;
  };

  const routes = [
    { path: "/home", breadcrumb: "Home" },
    {
      path: "dashboard/:projectId",
      breadcrumb: () => project?.title || "Proposal",
    },
    {
      path: "dashboard/:projectId/work-breakdown-structure/:requirementId",
      breadcrumb: "Work Breakdown Structure",
    },
    {
      path: "dashboard/:projectId/work-breakdown-structure/:requirementId/tasks-and-subtasks/:phaseId",
      breadcrumb: () => currentPhase?.name || "Phase",
    },
    {
      path: "dashboard/:projectId/scoping/:requirementId",
      breadcrumb: "Scoping",
    },
    {
      path: "dashboard/:projectId/scoping/:requirementId/open-questions",
      breadcrumb: "Open Questions",
    },
    {
      path: "dashboard/:projectId/resource-planning/:requirementId",
      breadcrumb: "Resource Overview",
    },
    {
      path: "dashboard/:projectId/resource-planning/:requirementId/resource-type-planning/:phsaseId/:resourceTypeId",
      breadcrumb: "Phase Details",
    },
    {
      path: "dashboard/:projectId/quote/:requirementId",
      breadcrumb: "Quote",
    },
    {
      path: "dashboard/:projectId/proposal/:requirementId",
      breadcrumb: "Proposal",
    },
    {
      path: "questionnaires",
      breadcrumb: () => "All Questionnaires",
    },
    {
      path: "questionnaires/capability-checks/:capabilityCheckId",
      breadcrumb: () => currentQuestinnarie?.title || "Capability Checks",
    },
    {
      path: "questionnaires/library",
      breadcrumb: () => "Our Capabilities",
    },
    {
      path: "settings/company",
      breadcrumb: "Company",
    },
    {
      path: "settings/staff-rates",
      breadcrumb: "Staff Rates",
    },
    {
      path: "settings/user-management",
      breadcrumb: "User Management",
    },
    {
      path: "settings/branding",
      breadcrumb: "Branding",
    },
    {
      path: "settings/billing",
      breadcrumb: "Billing",
    },
  ];

  const breadcrumbPaths: BreadcrumbEntry[] = routes.reduce<BreadcrumbEntry[]>(
    (acc, route) => {
      const match = matchPath(
        { path: route.path, end: false },
        location.pathname
      );
      if (match) {
        const breadcrumb =
          typeof route.breadcrumb === "function"
            ? route.breadcrumb()
            : route.breadcrumb;
        acc.push({
          path: match.pathnameBase,
          breadcrumb,
          active: location.pathname === match.pathnameBase,
        });
      }
      return acc;
    },
    [
      {
        path: "/home",
        breadcrumb: "Home",
        active: false,
      },
    ]
  );

  if (!breadcrumbPaths.length || location.pathname === "/home") {
    return null; // No breadcrumbs for the home page or if no paths matched
  }

  const handleLinkClick = (to: string) => {
    trackUser("Breadcrumb Click", {
      to,
    });
  };

  return (
    <div className="breadcrumb mt-8">
      <nav>
        <ul className="breadcrumb inline pl-4 text-[14px] text-gray-600">
          {breadcrumbPaths.map((crumb, index) => (
            <li
              key={index}
              className={`breadcrumb-item inline ${
                crumb.active
                  ? "active bg-gray-50 text-gray-800 font-semibold rounded rounded-lg px-2 py-1"
                  : ""
              }`}
            >
              {crumb.active ? (
                crumb.breadcrumb
              ) : (
                <Link
                  to={crumb.path}
                  onClick={() => handleLinkClick(crumb.path)}
                >
                  {crumb.breadcrumb}{" "}
                  <img
                    className="h-3 inline mx-4"
                    src="/icons/crumb-right.png"
                    alt=""
                  />
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Breadcrumbs;
