import { createSlice } from "@reduxjs/toolkit";
import { Questionnaire, CapabilityCheck } from "./types";
const questionnaireSlice = createSlice({
  name: "questionnaireData",
  initialState: {
    capabilityChecks: [] as CapabilityCheck[],
    currentQuestionnaire: null as Questionnaire | null,
    questionnaires: [] as Questionnaire[],
  },
  reducers: {
    setCapabilityChecks: (state, action) => {
      state.capabilityChecks = action.payload;
    },
    setQuestionnaire: (state, action) => {
      state.currentQuestionnaire = action.payload;
    },
    setQuestionnaires: (state, action) => {
      state.questionnaires = action.payload;
    },
  },
});

export const { setCapabilityChecks, setQuestionnaire, setQuestionnaires } =
  questionnaireSlice.actions;
export default questionnaireSlice.reducer;
