import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/auth",
  }),
  endpoints: (builder) => ({
    resendVerificationEmail: builder.mutation<void, string>({
      query: (userId) => ({
        url: "resend-verification-email",
        method: "POST",
        body: { userId },
      }),
    }),
  }),
});

export const { useResendVerificationEmailMutation } = authApi;


export const authApiMiddleware = authApi.middleware;

export default authApi.reducer;
