import { useDropzone } from "react-dropzone";
import StatusFlag from "./utils/StatusFlag";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  projectApiUtils,
  useDeleteRequirementMutation,
  useGetProjectByIdQuery,
  useGetProposalSettingsQuery,
  useGetQuoteQuery,
  useGetRequestResourceRequirementsQuery,
  useUploadProjectRequirementsMutation,
} from "../store/project/projectApiService";
import {
  CurrentQuote,
  CurrentProposalSettings,
  setCurrentProject,
  setCurrentQuote,
  setCurrentResourceSummary,
  setProposalSettings,
} from "../store/project/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Project,
  RequiredResourcesSummary,
  Requirement,
} from "../store/project/types";
import NewOpportunity from "./NewOpportunity";
import { RootState } from "../store";
import { toast } from "react-toastify";
import { downloadTemplateApi } from "../store/capabilityCheck/capabilityCheckApiService";
import { useUploadQuestionnaireMutation } from "../store/questionnaire/questionnaireApiService";
import { Questionnaire } from "../store/questionnaire/types";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
} from "react-icons/ai";
import { trackUser } from "../utils";
import { formatNumber } from "./utils/helperFunctions";

const Dashboard = () => {
  const { projectId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [uploadProjectRequirements] = useUploadProjectRequirementsMutation();
  const [deleteProjectRequirement] = useDeleteRequirementMutation();
  const isChildRoute =
    location.pathname.includes("questionnaires") ||
    location.pathname.includes("work-breakdown-structure") ||
    location.pathname.includes("scoping") ||
    location.pathname.includes("resource-planning") ||
    location.pathname.includes("quote") ||
    location.pathname.includes("proposal");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [
    showDeleteRequiremenFileConfirmation,
    setShowDeleteRequiremenFileConfirmation,
  ] = useState(false);

  const handleDeleteRequirementFileClick = () => {
    setShowDeleteRequiremenFileConfirmation(true);
  };

  const handleConfirmDeleteRequirementFile = (id: string) => {
    deleteProjectRequirement(id)
      .unwrap()
      .then(() => {
        toast.success("Successfully deleted!");
        setShowDeleteRequiremenFileConfirmation(false);
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
      });
  };

  const handleCancelDeleteRequirementFile = () => {
    setShowDeleteRequiremenFileConfirmation(false);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      uploadProjectRequirements({
        projectId: projectId!,
        file: acceptedFiles[0],
      })
        .unwrap()
        .then(({ data }) => {
          dispatch(
            projectApiUtils.invalidateTags([
              { type: "Project", id: projectId! },
            ])
          );
          trackUser("Requirement", {
            action: "Upload",
            projectId: projectId!,
            fileType: acceptedFiles[0].type,
          });
          navigate(`scoping/${data?.requirement?.id}`);
        })
        .catch((err: any) => {
          toast.error(err?.data?.message || "Error!");
          trackUser("Requirement", {
            action: "Upload Failed",
            projectId: projectId!,
            fileType: acceptedFiles[0].type,
            message: err?.data?.message,
          });
        });
    },
    [projectId, uploadProjectRequirements, dispatch, navigate]
  );

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  const currentQuote = useSelector<RootState, CurrentQuote | null>(
    (state) => state.projectData.currentQuote
  );

  const proposalSettings = useSelector<
    RootState,
    CurrentProposalSettings | null
  >((state) => state.projectData.proposalSettings);

  const { data: requestProposalSettings } = useGetProposalSettingsQuery(
    project?.requirement?.id!,
    {
      skip: project?.requirement?.id == null,
    }
  );

  useEffect(() => {
    if (requestProposalSettings) {
      dispatch(
        setProposalSettings({
          requirementId: project?.requirement?.id!,
          allDocuments: requestProposalSettings.data.allDocuments,
          activeDocument: requestProposalSettings.data.activeDocument,
        })
      );
    }
  }, [project?.requirement?.id, requestProposalSettings, dispatch]);

  const { data: requestResourceSummaryData } =
    useGetRequestResourceRequirementsQuery(project?.requirement?.id || "", {
      skip: !project?.requirement?.id,
    });

  useEffect(() => {
    if (requestResourceSummaryData) {
      dispatch(setCurrentResourceSummary(requestResourceSummaryData.data));
    }
  }, [requestResourceSummaryData, dispatch]);

  const { data: quoteRequestData } = useGetQuoteQuery(
    project?.requirement?.id || "",
    {
      skip: !project?.requirement?.id,
    }
  );

  useEffect(() => {
    if (quoteRequestData) {
      dispatch(
        setCurrentQuote({
          requirementId: project?.requirement?.id!,
          quote: quoteRequestData.data,
        })
      );
    }
  }, [project?.requirement?.id, quoteRequestData, dispatch]);

  const { data: requestProjectData } = useGetProjectByIdQuery(projectId!, {
    skip: projectId == null,
  });

  const resourceSummary = useSelector<
    RootState,
    RequiredResourcesSummary | null
  >((state) => {
    return state.projectData.currentResourceSummary;
  });

  useEffect(() => {
    if (requestProjectData) {
      dispatch(setCurrentProject(requestProjectData.data));
    }
  }, [requestProjectData, dispatch]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
    },
  });

  const getQuoteStatus = (requirement: Requirement) => {
    if (requirement.isQuoteApproved) {
      return "approved";
    }
    if (
      requirement.isWorkBreakdownApproved &&
      requirement.isResourcesApproved
    ) {
      return "in_approval";
    }

    return "not_started";
  };

  const getProposalStatus = (requirement: Requirement) => {
    if (
      requirement.isWorkBreakdownApproved &&
      requirement.isResourcesApproved &&
      requirement.isQuoteApproved
    ) {
      return "available";
    }

    return "not_started";
  };

  const getWorkBreakDownStatus = (requirement: Requirement) => {
    if (requirement.isWorkBreakdownApproved) {
      return "approved";
    }
    if (requirement.isScopeApproved) {
      return "in_approval";
    }
    return "not_started";
  };

  const getResourceStatus = (requirement: Requirement) => {
    if (requirement.isResourcesApproved) {
      return "approved";
    }
    if (requirement.isWorkBreakdownApproved) {
      return "in_approval";
    }
    return "not_started";
  };

  const trackLinkClick = (to: string) => {
    trackUser("Dashboard Card Click", {
      to,
    });
  };

  if (isChildRoute) {
    return <Outlet />;
  }
  return (
    <div>
      <div className="p-4 mt-2">
        <div className="flex w-full mb-6">
          <h1 className="grow cursor-pointer">{project?.title}</h1>

          <button onClick={toggleDrawer} className="button-secondary">
            <img src="/icons/edit.png" className="w-5 h-5" alt="" />
            Edit Project
          </button>
          <NewOpportunity
            isOpen={isOpen}
            toggleDrawer={toggleDrawer}
            project={project}
          />
        </div>

        {/******************* Estimation - Inactive State ************************/}

        <div className="">
          <div className="col-span-3 min-h-80 shadow border border-gray-200 rounded rounded-xl mb-6">
            <div className="pt-4 pl-6 pr-6 rounded rounded-xl bg-white flex flex-col">
              {project?.requirement == null && (
                <h2 className="font-semibold text-gray-900 mb-0 grow">
                  Estimation
                </h2>
              )}
              <h2 className="font-semibold text-gray-900 mb-0 grow">
                {project?.requirement && (
                  <div className="group relative text-gray-700 hover:bg-gray-50 even:bg-gray-10">
                    Estimation - {project.requirement.fileName}
                    {showDeleteRequiremenFileConfirmation ? (
                      <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
                        <span className="text-sm mr-2">Are you sure?</span>
                        <AiOutlineCheck
                          className="hover:text-green-500 cursor-pointer"
                          onClick={() =>
                            handleConfirmDeleteRequirementFile(
                              project.requirement!.id
                            )
                          }
                          size={20}
                        />
                        <AiOutlineClose
                          className="hover:text-red-500 cursor-pointer"
                          onClick={() => handleCancelDeleteRequirementFile()}
                          size={20}
                        />
                      </div>
                    ) : (
                      <AiOutlineDelete
                        className="cursor-pointer hover:text-red-500 absolute right-4 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100"
                        onClick={() => handleDeleteRequirementFileClick()}
                      />
                    )}
                  </div>
                )}
              </h2>
              {project?.requirement == null && (
                <div className="mt-4 mb-6 w-full rounded rounded-md bg-gray-50">
                  <div {...getRootProps()} className="upload min-h-52">
                    <input {...getInputProps()} />
                    <div className="text-sm text-center">
                      <div className="p-2 my-2 shadow bg-white inline-flex items-center border border-gray-200 rounded rounded-md">
                        <img
                          src="/icons/upload.png"
                          className="w-5 h-5"
                          alt="upload"
                        />
                      </div>
                      <br />
                      <span className="text-brand underline font-bold">
                        Click to upload
                      </span>
                      &nbsp;or drag & drop the Requirements, <br /> Supported
                      formats: DOCX, PDF
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/******************* Estimation - Active State ************************/}

            {project?.requirement && (
              <div className="grid grid-cols-3 gap-6 pt-4 pl-6 pr-6 rounded rounded-xl bg-white">
                <Link
                  className="text-brand font-bold grid-item"
                  to={`scoping/${project?.requirement.id}`}
                  onClick={() => trackLinkClick("Scope")}
                >
                  <div className="border border-gray-200 rounded rounded-xl">
                    <div className="rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full">
                      <div className="pt-4 pl-6 pr-6 grow">
                        <h3 className="font-bold text-gray-700 mb-0">Scope</h3>
                        <div className="flex justify-between items-center my-8">
                          <div className="text-gray-700 text-4xl font-bold">
                            <div className="flex flex-col">
                              <span>
                                {project.requirement.isScopeApproved
                                  ? "Clear"
                                  : ""}
                                &nbsp;
                              </span>
                              <span className="text-sm text-gray-400">
                                &nbsp;
                              </span>
                            </div>
                          </div>
                          <StatusFlag
                            status={
                              project.requirement.isScopeApproved
                                ? "approved"
                                : "in_approval"
                            }
                          />
                        </div>
                      </div>
                      <div className="py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0">
                        <img src="/icons/view.png" className="w-5" alt="" />
                        <span>View</span>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link
                  className={`text-brand font-bold grid-item ${
                    getWorkBreakDownStatus(project.requirement) ===
                    "not_started"
                      ? "text-gray-400 cursor-not-allowed"
                      : ""
                  }`}
                  to={
                    getWorkBreakDownStatus(project.requirement) !==
                    "not_started"
                      ? `work-breakdown-structure/${project.requirement.id}`
                      : location.pathname
                  }
                  onClick={() => trackLinkClick("WBS")}
                >
                  <div className="border border-gray-200 rounded rounded-xl">
                    <div
                      className={`rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full ${
                        getWorkBreakDownStatus(project.requirement) ===
                        "not_started"
                          ? "hover:bg-white"
                          : ""
                      }`}
                    >
                      <div className="pt-4 pl-6 pr-6 grow">
                        <h3 className="font-bold text-gray-700 mb-0">
                          Work Breakdown Structure
                        </h3>
                        <div className="flex justify-between items-center my-8">
                          <div className="text-gray-700 text-4xl font-bold">
                            <div className="flex flex-col">
                              <span>
                                {getWorkBreakDownStatus(project.requirement) ===
                                "approved"
                                  ? "100%"
                                  : "0%"}
                              </span>
                              <span className="text-sm text-gray-400">
                                &nbsp;
                              </span>
                            </div>
                          </div>
                          <StatusFlag
                            status={getWorkBreakDownStatus(project.requirement)}
                          />
                        </div>
                      </div>
                      <div
                        className={`py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0 ${
                          getWorkBreakDownStatus(project.requirement) ===
                          "not_started"
                            ? "text-gray-400 opacity-50"
                            : ""
                        }`}
                      >
                        <img
                          src="/icons/view.png"
                          className={`w-5 ${
                            getWorkBreakDownStatus(project.requirement) ===
                            "not_started"
                              ? "opacity-30"
                              : ""
                          } `}
                          alt=""
                        />
                        <span>View</span>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link
                  className={`text-brand font-bold grid-item ${
                    getResourceStatus(project.requirement) === "not_started"
                      ? "text-gray-400 cursor-not-allowed"
                      : ""
                  }`}
                  to={
                    getResourceStatus(project.requirement) !== "not_started"
                      ? `resource-planning/${project.requirement.id}`
                      : location.pathname
                  }
                  onClick={() => trackLinkClick("Resources")}
                >
                  <div className="border border-gray-200 rounded rounded-xl">
                    <div
                      className={`rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full ${
                        getResourceStatus(project.requirement) === "not_started"
                          ? "hover:bg-white"
                          : ""
                      }`}
                    >
                      <div className="pt-4 pl-6 pr-6 grow">
                        <h3 className="font-bold text-gray-700 mb-0">
                          Project Plan Overview
                        </h3>
                        <div className="flex justify-between items-center my-8">
                          <div className="flex flex-col">
                            <span className="flex flex-col text-gray-700 text-4xl font-bold">
                              {getResourceStatus(project.requirement) !==
                              "not_started" ? (
                                <>
                                  <span>
                                    {resourceSummary?.summary.reduce(
                                      (acc, { effort }) => acc + +effort,
                                      0
                                    )}{" "}
                                    h
                                  </span>
                                  <span className="text-sm text-gray-400">
                                    Total effort
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span>&nbsp;</span>
                                  <span className="text-sm text-gray-400">
                                    &nbsp;
                                  </span>
                                </>
                              )}
                            </span>
                          </div>

                          <StatusFlag
                            status={getResourceStatus(project.requirement)}
                          />
                        </div>
                      </div>
                      <div
                        className={`py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0 ${
                          getResourceStatus(project.requirement) ===
                          "not_started"
                            ? "text-gray-400 opacity-50"
                            : ""
                        }`}
                      >
                        <img
                          src="/icons/view.png"
                          className={`w-5 ${
                            getResourceStatus(project.requirement) ===
                            "not_started"
                              ? "opacity-30"
                              : ""
                          } `}
                          alt=""
                        />
                        <span>View</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>

          {/******************* Quote ************************/}

          {project?.requirement && (
            <div className="grid grid-cols-3 gap-6 w-full p-6 shadow border border-gray-200 rounded rounded-xl bg-brandYellow">
              <Link
                className={`text-brand font-bold ${
                  getQuoteStatus(project.requirement) === "not_started"
                    ? "text-gray-400 cursor-not-allowed"
                    : ""
                }`}
                to={
                  getQuoteStatus(project.requirement) !== "not_started"
                    ? `quote/${project.requirement.id}`
                    : location.pathname
                }
                onClick={() => trackLinkClick("Quote")}
              >
                <div className="border border-gray-200 rounded rounded-xl">
                  <div
                    className={`rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full ${
                      getQuoteStatus(project.requirement) === "not_started"
                        ? "hover:bg-white"
                        : ""
                    }`}
                  >
                    <div className="pt-4 pl-6 pr-6 grow">
                      <h3 className="font-bold text-gray-700 mb-0">Quote</h3>
                      <p className="text-sm font-normal text-gray-500 my-2">
                        View quote, adjust margins and discounts
                      </p>
                      <div className="flex justify-between items-center my-8">
                        {getQuoteStatus(project.requirement) !==
                        "not_started" ? (
                          <div className="flex flex-col">
                            <span className="text-gray-700 text-4xl font-bold">
                              {currentQuote &&
                              currentQuote.quote &&
                              currentQuote?.quote?.quoteTotals[0] &&
                              currentQuote?.quote?.quoteTotals[0]
                                .netMarginAmount
                                ? "$" +
                                  formatNumber(
                                    currentQuote?.quote?.quoteTotals[0]
                                      .netMarginAmount
                                  )
                                : "0"}
                            </span>
                            <span className="text-sm text-gray-400">
                              Net margin
                            </span>
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            <span className="text-gray-700 text-4xl font-bold">
                              &nbsp;
                            </span>
                            <span className="text-sm text-gray-400">
                              &nbsp;
                            </span>
                          </div>
                        )}

                        <StatusFlag
                          status={getQuoteStatus(project.requirement)}
                        />
                      </div>
                    </div>
                    <div
                      className={`py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0 ${
                        getResourceStatus(project.requirement) ===
                          "not_started" ||
                        getResourceStatus(project.requirement) === "in_approval"
                          ? "text-gray-400 opacity-50"
                          : ""
                      }`}
                    >
                      <img
                        src="/icons/view.png"
                        className={`w-5 ${
                          getResourceStatus(project.requirement) ===
                            "not_started" ||
                          getResourceStatus(project.requirement) ===
                            "in_approval"
                            ? "opacity-30"
                            : ""
                        } `}
                        alt=""
                      />
                      <span>View</span>
                    </div>
                  </div>
                </div>
              </Link>
              {/******************* Proposal ************************/}

              <Link
                className={`text-brand font-bold ${
                  getProposalStatus(project.requirement) === "not_started"
                    ? "text-gray-400 cursor-not-allowed"
                    : ""
                }`}
                to={
                  getProposalStatus(project.requirement) !== "not_started"
                    ? `proposal/${project.requirement.id}`
                    : location.pathname
                }
                onClick={() =>
                  trackLinkClick("Proposal from dashboard clicked")
                }
              >
                <div className="border border-gray-200 rounded rounded-xl">
                  <div
                    className={`rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full ${
                      getQuoteStatus(project.requirement) === "not_started" ||
                      getQuoteStatus(project.requirement) === "in_approval"
                        ? "hover:bg-white"
                        : ""
                    }`}
                  >
                    <div className="pt-4 pl-6 pr-6 grow">
                      <h3 className="font-bold text-gray-700 mb-0">Proposal</h3>
                      <p className="text-sm font-normal text-gray-500 my-2">
                        Generate your proposal
                      </p>
                      <div className="flex justify-between items-center my-8">
                        <div className="flex flex-col">
                          <span className="text-gray-700 text-4xl font-bold">
                            {proposalSettings?.activeDocument?.sections.filter(
                              (section) => section.selected
                            ).length
                              ? proposalSettings?.activeDocument?.sections.filter(
                                  (section) => section.selected
                                ).length
                              : ""}
                            &nbsp;
                          </span>
                          {getProposalStatus(project.requirement) ===
                            "available" &&
                          proposalSettings?.activeDocument?.sections.filter(
                            (section) => section.selected
                          ).length ? (
                            <span className="text-sm text-gray-400">
                              section(s) added
                            </span>
                          ) : (
                            <span className="text-sm text-gray-400">
                              &nbsp;
                            </span>
                          )}
                        </div>
                        <StatusFlag
                          status={getProposalStatus(project.requirement)}
                        />
                      </div>
                    </div>
                    <div
                      className={`py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0 ${
                        getQuoteStatus(project.requirement) === "not_started" ||
                        getQuoteStatus(project.requirement) === "in_approval"
                          ? "text-gray-400 opacity-50"
                          : ""
                      }`}
                    >
                      <img
                        src="/icons/view.png"
                        className={`w-5 ${
                          getQuoteStatus(project.requirement) ===
                            "not_started" ||
                          getQuoteStatus(project.requirement) === "in_approval"
                            ? "opacity-30"
                            : ""
                        } `}
                        alt=""
                      />
                      <span>View</span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
