export enum SupportedStatus {
  YES = "YES",
  NO = "NO",
  NO_INFO = "NO_INFO",
  PARTIALLY = "PARTIALLY",
}

export interface AnswerContext {
  id: string;
  faitfulnessFeedback: string;
  fileName: string;
}

export interface CapabilityCheck {
  id: string;
  identifier: string;
  rank: number;
  description: string;
  reasoning: string;
  supported: SupportedStatus;
  approved: boolean;
  processingStatus: string;
  isModified: boolean;
  responseContexts: AnswerContext[];
}

export interface Questionnaire {
  id: string;
  title: string;
  data?: string;
  completion: number;
  score: number;
}
