import React, { useEffect } from "react";
import {
  useGetCompanyQuery,
  useCreateCompanyMutation,
} from "../../store/company/companyApiService";
import { toast } from "react-toastify";
import { Company } from "../../store/company/types";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyData } from "../../store/company/companyDataSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import authBg from "../../images/auth-bg.png";
import { trackUser } from "../../utils";

const validationSchema = Yup.object({
  name: Yup.string().required("* Company name is required"),
  contactEmail: Yup.string()
    .email("* Invalid email format")
    .required("* Contact Email is required"),
  contactPhone: Yup.string().required("* Contact Phone is required"),
});

export const AboutCompanyForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const company = useSelector<RootState, Company | null>(
    (state) => state.companyData.company
  );
  const { data } = useGetCompanyQuery();
  const [updateCompanyData] = useCreateCompanyMutation();

  useEffect(() => {
    if (data) {
      dispatch(setCompanyData(data));
    }
  }, [data, dispatch]);

  const formik = useFormik({
    initialValues: {
      name: company?.name || "",
      contactEmail: company?.contactEmail || "",
      contactPhone: company?.contactPhone || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateCompanyData({
        id: company?.id!,
        isOnboarded: true,
        pricingPlan: localStorage.getItem("pricingPlan") || "",
        ...values,
      })
        .unwrap()
        .then((data) => {
          dispatch(setCompanyData(data));
          trackUser("Company Created", { company: data?.name });
          navigate("/home");
        })
        .catch((err) => {
          toast.error(err?.data?.message || "Error!");
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex w-full h-screen items-start">
        <div className="flex w-1/2 justify-center items-start p-16 bg-white">
          <div className="flex flex-col items-center w-2/3 ">
            <div className="flex flex-col items-center w-full mb-10">
              <img src="/images/logo.png" alt="logo" />
              <div className="text-center text-2xl font-medium leading-tight text-gray-900">
                Welcome to EstiWiz
              </div>
            </div>
            <div className="flex flex-col items-start gap-1 w-full">
              <label className="text-gray-700">
                What is your company’s name?
              </label>
              <input
                type="text"
                name="name"
                className="input-text w-full"
                value={formik.values.name}
                onChange={formik.handleChange}
              />

              <div className="text-red-500 text-sm text-right w-full">
                {formik.touched.name && formik.errors.name ? (
                  formik.errors.name
                ) : (
                  <span className="m-0 p-0">&nbsp;</span>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start gap-1 w-full">
              <label className="text-gray-700">Contact Email</label>
              <input
                type="email"
                name="contactEmail"
                className="input-text w-full"
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
              />

              <div className="text-red-500 text-sm text-right w-full">
                {formik.touched.contactEmail && formik.errors.contactEmail ? (
                  formik.errors.contactEmail
                ) : (
                  <span className="m-0 p-0">&nbsp;</span>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start gap-1 w-full">
              <label className="text-gray-700">Contact phone</label>
              <input
                type="text"
                name="contactPhone"
                className="input-text w-full"
                value={formik.values.contactPhone}
                onChange={formik.handleChange}
              />

              <div className="text-red-500 text-sm text-right w-full">
                {formik.touched.contactPhone && formik.errors.contactPhone ? (
                  formik.errors.contactPhone
                ) : (
                  <span className="m-0 p-0">&nbsp;</span>
                )}
              </div>
            </div>
            <button type="submit" className="button-primary w-full mt-6">
              Next
            </button>
          </div>
        </div>
        <div
          className="flex w-1/2 h-screen p-20 items-center justify-center bg-brandSubtle border-l border-gray-200 shadow"
          style={{
            backgroundImage: `url(${authBg})`,
            backgroundPosition: "center",
            backgroundPositionY: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <img
            src="/images/onboarding-step-1.png"
            className="w-[407px] h-[390px]"
            alt="Welcome to EstiWiz"
          />
        </div>
      </div>
    </form>
  );
};
