import mixpanel from "mixpanel-browser";
import { store } from "../store";

export const getAccessToken = () => store.getState().authData.accessToken;

export function getMixpanelAdditionalData(
  obj: Record<string, unknown>
): Record<string, unknown> {
  return {
    host: window.location.host,
    ...obj,
  };
}

export function trackUser(event: string, obj?: Record<string, unknown>): void {
  try {
    if ((mixpanel as any).__loaded) {
      mixpanel.track(event, getMixpanelAdditionalData(obj || {}));
    }
  } catch (e) {
    
  }
}
