import React, { useState } from "react";
import classNames from "classnames";
import { count } from "console";

interface Tab {
  label: string;
  content: React.ReactNode;
  count?: number;
}

interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <div>
      <div className="flex w-full justify-center mb-4 gap-4">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={classNames(
              "px-4 py-1 -mb-px text-[1.1rem] font-semibold text-center flex items-center gap-2",
              {
                "bg-brandSubtle rounded-xl text-brand border border-brandHover":
                  activeTab === index,
                "border-transparent text-gray-500 hover:text-brand hover:border-brand":
                  activeTab !== index,
              }
            )}
          >
            {tab.label}
            {tab.count && tab.count > 0 ? (
              <span className="bg-gray-200 text-gray-900 text-sm font-semibold rounded-full px-2 py-0.5">
                {tab.count}
              </span>
            ) : null}
          </button>
        ))}
      </div>
      <div className="">{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
