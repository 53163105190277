import React, { useState, useRef, useEffect } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Company } from "../store/company/types";
import { Project } from "../store/project/types";
import { trackUser } from "../utils";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";

const MainNav: React.FC = () => {
  const { user, logout } = useAuth0();
  const { projectId } = useParams();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const settingsRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    trackUser("Logout Click", { userId: user?.sub, projectId });
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const company = useSelector<RootState, Company | null>(
    (state) => state.companyData.company
  );

  const location = useLocation();

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  useEffect(() => {
    const pathnamesToExpand = [
      `/dashboard/${project?.id}/work-breakdown-structure/${project?.requirement?.id}`,
      `/dashboard/${project?.id}/scoping/${project?.requirement?.id}`,
      `/dashboard/${project?.id}/resource-planning/${project?.requirement?.id}`,
      `/dashboard/${project?.id}/quote/${project?.requirement?.id}`,
      `/dashboard/${project?.id}/proposal/${project?.requirement?.id}`,
    ];

    if (pathnamesToExpand.includes(location.pathname)) {
      setIsSettingsOpen(false);
    }
  }, [location.pathname, project]);

  const handleLinkClick = (to: string) => {
    trackUser("Main Navigation Click", {
      to,
    });
  };

  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    setIsSettingsOpen(true);
  };

  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setIsSettingsOpen(false);
    }, 500);
    setTimeoutId(id);
  };

  useEffect(() => {
    if (settingsRef.current) {
      settingsRef.current.style.maxHeight = isSettingsOpen
        ? `${settingsRef.current.scrollHeight}px`
        : "0px";
    }
  }, [isSettingsOpen]);

  return (
    <div className="container fixed flex flex-col px-4 py-6 w-72 bg-brandSubtle h-full border-r border-gray-200 shadow">
      <NavLink to="/" onClick={() => handleLinkClick("Logo Home")}>
        <img src="/images/main-logo.png" className="h-8 ml-1 mb-6" alt="home" />
      </NavLink>

      <nav className="nav flex flex-col gap-y-2 grow relative">
        <NavLink
          to="/home"
          onClick={() => handleLinkClick("Opportunities")}
          className={
            "flex items-center gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md"
          }
        >
          <img className="w-6" src="/icons/opportunities.png" alt="" />
          <span>Opportunities</span>
        </NavLink>

        {project && (
          <>
            <NavLink
              to={`/dashboard/${project?.id}`}
              onClick={() => handleLinkClick("Dashboard")}
              className={
                "flex items-center gap-x-2 py-3 px-3 text-gray-700 bg-transparent border border-transparent rounded-md max-w-[260px] inline-block align-bottom"
              }
            >
              <img className="w-8" src="/icons/dashboard.png" alt="" />
              <span className="truncate grow flex flex-col" style={{ lineHeight: '18px' }}>
                <span className="text-[11px] text-brand truncate">{project?.title}</span>
                <span>Opportunity cockpit</span>
              </span>
            </NavLink>
            <div className="w-full flex flex-col items-end">
              <NavLink
                to={`/dashboard/${project?.id}/scoping/${project?.requirement?.id}`}
                onClick={() => handleLinkClick("Scope")}
                className={`flex w-56 items-center self-end gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md ${
                  !project?.requirement ? "opacity-50 pointer-events-none" : ""
                }`}
              >
                <img className="w-6" src="/icons/scope.png" alt="" />
                <span>Scope</span>
              </NavLink>
              <NavLink
                to={`/dashboard/${project?.id}/work-breakdown-structure/${project?.requirement?.id}`}
                onClick={() => handleLinkClick("WBS")}
                className={`flex w-56 items-center self-end gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md ${
                  !project?.requirement?.isScopeApproved
                    ? "opacity-50 pointer-events-none"
                    : ""
                }`}
              >
                <img className="w-6" src="/icons/wbs.png" alt="" />
                <span>Work breakdown</span>
              </NavLink>
              <NavLink
                to={`/dashboard/${project?.id}/resource-planning/${project?.requirement?.id}`}
                onClick={() => handleLinkClick("Resources")}
                className={`flex w-56 items-center self-end gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md ${
                  !project?.requirement?.isWorkBreakdownApproved
                    ? "opacity-50 pointer-events-none"
                    : ""
                }`}
              >
                <img className="w-6" src="/icons/user-nav.png" alt="" />
                <span>Resources</span>
              </NavLink>
              <NavLink
                to={`/dashboard/${project?.id}/quote/${project?.requirement?.id}`}
                onClick={() => handleLinkClick("Quote")}
                className={`flex w-56 items-center self-end gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md ${
                  !project?.requirement?.isResourcesApproved
                    ? "opacity-50 pointer-events-none"
                    : ""
                }`}
              >
                <img className="w-6" src="/icons/quote-nav.png" alt="" />
                <span>Quote</span>
              </NavLink>
              <NavLink
                to={`/dashboard/${project?.id}/proposal/${project?.requirement?.id}`}
                onClick={() => handleLinkClick("Proposal")}
                className={`flex w-56 items-center self-end gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md ${
                  !project?.requirement?.isQuoteApproved
                    ? "opacity-50 pointer-events-none"
                    : ""
                }`}
              >
                <img className="w-6" src="/icons/proposal.png" alt="" />
                <span>Proposal</span>
              </NavLink>
            </div>
          </>
        )}
        <NavLink
          to={`/questionnaires`}
          onClick={() => handleLinkClick("Questionnaires")}
          className={
            "flex items-center gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md"
          }
        >
          <img className="w-6" src="/icons/projects.png" alt="" />
          <span>Questionnaires</span>
        </NavLink>
        <NavLink
          to="questionnaires/library"
          onClick={() => handleLinkClick("KnowledgeBase")}
          className={
            "flex w-56 items-center self-end gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md"
          }
        >
          <img className="w-6" src="/icons/your-docs.png" alt="" />
          <span>Our Capabilities</span>
        </NavLink>

        <div className="absolute bottom-3 left-0 flex flex-col gap-4 w-full">
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="relative flex items-center gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md cursor-pointer"
          >
            <div className="flex w-full items-center gap-x-2 py-3 text-gray-700 border border-transparent rounded-md">
              <img className="w-6" src="/icons/settings.png" alt="" />
              <span className="grow">Settings</span>
              <span className="p-1 bg-brandSubtle text-brand rounded rounded-md self-right">
                <AiOutlineRight className="ml-auto" />
              </span>
            </div>

            {/* Popup menu */}
            <div
              ref={settingsRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`absolute bottom-10 w-full bg-white shadow-lg border border-gray-200 rounded-md h-auto p-4 ${
                isSettingsOpen ? "block" : "hidden"
              }`}
            >
              <NavLink
                to="/settings/company"
                onClick={() => handleLinkClick("Managing company")}
                className="flex items-center gap-x-2 px-3 py-2 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>Company</span>
              </NavLink>
              <NavLink
                to="/settings/staff-rates"
                onClick={() => handleLinkClick("Staff Rates")}
                className="flex items-center gap-x-2 px-3 py-2 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>Staff Rates</span>
              </NavLink>
              <NavLink
                to="/settings/user-management"
                onClick={() => handleLinkClick("User Management")}
                className="flex items-center gap-x-2 px-3 py-2 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>User Management</span>
              </NavLink>
              <NavLink
                to="/settings/branding"
                onClick={() => handleLinkClick("Branding")}
                className="flex items-center gap-x-2 px-3 py-2 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>Branding</span>
              </NavLink>
              <NavLink
                to="/settings/billing"
                onClick={() => handleLinkClick("Billing")}
                className="flex items-center gap-x-2 px-3 py-2 mb-4 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>Billing</span>
              </NavLink>
              <div className="h-1">
                
              </div>
            </div>
          </div>
          {/* <a
            href="mailto:support@estiwiz.com"
            className="flex items-center gap-2 text-brand"
          >
            <img src="/icons/support.png" className="w-6" alt="" />
            <span className="text-brand">Need support?</span>
          </a> */}
        </div>
      </nav>

      <div className="flex items-center pt-6 border-t border-gray-200">
        <NavLink
          to="/my-profile"
          onClick={() => handleLinkClick("My Profile")}
          className={"flex grow group gap-x-4"}
        >
          <div className="flex items-center p-2 border border-brand rounded-full group-hover:border-brandHover">
            <img className="w-6" src="/icons/user.png" alt="" />
          </div>

          <div className="flex items-start flex-col text-base">
            <span className="text-md text-gray-800">{company?.name}</span>
            <span className="text-sm text-gray-600">
              User: {user?.nickname}
            </span>
          </div>
        </NavLink>

        <span className="group relative">
          <div className="absolute bottom-[calc(100%+0.5rem)] left-[50%] -translate-x-[50%] hidden group-hover:block w-auto">
            <div className="bottom-full right-0 rounded bg-gray-600 w-24 px-4 py-1 text-xs text-white">
              Logout
            </div>
          </div>
          <span>
            <abbr className="text-xl font-bold cursor-pointer">
              <img
                className="w-6 hover:cursor-pointer"
                src="/icons/logout.png"
                alt="Logout"
                onClick={handleLogout}
              />
            </abbr>
          </span>
        </span>
      </div>
    </div>
  );
};

export default MainNav;
