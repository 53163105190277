import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  AiOutlineDelete,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineArrowLeft,
  AiOutlineReload,
} from "react-icons/ai"; // Added AiOutlineReload for re-generate button
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { RootState } from "../../store";
import {
  useGetQuestionsAndAssumptionsQuery,
  useDeleteQuestionMutation,
  useUpdateQuestionMutation,
  useDeleteAssumptionMutation,
  useDeletePrerequisiteMutation,
  useUpdateAssumptionMutation,
  useUpdatePrerequisiteMutation,
  useCreateAssumptionMutation,
  useCreatePrerequisiteMutation,
  useCreateQuestionMutation,
  useRegenerateScopeMutation,
  projectApiUtils,
} from "../../store/project/projectApiService";
import {
  CurrentQuestionsAndAssumptions,
  setCurrentQuestionsAndAssumptions,
} from "../../store/project/projectSlice";
import { TableLoader } from "../utils/ContentLoader";
import { trackUser } from "../../utils";
import { create, debounce } from "lodash";
import { toast } from "react-toastify";
import Tabs from "../utils/Tabs";
import AutoResizeTextarea from "../utils/AutoResizeTextArea";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Project,
  RequirementAssumption,
  RequirementPrerequisite,
  RequirementQuestion,
} from "../../store/project/types";
import { Tooltip } from "react-tooltip";
import ConfirmModal from "../utils/ConfirmModal";

const ViewOpenQuestions = () => {
  const dispatch = useDispatch();
  const intervalRef = useRef<null | number | NodeJS.Timeout>(null);
  const { requirementId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const currentQuestionsAndAssumptions = useSelector<
    RootState,
    CurrentQuestionsAndAssumptions | null
  >((state) => state.projectData.currentQuestionsAndAssumptions);

  const { data: requestQuestionsAndAssumptions } =
    useGetQuestionsAndAssumptionsQuery(requirementId!, {
      skip: requirementId == null,
    });

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  useEffect(() => {
    if (requestQuestionsAndAssumptions) {
      dispatch(
        setCurrentQuestionsAndAssumptions({
          requirementId: requirementId!,
          ...requestQuestionsAndAssumptions.data,
        })
      );
      if (
        !intervalRef.current &&
        (!requestQuestionsAndAssumptions.data.isQuestionsGenerated ||
          !requestQuestionsAndAssumptions.data.isAssumptionsGenerated ||
          !requestQuestionsAndAssumptions.data.isPrerequisiteGenerated)
      ) {
        // Start the interval to invalidate the state every 5 seconds
        intervalRef.current = setInterval(() => {
          dispatch(
            projectApiUtils.invalidateTags([
              { type: "QuestionsAndAssumptions", id: requirementId },
            ])
          );
        }, 5000);
      }
      if (
        intervalRef.current &&
        requestQuestionsAndAssumptions.data.isQuestionsGenerated &&
        requestQuestionsAndAssumptions.data.isAssumptionsGenerated &&
        requestQuestionsAndAssumptions.data.isPrerequisiteGenerated
      ) {
        clearInterval(intervalRef.current as NodeJS.Timeout);
        intervalRef.current = null;
      }

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };
    }
  }, [requirementId, requestQuestionsAndAssumptions, dispatch]);

  const [deleteQuestion] = useDeleteQuestionMutation();
  const [deleteAssumption] = useDeleteAssumptionMutation();
  const [deletePrerequisite] = useDeletePrerequisiteMutation();
  const [createQuestion] = useCreateQuestionMutation();
  const [updateQuestion] = useUpdateQuestionMutation();
  const [createAssumption] = useCreateAssumptionMutation();
  const [updateAssumption] = useUpdateAssumptionMutation();
  const [createPrerequisite] = useCreatePrerequisiteMutation();
  const [updatePrerequisite] = useUpdatePrerequisiteMutation();
  const [regenerateScope] = useRegenerateScopeMutation();

  const [deletingId, setDeletingId] = useState<string>("");
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingContent, setEditingContent] = useState<string>("");
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const [answer, setAnswer] = useState<{ [key: string]: string }>({});
  const [showRegenerateScope, setShowRegenerateScope] =
    useState<boolean>(false);

  const inputAssumptionRef = useRef<HTMLTextAreaElement>(null);
  const inputPrerequisiteRef = useRef<HTMLTextAreaElement>(null);
  const inputQuestionRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (editingId && inputAssumptionRef.current) {
      inputAssumptionRef.current.focus();
      inputAssumptionRef.current.select();
    }
    if (editingId && inputPrerequisiteRef.current) {
      inputPrerequisiteRef.current.focus();
      inputPrerequisiteRef.current.select();
    }
    if (editingId && inputQuestionRef.current) {
      inputQuestionRef.current.focus();
      inputQuestionRef.current.select();
    }
  }, [editingId]);

  const handleDelete = (id: string) => {
    setDeletingId(id);
  };

  const handleEdit = (id: string, content: string) => {
    setEditingId(id);
    setEditingContent(content);
  };

  const resetEditMode = () => {
    setEditingId(null);
    setEditingContent("");
  };

  const handleConfirmDelete = async (type: string, id: string) => {
    try {
      if (type === "question") {
        await deleteQuestion(id).unwrap();
      } else if (type === "assumption") {
        await deleteAssumption(id).unwrap();
      } else if (type === "prerequisite") {
        await deletePrerequisite(id).unwrap();
      }
      toast.success("Successfully deleted!");
      setDeletingId("");
    } catch (err: any) {
      toast.error(err?.data?.message || "Error deleting item!");
    }
  };

  const handleCancelDelete = () => {
    setDeletingId("");
  };

  const handleSaveEdit = async (type: string, id: string) => {
    try {
      if (type === "assumption") {
        await updateAssumption({ id, description: editingContent }).unwrap();
      } else if (type === "prerequisite") {
        await updatePrerequisite({ id, description: editingContent }).unwrap();
      } else if (type === "question") {
        await updateQuestion({ id, question: editingContent }).unwrap();
      }
      toast.success("Successfully updated!");
      resetEditMode();
    } catch (err: any) {
      toast.error(err?.data?.message || "Error updating item!");
    }
  };

  const createNewAssumption = () => {
    trackUser("Create Assumption");
    const newAssumption: Partial<RequirementAssumption> = {
      id: "",
      description: "[New Assumption]",
    };
    createAssumption({
      requirementId: requirementId!,
      data: newAssumption,
    })
      .unwrap()
      .then((result) => {
        const createdItem = result.data.find(
          (item: RequirementAssumption) =>
            item.description === "[New Assumption]"
        );
        if (createdItem) {
          dispatch(
            setCurrentQuestionsAndAssumptions({
              requirementId: requirementId!,
              ...currentQuestionsAndAssumptions,
              assumptions: result.data,
            })
          );
          toast.success(`Successfully created new assumption item!`);
          // Set the newly created item in the editing state
          setEditingId(createdItem.id);
          setEditingContent(createdItem.description);
        } else {
          toast.error("Could not find the newly created item.");
        }
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error creating item!");
      });
  };

  const createNewPrerequisite = () => {
    trackUser("Create Prerequisite");
    const newPrerequisite: Partial<RequirementPrerequisite> = {
      id: "",
      description: "[New Prerequisite]",
    };
    createPrerequisite({
      requirementId: requirementId!,
      data: newPrerequisite,
    })
      .unwrap()
      .then((result) => {
        const createdItem = result.data.find(
          (item: RequirementPrerequisite) =>
            item.description === "[New Prerequisite]"
        );
        if (createdItem) {
          dispatch(
            setCurrentQuestionsAndAssumptions({
              requirementId: requirementId!,
              ...currentQuestionsAndAssumptions,
              prerequisites: result.data,
            })
          );
          toast.success(`Successfully created new prerequisite item!`);
          setEditingId(createdItem.id);
          setEditingContent(createdItem.description);
        } else {
          toast.error("Could not find the newly created item.");
        }
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error creating item!");
      });
  };

  const createNewQuestion = () => {
    trackUser("Create Question");
    const newQuestion: Partial<RequirementQuestion> = {
      id: "",
      question: "[New Question]",
    };
    createQuestion({
      requirementId: requirementId!,
      data: newQuestion,
    })
      .unwrap()
      .then((result) => {
        const createdItem = result.data.find(
          (item: RequirementQuestion) => item.question === "[New Question]"
        );
        if (createdItem) {
          dispatch(
            setCurrentQuestionsAndAssumptions({
              requirementId: requirementId!,
              ...currentQuestionsAndAssumptions,
              questions: result.data,
            })
          );
          setShowRegenerateScope(true);
          toast.success(`Successfully created new question!`);
          setEditingId(createdItem.id);
          setEditingContent(createdItem.question);
        } else {
          toast.error("Could not find the newly created item.");
        }
      })
      .catch((err: any) => {
        toast.error(
          err?.data?.message || "Error creating question! Please try later."
        );
      });
  };

  const regenerateScopeHandler = () => {
    trackUser("Re-generate Scope");
    regenerateScope(requirementId!)
      .unwrap()
      .then(() => {
        setShowRegenerateScope(false);
        toast.success("Generating scope in progress...");
        navigate(`/dashboard/${project?.id}/scoping/${requirementId}`);
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error regenerating scope!");
      });
  };

  const handleConfirmRegenerate = () => {
    regenerateScopeHandler();
    setModalVisible(false);
  };

  const handleCancelRegenerate = () => {
    setModalVisible(false);
  };

  const handleAnswerChange = async (id: string, answer: string) => {
    try {
      await updateQuestion({ id, answer }).unwrap();
      setShowRegenerateScope(true);
    } catch (err: any) {
      toast.error(err?.data?.message || "Error saving answer!");
    }
  };

  const onAnswerChange = (id: string, answer: string) => {
    setAnswer((prev) => ({ ...prev, [id]: answer }));
    handleAnswerChange(id, answer);
  };

  const handlePaste = (e: ClipboardEvent) => {
    const clipboardData = e.clipboardData?.getData("Text");
    if (clipboardData) {
      const answers = clipboardData.split("\n");
      let filled = false;
      const newAnswers: { [key: string]: string } = {};

      currentQuestionsAndAssumptions!.questions.forEach((question, index) => {
        if (index < answers.length) {
          newAnswers[question.id] = answers[index];
          filled = true;

          // Manually trigger the onAnswerChange handler
          setTimeout(() => {
            onAnswerChange(question.id, answers[index]);
          }, 0);
        }
      });

      setAnswer(newAnswers);
      setShowRegenerateScope(filled); // Show "Re-generate Scope" button if any textarea is filled
      if (filled) {
        trackUser("Paste Answers");
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [currentQuestionsAndAssumptions]);

  const loaded = () => {
    return (
      currentQuestionsAndAssumptions &&
      currentQuestionsAndAssumptions.requirementId === requirementId
    );
  };

  const copyQuestions = () => {
    const questionsText = currentQuestionsAndAssumptions!.questions
      .map((q) => q.question)
      .join("\n");
    navigator.clipboard
      .writeText(questionsText)
      .then(() => {
        setCopiedToClipboard(true);
        trackUser("Copy Questions to Clipboard", { user: user?.email || "" });
      })
      .catch((err) => {
        setCopiedToClipboard(false);
        toast.error("Error copying questions to clipboard!");
      });
  };

  const assumptionsContent = (
    <div className="container shadow border border-gray-200 mb-6 rounded rounded-xl">
      <div className="">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="text-left">
              <th className="px-6 py-5  text-xl font-semibold">Assumptions</th>
              <th className="px-6 py-2 text-right">
                {!project?.requirement?.isScopeApproved && (
                  <div>
                    <button
                      data-tooltip-id="addNew"
                      data-tooltip-content="Add New Assumption"
                      onClick={createNewAssumption}
                    >
                      <img src="/icons/add.png" className="w-6" alt="" />
                    </button>
                    <Tooltip id="addNew" />
                  </div>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {!loaded() ? (
              <tr>
                <td>
                  <TableLoader />
                </td>
              </tr>
            ) : !currentQuestionsAndAssumptions!.isAssumptionsGenerated ? (
              <tr>
                <td>
                  <div className="p-4 mt-2">Generating assumptions...</div>
                </td>
              </tr>
            ) : (
              currentQuestionsAndAssumptions!.assumptions.map((assumption) => (
                <tr key={assumption.id} className="odd:bg-gray-100 group">
                  <td className="border-b px-4 py-4">
                    {editingId === assumption.id ? (
                      <div className="relative flex gap-2">
                        <AutoResizeTextarea
                          value={editingContent}
                          onChange={(e) => setEditingContent(e.target.value)}
                          isLoading={false}
                          ref={inputAssumptionRef}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleSaveEdit("assumption", assumption.id);
                            } else if (e.key === "Escape") {
                              resetEditMode();
                            }
                          }}
                        ></AutoResizeTextarea>
                        <div className="flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                          <AiOutlineCheck
                            onClick={() =>
                              handleSaveEdit("assumption", assumption.id)
                            }
                            size={20}
                            className="hover:text-green-500 cursor-pointer"
                          />
                          <AiOutlineClose
                            onClick={resetEditMode}
                            size={20}
                            className="hover:text-red-500 cursor-pointer"
                          />
                        </div>
                      </div>
                    ) : (
                      assumption.description
                    )}
                  </td>
                  <td className="border-b px-4 py-2">
                    <div className="flex items-right justify-end space-x-4">
                      {deletingId === assumption.id ? (
                        <>
                          <span className="mr-1">Are you sure?</span>
                          <div>
                            <AiOutlineCheck
                              data-tooltip-id={`delete-${assumption.id}`}
                              data-tooltip-content="Yes"
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              onClick={() =>
                                handleConfirmDelete("assumption", assumption.id)
                              }
                              size={20}
                              title="Yes"
                            />
                            <Tooltip id={`delete-${assumption.id}`} />
                          </div>
                          <div>
                            <AiOutlineClose
                              data-tooltip-id={`delete-${assumption.id}`}
                              data-tooltip-content="No"
                              className="text-gray-500 cursor-pointer hover:text-gray-700"
                              onClick={handleCancelDelete}
                              size={20}
                              title="Cancel Delete"
                            />
                            <Tooltip id={`delete-${assumption.id}`} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <AiOutlineEdit
                              data-tooltip-id={`edit-${assumption.id}`}
                              data-tooltip-content="Edit"
                              className="hover:text-gray-500 cursor-pointer"
                              onClick={() =>
                                handleEdit(
                                  assumption.id,
                                  assumption.description
                                )
                              }
                              size={20}
                              title="Edit"
                            />
                            <Tooltip id={`edit-${assumption.id}`} />
                          </div>
                          <div>
                            <AiOutlineDelete
                              data-tooltip-id={`delete-${assumption.id}`}
                              data-tooltip-content="Delete"
                              onClick={() => handleDelete(assumption.id)}
                              className="text-red-500 cursor-pointer hover:text-red-700"
                              size={20}
                              title="Delete"
                            />
                            <Tooltip id={`delete-${assumption.id}`} />
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  const prerequisitesContent = (
    <div className="container shadow border border-gray-200 mb-6 rounded rounded-xl">
      <div className="">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="text-left">
              <th className="px-6 py-5 text-xl font-semibold">Prerequisites</th>
              <th className="px-6 py-2 text-right">
                {!project?.requirement?.isScopeApproved && (
                  <div>
                    <button
                      data-tooltip-id="addNew"
                      data-tooltip-content="Add New Prerequisites"
                      onClick={createNewPrerequisite}
                    >
                      <img src="/icons/add.png" className="w-6" alt="" />
                    </button>
                    <Tooltip id="addNew" />
                  </div>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {!loaded() ? (
              <tr>
                <td>
                  <TableLoader />
                </td>
              </tr>
            ) : !currentQuestionsAndAssumptions?.isPrerequisiteGenerated ? (
              <tr>
                <td>
                  <div className="p-4 mt-2">Generating prerequisites...</div>
                </td>
              </tr>
            ) : (
              currentQuestionsAndAssumptions!.prerequisites.map(
                (prerequisite) => (
                  <tr key={prerequisite.id} className="odd:bg-gray-100 group">
                    <td className="border-b px-4 py-4 group-first:rounded-0">
                      {editingId === prerequisite.id ? (
                        <div className="relative flex gap-2">
                          <AutoResizeTextarea
                            value={editingContent}
                            onChange={(e) => setEditingContent(e.target.value)}
                            isLoading={false}
                            ref={inputPrerequisiteRef}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSaveEdit("prerequisite", prerequisite.id);
                              } else if (e.key === "Escape") {
                                resetEditMode();
                              }
                            }}
                          ></AutoResizeTextarea>
                          <div className="flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                            <AiOutlineCheck
                              onClick={() =>
                                handleSaveEdit("prerequisite", prerequisite.id)
                              }
                              size={20}
                              className="hover:text-green-500 cursor-pointer"
                            />
                            <AiOutlineClose
                              onClick={resetEditMode}
                              size={20}
                              className="hover:text-red-500 cursor-pointer"
                            />
                          </div>
                        </div>
                      ) : (
                        prerequisite.description
                      )}
                    </td>
                    <td className="border-b px-4 py-2 text-right">
                      <div className="flex items-right justify-end w-full space-x-4">
                        {deletingId === prerequisite.id ? (
                          <>
                            <span className="mr-1">Are you sure?</span>
                            <div>
                              <AiOutlineCheck
                                data-tooltip-id={`delete-${prerequisite.id}`}
                                data-tooltip-content="Yes"
                                className="text-green-500 cursor-pointer hover:text-green-700"
                                onClick={() =>
                                  handleConfirmDelete(
                                    "prerequisite",
                                    prerequisite.id
                                  )
                                }
                                size={20}
                                title="Confirm Delete"
                              />
                              <Tooltip id={`delete-${prerequisite.id}`} />
                            </div>
                            <div>
                              <AiOutlineClose
                                data-tooltip-id={`delete-${prerequisite.id}`}
                                data-tooltip-content="No"
                                className="text-gray-500 cursor-pointer hover:text-gray-700"
                                onClick={handleCancelDelete}
                                size={20}
                                title="Cancel Delete"
                              />
                              <Tooltip id={`delete-${prerequisite.id}`} />
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <AiOutlineEdit
                                data-tooltip-id={`edit-${prerequisite.id}`}
                                data-tooltip-content="Edit Prerequisite"
                                className="hover:text-gray-500 cursor-pointer"
                                onClick={() =>
                                  handleEdit(
                                    prerequisite.id,
                                    prerequisite.description
                                  )
                                }
                                size={20}
                                title="Edit Prerequisite"
                              />
                              <Tooltip id={`edit-${prerequisite.id}`} />
                            </div>

                            <div>
                              <AiOutlineDelete
                                data-tooltip-id={`delete-${prerequisite.id}`}
                                data-tooltip-content="Delete"
                                onClick={() => handleDelete(prerequisite.id)}
                                className="text-red-500 cursor-pointer hover:text-red-700"
                                size={20}
                                title="Delete"
                              />
                              <Tooltip id={`delete-${prerequisite.id}`} />
                            </div>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  const questionsContent = (
    <div className="container shadow border border-gray-200 mb-4 rounded rounded-xl">
      <div className="">
        <div className="flex items-center justify-start gap-8 px-4 px-6">
          <div className="flex grow items-center justify-start gap-8">
            <h2 className="text-xl font-semibold py-4 mt-1">Questions</h2>
            <button
              onClick={copyQuestions}
              className="button-secondary flex text-sm items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
            >
              <img
                src="/icons/copy.png"
                className="w-5 h-5"
                alt="copy your response"
              />
              {copiedToClipboard
                ? "Questions Copied!"
                : "Copy questions to clipboard"}
            </button>
          </div>
          {!project?.requirement?.isScopeApproved && (
            <div>
              <button
                data-tooltip-id="addNew"
                data-tooltip-content="Add New Question"
                onClick={createNewQuestion}
              >
                <img src="/icons/add.png" className="w-6" alt="" />
              </button>
              <Tooltip id="addNew" />
            </div>
          )}
        </div>

        <table className="min-w-full w-full">
          <thead>
            <tr className="text-left bg-gray-100">
              <th className="px-4 py-4 w-1/3">Question</th>
              <th className="px-4 py-4 grow">Client Response</th>
              <th className="px-4 py-4 w-[40px]"></th>
            </tr>
          </thead>
          <tbody>
            {!loaded() ? (
              <tr>
                <td>
                  <TableLoader />
                </td>
              </tr>
            ) : !currentQuestionsAndAssumptions!.isQuestionsGenerated ? (
              <tr>
                <td>
                  <div className="p-4 mt-2">Generating questions...</div>
                </td>
              </tr>
            ) : (
              currentQuestionsAndAssumptions!.questions.map((question) => (
                <tr key={question.id}>
                  <td className="border-b px-4 pr-8 py-2 w-1/2">
                    {editingId === question.id ? (
                      <div className="relative flex gap-2">
                        <AutoResizeTextarea
                          value={editingContent}
                          onChange={(e) => setEditingContent(e.target.value)}
                          isLoading={false}
                          ref={inputQuestionRef}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleSaveEdit("question", question.id);
                            } else if (e.key === "Escape") {
                              resetEditMode();
                            }
                          }}
                        ></AutoResizeTextarea>
                        <div className="flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                          <AiOutlineCheck
                            onClick={() =>
                              handleSaveEdit("question", question.id)
                            }
                            size={20}
                            className="hover:text-green-500 cursor-pointer"
                          />
                          <AiOutlineClose
                            onClick={resetEditMode}
                            size={20}
                            className="hover:text-red-500 cursor-pointer"
                          />
                        </div>
                      </div>
                    ) : (
                      question.question
                    )}
                  </td>
                  <td className="border-b px-4 py-2 w-1/2">
                    <textarea
                      className="w-full h-full p-2 text-gray-900 bg-gray-100 rounded hover:bg-gray-100 focus:border focus:border-brandHover focus:outline-brand"
                      placeholder="Type your answer here..."
                      value={answer[question.id] || question.answer}
                      onChange={(e) =>
                        onAnswerChange(question.id, e.target.value)
                      }
                      data-id={question.id} // Added data-id attribute
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                    <div className="flex items-right justify-end space-x-4 min-w-16">
                      {deletingId === question.id ? (
                        <>
                          <div>
                            <AiOutlineCheck
                              data-tooltip-id={`delete-${question.id}`}
                              data-tooltip-content="Delete"
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              onClick={() =>
                                handleConfirmDelete("question", question.id)
                              }
                              size={20}
                              title="Confirm Delete"
                            />
                            <Tooltip id={`delete-${question.id}`} />
                          </div>
                          <div>
                            <AiOutlineClose
                              data-tooltip-id={`delete-${question.id}`}
                              data-tooltip-content="Cancel"
                              className="text-gray-500 cursor-pointer hover:text-gray-700"
                              onClick={handleCancelDelete}
                              size={20}
                              title="Cancel Delete"
                            />
                            <Tooltip id={`delete-${question.id}`} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <AiOutlineEdit
                              data-tooltip-id={`edit-${question.id}`}
                              data-tooltip-content="Edit"
                              className="hover:text-gray-500 cursor-pointer"
                              onClick={() =>
                                handleEdit(question.id, question.question)
                              }
                              size={20}
                              title="Edit"
                            />
                            <Tooltip id={`edit-${question.id}`} />
                          </div>
                          <div>
                            <AiOutlineDelete
                              data-tooltip-id={`delete-${question.id}`}
                              data-tooltip-content="Delete"
                              onClick={() => handleDelete(question.id)}
                              className="text-red-500 cursor-pointer hover:text-red-700"
                              size={20}
                              title="Delete"
                            />
                            <Tooltip id={`delete-${question.id}`} />
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  const countUnansweredQuestions = () => {
    return currentQuestionsAndAssumptions?.questions.filter((q) => !q.answer)
      .length;
  };

  const tabs = [
    {
      label: "Questions",
      content: questionsContent,
      count: countUnansweredQuestions(),
    },
    { label: "Assumptions", content: assumptionsContent },
    { label: "Prerequisites", content: prerequisitesContent },
  ];

  return (
    <div className="p-4 pb-8 mt-2">
      <div className="sticky top-0 pt-4 bg-white/90 z-10 flex items-start rfi-header mb-4">
        <div className="flex-grow mb-4">
          <h2 className="grow text-[1.5rem] font-semibold">
            Assumptions and Questions
          </h2>
        </div>
        <div className="flex ml-4 gap-4">
          {showRegenerateScope && !project?.requirement?.isScopeApproved ? (
            <button
              onClick={regenerateScopeHandler}
              className="button-primary flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
            >
              <AiOutlineReload
                className="cursor-pointer"
                size={20}
                title="Re-generate Scope"
              />
              Re-generate Scope
            </button>
          ) : (
            <>
              <button
                className="button-secondary flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
                onClick={() => window.history.back()}
              >
                <AiOutlineArrowLeft
                  className="cursor-pointer"
                  size={20}
                  title="Back"
                />
                Back to Scope
              </button>
            </>
          )}
        </div>
      </div>
      <Tabs tabs={tabs} />
      <ConfirmModal
        show={isModalVisible}
        question="Be aware that by regenerating the scope you need to re-approve it again and this action will start work breakdown structure from the beggining. Are you sure you want to continue?"
        onConfirm={handleConfirmRegenerate}
        onCancel={handleCancelRegenerate}
      />
    </div>
  );
};

export default ViewOpenQuestions;
