import React, { useCallback, useEffect, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { RootState } from "../store";
import { toast } from "react-toastify";
import { CapabilityType, Company, Document } from "../store/company/types";
import {
  useDeleteCompanyDocumentMutation,
  useGetCompanyDocumentsQuery,
  useGetCompanyQuery,
  useUploadDocumentMutation,
} from "../store/company/companyApiService";
import {
  setCompanyData,
  setCompanyDocuments,
} from "../store/company/companyDataSlice";
import { trackUser } from "../utils";

const CompanyDocuments = () => {
  const dispatch = useDispatch();
  const [uploadCompanyDocument] = useUploadDocumentMutation();
  const [deleteCompanyDocument] = useDeleteCompanyDocumentMutation();
  const companyDataResult = useGetCompanyQuery();

  const company = useSelector<RootState, Company | null>(
    (state) => state.companyData.company
  );

  const documents = useSelector<RootState, Document[]>(
    (state) => state.companyData.documents
  );

  const companyDocumentsResult = useGetCompanyDocumentsQuery(company!.id, {
    skip: !company?.id,
  });
  const [showingConfirmForDocId, setShowingConfirmForDocId] = useState("");

  const handleDeleteClick = (docId: string) => {
    trackUser("Knowledge Base - Delete Document", { docId });
    setShowingConfirmForDocId(docId);
  };

  const handleConfirmDelete = (docId: string) => {
    deleteCompanyDocument(docId)
      .unwrap()
      .then(() => {
        toast.success("Successfully deleted!");
        setShowingConfirmForDocId("");
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
      });
  };

  const handleCancelDelete = () => {
    setShowingConfirmForDocId("");
  };

  useEffect(() => {
    if (companyDataResult.isSuccess) {
      dispatch(setCompanyData(companyDataResult.data));
    }
  }, [companyDataResult, dispatch]);

  useEffect(() => {
    if (companyDocumentsResult.isSuccess) {
      dispatch(setCompanyDocuments(companyDocumentsResult.data));
    }
  }, [companyDocumentsResult, dispatch]);

  const handleRowClick = (id: string) => {};

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (company && company.id && acceptedFiles.length > 0) {
        uploadCompanyDocument({
          companyId: company.id,
          file: acceptedFiles[0],
          capabilityType: CapabilityType.CompanyCapability,
        })
          .unwrap()
          .then(() => {
            trackUser("Knowledge Base - Upload Document", {
              companyId: company?.id
            });
            toast.success("Successfully saved!");
          })
          .catch((err: any) => {
            toast.error(err?.data?.message || "Error!");
            trackUser("Knowledge Base - Upload Document Error", {
              companyId: company?.id,
              error: err?.data?.message,
            });
          });
      }
    },
    [company, uploadCompanyDocument]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
    },
  });
  const getProcessingStatus = (type: string) => {
    switch (type) {
      case "PENDING":
        return "Pending";
      case "PROCESSING":
        return "Processing";
      case "PROCESSED":
        return "Processed";
      case "FAILED":
        return "Failed";
      default:
        return "Pending";
    }
  };

  const getFileType = (fileName: string) => {
    const ext = fileName.split(".").pop();
    if (ext === "xlsx") {
      return "/icons/ico-xlsx.png";
    } else if (ext === "pdf") {
      return "/icons/ico-pdf.png";
    } else if (ext === "docx") {
      return "/icons/ico-docx.png";
    } else {
      return "/icons/ico-file.png";
    }
  };

  if (!company) return null;

  return (
    <div className="p-4">
      <h1 className="">Our Capabilities</h1>
      <div className="subtitle my-4">
        <h2>Upload a document</h2>
        <p className="text-[14px] text-gray-600">
          Upload your past responses, company's capabilities documents,
          including certificates, standards, and compliance materials for
          proposal compilation.
        </p>
      </div>
      <div className="my-6">
        <div {...getRootProps()} className="upload">
          <input {...getInputProps()} />
          <div className="text-sm text-center">
            <div className="p-2 my-2 shadow inline-flex items-center border border-gray-200 rounded rounded-md">
              <img src="/icons/upload.png" className="w-5 h-5" alt="upload" />
            </div>
            <br />
            <span className="text-brand underline font-bold">
              Click to upload
            </span>
            <br /> or drag & drop .xlsx, .docx or pdf file here
          </div>
        </div>
      </div>
      {documents.length > 0 && (
        <div className="doc-list-holder rounded rounded-xl shadow border border-gray-100">
          <div className="doc-list-header py-6 pl-8 rounded rounded-xl bg-white flex items-center">
            <h2 className="font-normal text-gray-900 mb-0">Documents</h2>
          </div>
          <table className="min-w-full text-[15px]">
            <thead>
              <tr>
                <th className="w-1/2 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                  Name
                </th>
                <th className="w-1/6 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                  Status
                </th>
                <th className="w-1/6 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc, index) => (
                <tr
                  key={index}
                  className="group cursor-pointer border-t border-gray-200 relative text-gray-700 hover:bg-brandSubtle hover:text-brand even:bg-gray-10"
                  onClick={() => handleRowClick(doc.id)}
                >
                  <td className="py-4 px-8 group-last:rounded-0 group-last:rounded-bl-xl">
                    <div className="flex items-center gap-4">
                      <img src={getFileType(doc.name)} className="w-8" alt="" />
                      {doc.name}
                    </div>
                  </td>
                  <td className="py-4 px-8 ">
                    {getProcessingStatus(doc.processingStatus)}
                  </td>
                  <td className="py-4 px-8 group-last:rounded-0 group-last:rounded-br-xl">
                    {showingConfirmForDocId === doc.id ? (
                      <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
                        <span className="text-sm mr-2">Are you sure?</span>
                        <AiOutlineCheck
                          className="hover:text-green-500 cursor-pointer"
                          onClick={() => handleConfirmDelete(doc.id)}
                          size={20}
                        />
                        <AiOutlineClose
                          className="hover:text-red-500 cursor-pointer"
                          onClick={() => handleCancelDelete()}
                          size={20}
                        />
                      </div>
                    ) : (
                      <AiOutlineDelete
                        className="hover:text-red-500 absolute right-4 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100"
                        onClick={() => handleDeleteClick(doc.id)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CompanyDocuments;
