import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../../utils";
import { ResourceType } from "./types";

const resourceTypeApi = createApi({
  reducerPath: "resourceTypeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/resource-type",
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  tagTypes: ["ResourceType"],
  endpoints: (builder) => ({
    getResourceTypes: builder.query<{ data: ResourceType[] }, void>({
      query: () => "/",
      providesTags: [{ type: "ResourceType" }],
    }),
    createResourceType: builder.mutation<
      { data: ResourceType },
      Partial<ResourceType>
    >({
      query: (resourceTypeData) => {
        return {
          url: "",
          method: "POST",
          body: resourceTypeData,
        };
      },
    }),
    updateResourceType: builder.mutation<ResourceType, Partial<ResourceType>>({
      query: ({ id, ...resourceTypeData }) => {
        return {
          url: `/${id}`,
          method: "PATCH",
          body: resourceTypeData,
        };
      },
      invalidatesTags: [{ type: "ResourceType" }],
    }),
    deleteResourceType: builder.mutation<null, string>({
      query: (id: string) => {
        return {
          url: `/${id}`,
          method: "DELETE",
          body: {
            id,
          },
        };
      },
      invalidatesTags: [{ type: "ResourceType" }],
    }),
  }),
});

export const {
  useGetResourceTypesQuery,
  useCreateResourceTypeMutation,
  useUpdateResourceTypeMutation,
  useDeleteResourceTypeMutation,
} = resourceTypeApi;

export const resourceTypeApiMiddleware = resourceTypeApi.middleware;

export default resourceTypeApi.reducer;
