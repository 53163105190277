import { Quote, QuoteItem } from "../../store/quote/types";
import { parseNumber } from "../utils/helperFunctions";

export const calculateDerivedFields = (
  item: QuoteItem,
  field: keyof QuoteItem,
  value: number
): Partial<QuoteItem> => {
  const updatedItem: Partial<QuoteItem> = {};

  if (field === "discountPercentage") {
    updatedItem.discountPercentage = value;
    cacluateOtherFields(updatedItem, item);
  } else if (field === "discountAmount") {
    updatedItem.discountPercentage = (value / item.listPrice) * 100;
    cacluateOtherFields(updatedItem, item);
  } else if (field === "netMarginPercentage") {
    updatedItem.netMarginAmount = (value / 100) * item.listPrice;
    updatedItem.discountAmount =
      item.listPrice - updatedItem.netMarginAmount - item.totalCost;
    updatedItem.discountPercentage =
      (updatedItem.discountAmount / item.listPrice) * 100;
    cacluateOtherFields(updatedItem, item);
  } else if (field === "netMarginAmount") {
    updatedItem.discountAmount = item.listPrice - value - item.totalCost;
    updatedItem.discountPercentage =
      (updatedItem.discountAmount / item.listPrice) * 100;
    cacluateOtherFields(updatedItem, item);
  } else if (field === "netPrice") {
    updatedItem.netMarginAmount = value - item.totalCost;
    updatedItem.discountAmount =
      item.listPrice - updatedItem.netMarginAmount - item.totalCost;
    updatedItem.discountPercentage =
      (updatedItem.discountAmount / item.listPrice) * 100;
    cacluateOtherFields(updatedItem, item);
  }

  return updatedItem;
};

export const recalculateTotals = (updatedQuote: Quote) => {
  updatedQuote.quoteItemGroups.forEach((itemGroup) => {
    itemGroup.totalHours = itemGroup.quoteItems.reduce(
      (sum, item) => sum + parseNumber(item.totalHours),
      0
    );
    itemGroup.totalCost = itemGroup.quoteItems.reduce(
      (sum, item) => sum + parseNumber(item.totalCost),
      0
    );
    itemGroup.averageHourlyRate = itemGroup.listPrice / itemGroup.totalHours;
    itemGroup.listPrice = itemGroup.quoteItems.reduce(
      (sum, item) => sum + parseNumber(item.listPrice),
      0
    );
    itemGroup.discountAmount = itemGroup.quoteItems.reduce(
      (sum, item) => sum + parseNumber(item.discountAmount),
      0
    );
    itemGroup.netMarginAmount = itemGroup.quoteItems.reduce(
      (sum, item) => sum + parseNumber(item.netMarginAmount),
      0
    );
    itemGroup.netPrice = itemGroup.quoteItems.reduce(
      (sum, item) => sum + parseNumber(item.netPrice),
      0
    );
    itemGroup.discountPercentage =
      (itemGroup.discountAmount / itemGroup.listPrice) * 100;
    itemGroup.netMarginPercentage =
      (itemGroup.netMarginAmount / itemGroup.netPrice) * 100;
  });

  updatedQuote.quoteTotals[0].totalCost = updatedQuote.quoteItemGroups.reduce(
    (sum, group) => sum + parseNumber(group.totalCost),
    0
  );
  updatedQuote.quoteTotals[0].totalHours = updatedQuote.quoteItemGroups.reduce(
    (sum, group) => sum + parseNumber(group.totalHours),
    0
  );
  updatedQuote.quoteTotals[0].listPrice = updatedQuote.quoteItemGroups.reduce(
    (sum, group) => sum + parseNumber(group.listPrice),
    0
  );
  updatedQuote.quoteTotals[0].discountAmount =
    updatedQuote.quoteItemGroups.reduce(
      (sum, group) => sum + parseNumber(group.discountAmount),
      0
    );
  updatedQuote.quoteTotals[0].netMarginAmount =
    updatedQuote.quoteItemGroups.reduce(
      (sum, group) => sum + parseNumber(group.netMarginAmount),
      0
    );
  updatedQuote.quoteTotals[0].netPrice = updatedQuote.quoteItemGroups.reduce(
    (sum, group) => sum + parseNumber(group.netPrice),
    0
  );

  updatedQuote.quoteTotals[0].discountPercentage =
    (updatedQuote.quoteTotals[0].discountAmount /
      updatedQuote.quoteTotals[0].listPrice) *
    100;

  updatedQuote.quoteTotals[0].netMarginPercentage =
    (updatedQuote.quoteTotals[0].netMarginAmount /
      updatedQuote.quoteTotals[0].netPrice) *
    100;

  updatedQuote.quoteTotals[0].vatAmount =
    (updatedQuote.quoteTotals[0].netPrice *
      updatedQuote.quoteTotals[0].vatPercentage) /
    100;

  updatedQuote.quoteTotals[0].totalAmount =
    updatedQuote.quoteTotals[0].netPrice +
    updatedQuote.quoteTotals[0].vatAmount;
};

function cacluateOtherFields(updatedItem: Partial<QuoteItem>, item: QuoteItem) {
  updatedItem.discountAmount =
    (updatedItem.discountPercentage! / 100) * item.listPrice;
  updatedItem.netMarginAmount =
    item.listPrice - updatedItem.discountAmount - item.totalCost;
  updatedItem.netMarginPercentage =
    (updatedItem.netMarginAmount / item.listPrice) * 100;
  updatedItem.netPrice = item.listPrice - updatedItem.discountAmount;
}
