import React, { useEffect } from "react";
import {
  useGetCompanyQuery,
  useCreateCompanyMutation,
} from "../../store/company/companyApiService";
import { toast } from "react-toastify";
import { Company } from "../../store/company/types";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyData } from "../../store/company/companyDataSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { trackUser } from "../../utils";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  streetAddress: Yup.string().required("Street address is required"),
  suite: Yup.string().required("Suite is required"),
  city: Yup.string().required("City is required"),
  postalCode: Yup.string().required("Postal code is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().nullable(),
  contactEmail: Yup.string()
    .email("Invalid email format")
    .required("Contact Email is required"),
  contactPhone: Yup.string().required("Contact Phone is required"),
});

export const EditCompanyForm = () => {
  const dispatch = useDispatch();

  const company = useSelector<RootState, Company | null>(
    (state) => state.companyData.company
  );
  const { data } = useGetCompanyQuery();
  const [updateCompanyData] = useCreateCompanyMutation();

  useEffect(() => {
    if (data) {
      dispatch(setCompanyData(data));
    }
  }, [data, dispatch]);

  const formik = useFormik({
    initialValues: {
      name: company?.name || "",
      streetAddress: company?.streetAddress || "",
      suite: company?.suite || "",
      city: company?.city || "",
      postalCode: company?.postalCode || "",
      country: company?.country || "",
      state: company?.state || "",
      contactEmail: company?.contactEmail || "",
      contactPhone: company?.contactPhone || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateCompanyData({
        id: company?.id!,
        isOnboarded: true,
        ...values,
      })
        .unwrap()
        .then((data) => {
          dispatch(setCompanyData(data));
          trackUser("Company Updated", { company: data?.name });
          toast.success("Company updated successfully!");
        })
        .catch((err) => {
          toast.error(err?.data?.message || "Error!");
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex w-full h-screen justify-start items-start p-4 bg-white">
        <div className="flex flex-col items-center gap-4 w-2/3 mb-8 pb-12">
          <div className="flex flex-col items-center gap-6 w-full">
            {/* <img src="/images/logo.png" alt="logo" /> */}
            <div className="text-center text-2xl font-medium leading-tight text-gray-900">
              Edit Company Details
            </div>
          </div>
          <div className="flex flex-col items-start gap-1.5 w-full">
            <label className="text-gray-700">Company Name</label>
            <input
              type="text"
              name="name"
              className="input-text w-full"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 mb-4">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="flex flex-col items-start gap-1.5 w-full">
            <label className="text-gray-700">Street address</label>
            <input
              type="text"
              name="streetAddress"
              className="input-text w-full"
              value={formik.values.streetAddress}
              onChange={formik.handleChange}
            />
            {formik.touched.streetAddress && formik.errors.streetAddress ? (
              <div className="text-red-500 mb-4">
                {formik.errors.streetAddress}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col items-start gap-1.5 w-full">
            <label className="text-gray-700">Suite</label>
            <input
              type="text"
              name="suite"
              className="input-text w-full"
              value={formik.values.suite}
              onChange={formik.handleChange}
            />
            {formik.touched.suite && formik.errors.suite ? (
              <div className="text-red-500 mb-4">{formik.errors.suite}</div>
            ) : null}
          </div>
          <div className="flex flex-col items-start gap-1.5 w-full">
            <label className="text-gray-700">City</label>
            <input
              type="text"
              name="city"
              className="input-text w-full"
              value={formik.values.city}
              onChange={formik.handleChange}
            />
            {formik.touched.city && formik.errors.city ? (
              <div className="text-red-500 mb-4">{formik.errors.city}</div>
            ) : null}
          </div>
          <div className="flex flex-col items-start gap-1.5 w-full">
            <label className="text-gray-700">Postal Code</label>
            <input
              type="text"
              name="postalCode"
              className="input-text w-full"
              value={formik.values.postalCode}
              onChange={formik.handleChange}
            />
            {formik.touched.postalCode && formik.errors.postalCode ? (
              <div className="text-red-500 mb-4">
                {formik.errors.postalCode}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col items-start gap-1.5 w-full">
            <label className="text-gray-700">Country</label>
            <input
              type="text"
              name="country"
              className="input-text w-full"
              value={formik.values.country}
              onChange={formik.handleChange}
            />
            {formik.touched.country && formik.errors.country ? (
              <div className="text-red-500 mb-4">{formik.errors.country}</div>
            ) : null}
          </div>
          <div className="flex flex-col items-start gap-1.5 w-full">
            <label className="text-gray-700">State</label>
            <input
              type="text"
              name="state"
              className="input-text w-full"
              value={formik.values.state}
              onChange={formik.handleChange}
            />
            {formik.touched.state && formik.errors.state ? (
              <div className="text-red-500 mb-4">{formik.errors.state}</div>
            ) : null}
          </div>
          <div className="flex flex-col items-start gap-1.5 w-full">
            <label className="text-gray-700">Contact Email</label>
            <input
              type="email"
              name="contactEmail"
              className="input-text w-full"
              value={formik.values.contactEmail}
              onChange={formik.handleChange}
            />
            {formik.touched.contactEmail && formik.errors.contactEmail ? (
              <div className="text-red-500 mb-4">
                {formik.errors.contactEmail}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col items-start gap-1.5 w-full">
            <label className="text-gray-700">Contact phone</label>
            <input
              type="text"
              name="contactPhone"
              className="input-text w-full"
              value={formik.values.contactPhone}
              onChange={formik.handleChange}
            />
            {formik.touched.contactPhone && formik.errors.contactPhone ? (
              <div className="text-red-500 mb-4">
                {formik.errors.contactPhone}
              </div>
            ) : null}
          </div>
          <button type="submit" className="button-primary w-full">
            Save Changes
          </button>
        </div>
      </div>
    </form>
  );
};
