import { createSlice } from "@reduxjs/toolkit";
const userIdentitySlice = createSlice({
  name: "userIdentityData",
  initialState: {
    id: null,
  },
  reducers: {
    setUserIdentityId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { setUserIdentityId } = userIdentitySlice.actions;

export default userIdentitySlice.reducer;
