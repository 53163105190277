import React from "react";
import ContentLoader from "react-content-loader";

export const TableLoader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={200}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="15" rx="3" ry="3" width="15%" height="20" />
    <rect x="20%" y="15" rx="3" ry="3" width="15%" height="20" />
    <rect x="40%" y="15" rx="3" ry="3" width="30%" height="20" />
    <rect x="75%" y="15" rx="3" ry="3" width="25%" height="20" />

    {[...Array(5)].map((_, i) => (
      <React.Fragment key={i}>
        <rect x="0" y={50 + i * 30} rx="3" ry="3" width="15%" height="20" />
        <rect x="20%" y={50 + i * 30} rx="3" ry="3" width="15%" height="20" />
        <rect x="40%" y={50 + i * 30} rx="3" ry="3" width="30%" height="20" />
        <rect x="75%" y={50 + i * 30} rx="3" ry="3" width="25%" height="20" />
      </React.Fragment>
    ))}
  </ContentLoader>
);
