import React, { useState } from "react";
import { SupportedStatus } from "../../store/questionnaire/types";

type StatusIconProps = {
  supported: SupportedStatus;
  onStatusChange: (newStatus: SupportedStatus) => void;
};

function StatusIcon({ supported, onStatusChange }: StatusIconProps) {
  const [isEditable, setIsEditable] = useState(false);

  const iconMap = {
    [SupportedStatus.YES]: "/icons/ico-res-yes.png",
    [SupportedStatus.NO]: "/icons/ico-res-no.png",
    [SupportedStatus.NO_INFO]: "/icons/ico-res-no-info.png",
    [SupportedStatus.PARTIALLY]: "/icons/ico-res-maybe.png",
  };

  const statusText = {
    [SupportedStatus.YES]: "Yes",
    [SupportedStatus.NO]: "No",
    [SupportedStatus.NO_INFO]: "No Info",
    [SupportedStatus.PARTIALLY]: "Partially",
  };

  const iconSrc = iconMap[supported];

  const handleIconClick = () => {
    setIsEditable(!isEditable);
  };

  const handleChangeStatus = (newStatus: SupportedStatus) => {
    onStatusChange(newStatus);
    setIsEditable(false);
  };

  return (
    <div>
      <img
        className="w-5 inline-table cursor-pointer"
        src={iconSrc}
        alt={statusText[supported]}
        onClick={handleIconClick}
      />
      {isEditable && (
        <div className="absolute bg-white shadow-lg rounded p-2 mt-1 z-10">
          {Object.values(SupportedStatus)
            .filter((status) => status !== supported)
            .map((status) => (
              <div
                key={status}
                className="flex items-center p-1 hover:bg-gray-200 cursor-pointer"
                onClick={() => handleChangeStatus(status)}
              >
                <img
                  className="w-4 h-4 mr-2"
                  src={iconMap[status]}
                  alt={statusText[status]}
                />
                {statusText[status]}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default StatusIcon;
