import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RootState } from "../../store";
import { useParams, useNavigate } from "react-router-dom";
import {
  CurrentQuote,
  setCurrentProject,
  setCurrentQuote,
} from "../../store/project/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  projectApiUtils,
  useApproveStageMutation,
  useGetQuoteQuery,
} from "../../store/project/projectApiService";
import { TableLoader } from "../utils/ContentLoader";
import QuoteItemTable from "./QuoteItemTable";
import { trackUser } from "../../utils";
import QuoteTotalsTable from "./QuoteTotalsTable";

//import QuoteDrawer from "./QuoteDrawer";
import { Project, ProjectStage } from "../../store/project/types";
import { toast } from "react-toastify";
import StatusFlag from "../utils/StatusFlag";

const QuotePage = () => {
  const dispatch = useDispatch();
  const { projectId, requirementId } = useParams();
  const intervalRef = useRef<null | number | NodeJS.Timeout>(null);
  const navigate = useNavigate();
  const [approveStage] = useApproveStageMutation();

  const currentQuote = useSelector<RootState, CurrentQuote | null>(
    (state) => state.projectData.currentQuote
  );

  const { data: quoteRequestData } = useGetQuoteQuery(requirementId!, {
    skip: !requirementId,
  });

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  useEffect(() => {
    if (quoteRequestData) {
      dispatch(
        setCurrentQuote({
          requirementId: requirementId!,
          quote: quoteRequestData.data,
        })
      );
    }

    const isQuoteGenerated = project?.requirement?.isQuoteGenerated;

    if (!intervalRef.current && !isQuoteGenerated) {
      intervalRef.current = setInterval(() => {
        dispatch(
          projectApiUtils.invalidateTags([
            { type: "Quote", id: requirementId },
            { type: "Project", id: projectId },
          ])
        );
      }, 5000);
    }

    if (intervalRef.current && isQuoteGenerated) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      intervalRef.current = null;
    }

    // Cleanup function
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current as NodeJS.Timeout);
        intervalRef.current = null;
      }
    };
  }, [requirementId, quoteRequestData, dispatch, projectId, project]);

  const loaded = () => {
    return currentQuote && currentQuote.requirementId === requirementId!;
  };

  const approveQuote = async () => {
    try {
      const { data } = await approveStage({
        requirementId: requirementId!,
        stage: ProjectStage.QUOTE,
      }).unwrap();
      trackUser("Approve Quote", { requirementId });
      dispatch(setCurrentProject(data));
      navigate(
        `/dashboard/${project?.id}/proposal/${project?.requirement?.id}`
      );
    } catch (err: any) {
      toast.error(err?.data?.message || "Error approving Quote!");
    }
  };

  return (
    <div className="px-4 pt-2 pb-8">
      <div className="sticky top-0 pt-4 bg-white/90 z-10 flex items-start rfi-header w-full">
        <div className="flex grow gap-6 mb-4 items-center">
          <h2 className="grow text-[1.5rem] font-semibold">Quote</h2>
          {project?.requirement?.isQuoteApproved && (
            <StatusFlag status="approved" />
          )}
        </div>

        <div className="flex ml-4 gap-4">
          {!project?.requirement?.isQuoteApproved && (
            <button
              className="button-primary-icon flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
              onClick={approveQuote}
            >
              <AiOutlineCheckCircle
                className="hover:text-gray-500 cursor-pointer"
                size={20}
                title="Approve Quote"
              />
              Approve
            </button>
          )}
        </div>
      </div>
      {project?.requirement?.isQuoteApproved && (
        <div className="bg-brandYellow p-4 rounded-xl mb-4">
          <span className="text-[#475467]">
            Quote is approved. You can no longer edit fields in this Quote.
          </span>
        </div>
      )}
      <div className="container mx-auto">
        {!loaded() ? (
          <TableLoader />
        ) : !currentQuote ||
          !currentQuote?.quote?.quoteTotals ||
          !project?.requirement?.isQuoteGenerated ? (
          <div className="w-full flex flex-col items-center p-12 bg-gray-25 rounded-xl border border-gray-100 shadow">
            <img
              src="/icons/estimation.png"
              className="w-8 opacity-50 animate-spin"
              alt="Generating Quote in progress"
            />
            <span className="text-sm text-gray-600 text-center">
              Generating Quote in progress. Please wait...
            </span>
          </div>
        ) : (
          <>
            <QuoteItemTable quote={currentQuote?.quote!} />
            <QuoteTotalsTable
              quote={currentQuote.quote}
              totals={currentQuote?.quote!.quoteTotals}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default QuotePage;
