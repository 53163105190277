import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useInviteUserMutation } from "../../store/users/userApi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
});

type InviteUserDrawerProps = {
  isOpen: boolean;
  toggleDrawer: () => void;
};

const InviteUserDrawer: React.FC<InviteUserDrawerProps> = ({
  isOpen,
  toggleDrawer,
}) => {
  const [inviteUser] = useInviteUserMutation();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await inviteUser(values).unwrap();
        toast.success("User invited successfully!");
        formik.resetForm();
        toggleDrawer();
      } catch (error: any) {
        toast.error(error?.data?.message || "Error inviting user!");
      }
    },
  });

  useEffect(
    () => {
      if (isOpen) {
        formik.resetForm();
      }
    },
    // formik as dependency creates infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  );

  return (
    <div
      className={`fixed top-0 right-0 w-80 h-full bg-white shadow-md z-50 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="m-4 mt-12">
        <h2 className="text-lg font-semibold m-2">Invite New User</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="m-2 mt-12">
            <label className="block">
              Email*
              <input
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Enter user email"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="m-2">
            <label className="block">
              First Name*
              <input
                type="text"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                placeholder="Enter first name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="text-red-500">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="m-2">
            <label className="block">
              Last Name*
              <input
                type="text"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                placeholder="Enter last name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="text-red-500">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="m-2">
            <label className="block">
              Password*
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  placeholder="Enter password"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 px-3 flex items-center"
                >
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </button>
              </div>
            </label>
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="m-4 mt-8 flex items-center justify-center grow">
            <button type="submit" className="button-primary">
              Invite
            </button>
            <button
              type="button"
              onClick={toggleDrawer}
              className="button-secondary w-[1/3] ml-4"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InviteUserDrawer;
