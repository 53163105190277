import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Questionnaire } from "./types";
import { getAccessToken } from "../../utils";

const api = createApi({
  reducerPath: "questionnaireApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/questionnaire",
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  tagTypes: ["Questionnaire", "QuestionnaireHistory"],
  endpoints: (builder) => ({
    getQuestionnaires: builder.query<
      {
        data: Questionnaire[];
      },
      void
    >({
      query: () => "/",
      providesTags: [{ type: "QuestionnaireHistory" }],
    }),
    getQuestionnaire: builder.query<Questionnaire, string | null>({
      query: (id: string | null) => `${id}`,
      providesTags: [{ type: "Questionnaire" }],
    }),
    uploadQuestionnaire: builder.mutation<
      Questionnaire,
      {
        projectId?: string;
        file: File;
      }
    >({
      query: ({ projectId, file }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/upload/${projectId}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "QuestionnaireHistory" }],
    }),
    updateQuestionnaire: builder.mutation<Questionnaire, Questionnaire>({
      query: (questionnaire: Questionnaire) => {
        return {
          url: "",
          method: "PATCH",
          body: {
            id: questionnaire.id,
            title: questionnaire.title,
            data: questionnaire.data,
          },
        };
      },
      invalidatesTags: [{ type: "QuestionnaireHistory" }],
    }),
    updateQuestionnaireTitle: builder.mutation<null, Questionnaire>({
      query: (questionnaire: Questionnaire) => {
        return {
          url: "",
          method: "PATCH",
          body: {
            id: questionnaire.id,
            title: questionnaire.title,
          },
        };
      },
      invalidatesTags: [{ type: "QuestionnaireHistory" }],
    }),
    deleteQuestionnaire: builder.mutation<null, string>({
      query: (id: string) => {
        return {
          url: "",
          method: "DELETE",
          body: {
            id,
          },
        };
      },
      invalidatesTags: [{ type: "QuestionnaireHistory" }],
    }),
  }),
});

export const {
  useUpdateQuestionnaireMutation,
  useUpdateQuestionnaireTitleMutation,
  useDeleteQuestionnaireMutation,
  useUploadQuestionnaireMutation,
  useGetQuestionnairesQuery,
  useGetQuestionnaireQuery,
  util: questionnaireApiUtils,
} = api;

export const questionnaireApiMiddleware = api.middleware;

export default api.reducer;
