import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect } from "react";
import { setAccessToken } from "../../store/userIdentity/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { TableLoader } from "../utils/ContentLoader";

type AuthProps = {
  children: ReactNode;
};

export const Auth = ({ children }: AuthProps) => {
  const {
    isAuthenticated,
    isLoading,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const accessToken = useSelector<RootState, string | null>((state) => {
    return state.authData.accessToken;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          dispatch(setAccessToken(token));
        } else {
          loginWithRedirect();
        }
      } catch (e) {
        // Handle the case where getting the access token silently fails
        loginWithRedirect();
      }
    };

    if (isAuthenticated && !accessToken) {
      getToken();
    }
  }, [
    dispatch,
    getAccessTokenSilently,
    loginWithRedirect,
    isAuthenticated,
    accessToken,
  ]);

  useEffect(() => {
    if (error) {
      const urlParams = new URLSearchParams(window.location.search);
      const error_obj = JSON.parse(urlParams.get("error_description") || "{}");
      if (error_obj.code === "EMAIL_NOT_VERIFIED") {
        if (!window.location.pathname.includes("/verify-email")) {
          window.location.href = `/verify-email?${urlParams.toString()}`;
        }
        return;
      }
    }

    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
      localStorage.setItem("returnTo", window.location.href);
    }
  }, [error, isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading) return <TableLoader />;
  if (!isAuthenticated || !accessToken) return <>Redirecting...</>;

  return <>{children}</>;
};
