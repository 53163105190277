import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResourceType } from "./types";

interface ResourceTypeDataState {
  resourceTypes: ResourceType[];
}

const initialState: ResourceTypeDataState = {
  resourceTypes: [],
};

export const resourceTypeSlice = createSlice({
  name: "resourceTypeData",
  initialState,
  reducers: {
    setResourceTypes: (state, action: PayloadAction<ResourceType[]>) => {
      state.resourceTypes = action.payload;
    },
    addResourceType: (state, action: PayloadAction<ResourceType>) => {
      state.resourceTypes.push(action.payload);
    },
    updateResourceType: (state, action: PayloadAction<ResourceType>) => {
      const index = state.resourceTypes.findIndex(
        (rt) => rt.id === action.payload.id
      );
      if (index !== -1) {
        state.resourceTypes[index] = action.payload;
      }
    },
  },
});

export const { setResourceTypes, addResourceType, updateResourceType } =
  resourceTypeSlice.actions;

export default resourceTypeSlice.reducer;
