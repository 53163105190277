import React from "react";
import { useResendVerificationEmailMutation } from "../../store/auth/authApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const VerifyEmail = () => {
  const [resendVerificationEmail] = useResendVerificationEmailMutation();

  const handleResendEmail = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const error_obj = JSON.parse(urlParams.get("error_description") || "{}");

      if (error_obj) {
        if (error_obj.user_id) {
          await resendVerificationEmail(error_obj.user_id).unwrap();
          toast.success(
            "Verification email has been resent. Please check your inbox."
          );
        } else {
          toast.error("Email not found in state parameter.");
        }
      } else {
        toast.error("State parameter is missing.");
      }
    } catch (error) {
      toast.error(
        "Failed to resend verification email. Please try again later."
      );
    }
  };

  return (
    <div className="w-full h-screen bg-brandYellow flex flex-col gap-12 items-center justify-center">
      <img
        src="/images/main-logo.png"
        className="w-36"
        alt="EstiWiz - Your best buddy in giving proposals!"
      />
      <div className="text-center">
        Please check your email and click on the verification link. <br />
        If you don't see the email in your inbox, please do the following steps:
      </div>
      <ol className="m-0 p-0 text-center">
        <li>1. Check your spam folder</li>
        <li>
          2.{" "}
          <button className="underline" onClick={handleResendEmail}>
            Click on this link
          </button>{" "}
          to resend the email
        </li>
      </ol>
    </div>
  );
};
