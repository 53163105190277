import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../../utils";
import { CapabilityType, Company } from "./types";

const api = createApi({
  reducerPath: "companyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/company",
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  tagTypes: ["Company", "CompanyDocument"],
  endpoints: (builder) => ({
    getCompany: builder.query<Company, void>({
      query: () => "/",
      providesTags: [{ type: "Company" }],
    }),
    createCompany: builder.mutation<Company, Partial<Company>>({
      query: (companyData: Company) => {
        return {
          url: "",
          method: "POST",
          body: {
            ...companyData,
          },
        };
      },
      invalidatesTags: [{ type: "Company" }, { type: "CompanyDocument" }],
    }),
    finishOnboarding: builder.mutation<Company, Company>({
      query: (company) => {
        return { url: `/onboarded/${company.id}`, method: "PATCH" };
      },
      invalidatesTags: [{ type: "Company" }, { type: "CompanyDocument" }],
    }),
    uploadDocument: builder.mutation<
      void,
      { companyId: string; file: File; capabilityType: CapabilityType }
    >({
      query: ({ companyId, file, capabilityType }) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("companyId", companyId);
        formData.append("capabilityType", capabilityType);
        return {
          url: "/document",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "CompanyDocument" }],
    }),
    getCompanyDocuments: builder.query<Array<Document>, string>({
      query: (id) => `/documents/${id}`,
      providesTags: [{ type: "CompanyDocument" }],
    }),
    deleteCompanyDocument: builder.mutation<void, string>({
      query: (id) => ({
        url: `/document/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CompanyDocument" }],
    }),
    getFileContent: builder.query<any, string>({
      query: (id) => `/file-content/${id}`,
    }),
  }),
});

export const {
  useGetCompanyQuery,
  useCreateCompanyMutation,
  useFinishOnboardingMutation,
  useUploadDocumentMutation,
  useDeleteCompanyDocumentMutation,
  useGetCompanyDocumentsQuery,
  useGetFileContentQuery,
  util: companyApiUtil,
} = api;

export const companyApiMiddleware = api.middleware;

export default api.reducer;
