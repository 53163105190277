import React from 'react';

interface ConfirmModalProps {
  show: boolean;
  question: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ show, question, onConfirm, onCancel }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-1/3">
        <h2 className="text-lg font-semibold mb-4">Confirm</h2>
        <p className="mb-6">{question}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="bg-brandSubtle2 hover:bg-brandSubtle text-brandDark font-semibold py-2 px-4 rounded"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-brand text-brandYellow hover:bg-brandHover font-semibold py-2 px-4 rounded"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
