export const toCurrencyFormat = (value: number) => {
  if(!value) {
    value = 0;
  }

  return new Intl.NumberFormat("eu-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat("eu-US", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(value);
};

export const parseNumber = (value: string | number) => {
  if (typeof value === "number") {
    return value;
  }
  let numberValue = 0;
  if (value && !isNaN(parseFloat(value.replace(/,/g, "")))) {
    numberValue = parseFloat(value.replace(/,/g, ""));
  }
  return numberValue;
};
